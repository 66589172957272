import { AuthProvider } from "context/AuthContext";
import { SystemVersionProvider } from "context/SystemVersionContext";

import MainRouter from "router";
import ErrorBoundary from "router/modules/ErrorBoundary";

import "styles/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "styles/Restyling.scss";
import "styles/App.scss";

const App = () => (
  <AuthProvider>
    <SystemVersionProvider>
      <ErrorBoundary>
        <MainRouter />
      </ErrorBoundary>
    </SystemVersionProvider>
  </AuthProvider>
);

export default App;
