import { useState } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import MaskUtil from "utils/MaskUtil";

const Ocorrencia = ({ FuncionarioId }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const { StatusOcorrencia } = MaskEnum;

  const [SelectedStatus, setSelectedStatus] = useState(null);
  const [Descricao, setDescricao] = useState("");

  const [Historico, setHistorico] = useState([]);

  const LipramCampos = () => {
    setDescricao("");
    setSelectedStatus(null);
  };

  const GetOcorrencias = async () => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/Funcionarios/Ocorrencia",
        params: { id: FuncionarioId },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setHistorico(data.objetoResposta);
      } else {
        notify({
          type: "aviso",
          message: "sem ocorrencias."
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const SalvarOcorrencia = async (params) => {
    params.preventDefault();
    try {
      setLoading(true);
      const data = await Requicicao.Post({
        endpoint: "/Funcionarios/Ocorrencia",
        data: { FuncionarioId, Status: SelectedStatus, Descricao },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        notify({
          type: "ok",
          message: "Ocorrencia Salva."
        });
        LipramCampos();
      } else if (data.codigoEstadoHttp === 404) {
        notify({
          type: "erro",
          message: data.mensagemAdicional
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form className="row g-3" onSubmit={SalvarOcorrencia}>
        <div className="col-md-6">
          <label htmlFor="Status" className="form-label">
            Status
          </label>
          <Dropdown
            id="Status"
            className="w-100"
            value={SelectedStatus}
            onChange={(e) => setSelectedStatus(e.value)}
            options={StatusOcorrencia}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="descricao" className="form-label">
            Descrição
          </label>
          <InputText
            id="descricao"
            className="w-100"
            value={Descricao}
            onChange={(e) => setDescricao(e.target.value)}
          />
        </div>
        <div className="col-12">
          <div className="d-flex flex-row-reverse gap-3">
            <Button label="Salvar" className="btn btn-primary" />
            <Button
              className="btn"
              type="button"
              label="Carregar Histórico"
              onClick={GetOcorrencias}
            />
          </div>
        </div>
      </form>
      {Historico.length > 0 && (
        <div className="mt-3">
          <DataTable value={Historico} stripedRows rows={3} paginator>
            <Column
              field="status"
              header="Status"
              body={(row) =>
                StatusOcorrencia.find((x) => x.value === row.status)?.label
              }
            />
            <Column field="descricao" header="Descrição" />
            <Column
              field="dataCriacao"
              header="Data de Criação"
              body={(row) => MaskUtil.applyDataMask(row.dataCriacao)}
            />
          </DataTable>
        </div>
      )}
    </>
  );
};

Ocorrencia.propTypes = {
  FuncionarioId: PropTypes.string.isRequired
};

export default Ocorrencia;
