import React, { useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import { format } from "date-fns";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";

import { useAuth } from "context/AuthContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

import "./styleds.scss";

const AcessoNovaContratacao = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const notify = useNotification();
  const Requicicao = new Api();

  const [birthDate, setBirthDate] = useState("");
  const [cpf, setCpf] = useState("");

  const handleCpfChange = (e) => {
    const { value } = e.target;
    setCpf(value);
  };

  const handleBirthDateChange = (e) => {
    setBirthDate(e.value);
  };

  const isValidDate = (dateStr) => {
    const parsedDate = dateStr;
    return parsedDate instanceof Date;
  };

  const isOver18 = (dateStr) => {
    const birthDateObj = dateStr;

    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    const dayDiff = today.getDate() - birthDateObj.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age -= 1;
    }

    return age >= 18;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidDate(birthDate)) {
      notify({
        type: "error",
        message:
          "Data de nascimento inválida. Por favor, insira uma data válida.",
        life: 3000
      });
      return;
    }

    if (!isOver18(birthDate)) {
      notify({
        type: "error",
        message: "Você deve ter pelo menos 18 anos para continuar.",
        life: 3000
      });
      return;
    }

    try {
      const payload = {
        cpf: cpf.toUpperCase(),
        dataNascimento: format(birthDate, "yyyy-MM-dd")
      };

      const response = await Requicicao.Post({
        endpoint: "/Funcionarios/ValidarFuncionario",
        data: payload,
        config: auth.GetHeaders()
      });

      if (response.codigoEstadoHttp === 200) {
        notify({
          type: "success",
          message: "Funcionário validado com sucesso!",
          life: 3000
        });

        const data = response.objetoResposta;

        if (data.statusCadastro === 6) {
          navigate({
            pathname: "/boasVindas/reenvio-aquivos",
            search: createSearchParams({
              id: data.id
            }).toString()
          });
        } else if (
          data.statusCadastro === 4 ||
          data.statusCadastro === 5 ||
          data.statusCadastro === 8
        ) {
          navigate({
            pathname: "/boasVindas/dados",
            search: createSearchParams({
              id: data.id
            }).toString()
          });
        } else
          navigate({
            pathname: "/boasVindas/aprovarCartaProposta",
            search: createSearchParams({
              id: data.id
            }).toString()
          });
      } else {
        notify({
          type: "error",
          message:
            response.mensagemAdicional || "Erro ao validar o funcionário.",
          life: 3000
        });
      }
    } catch (error) {
      console.error("Erro ao validar o funcionário:", error);
      notify({
        type: "error",
        message:
          "Ocorreu um erro ao tentar validar suas informações. Por favor, tente novamente mais tarde.",
        life: 3000
      });
    }
  };

  return (
    <div className="box-bem-vindo">
      <div className="card box-form">
        <h1>Bem-vindo, Colaborador!</h1>
        <p className="text-center">
          Estamos felizes em tê-lo(a) conosco.
          <br /> Por favor, preencha os campos abaixo para continuar.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="campo">
            <label>Data de Nascimento:</label>
            <Calendar
              value={birthDate}
              onChange={handleBirthDateChange}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="campo">
            <label>CPF:</label>
            <InputMask
              value={cpf}
              onChange={handleCpfChange}
              mask="999.999.999-99"
            />
          </div>
          <Button label="Enviar" type="submit" severity="success" />
        </form>
      </div>
    </div>
  );
};

export default AcessoNovaContratacao;
