import { useState, useEffect } from "react";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";

const ListaBaseConhecimento = () => {
  const [lista, setLista] = useState([]);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const stateDataPage = async () => {
    // Simula a requisição para buscar os dados da base de conhecimento
    const data = [
      {
        id: 1,
        titulo: "Tutorial de Ponto de Venda",
        descricao: "Como usar o ponto de venda local",
        url: "/videos/Treinamento PDV Local.mp4"
      },
      {
        id: 2,
        titulo: "Conhecimento Básico do Portal",
        descricao: "Como usar o Portal",
        url: "/videos/Treinamento Basico Portal.mp4"
      }
    ];
    setLista(data);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      titulo: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      descricao: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const thisFilters = { ...filters };
    thisFilters.global.value = value;
    setFilters(thisFilters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    stateDataPage();
    initFilters();
  }, []);

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between mb-3">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Limpar filtro"
          outlined
          onClick={clearFilter}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Pesquisa Global"
          />
        </span>
      </div>
      <div className="d-flex flex-wrap gap-3">
        {lista
          .filter((item) => {
            if (filters.global.value) {
              return (
                item.titulo
                  .toLowerCase()
                  .includes(filters.global.value.toLowerCase()) ||
                item.descricao
                  .toLowerCase()
                  .includes(filters.global.value.toLowerCase())
              );
            }
            return true;
          })
          .map((item) => (
            <Card
              key={item.id}
              title={item.titulo}
              subTitle={item.descricao}
              className="w-100"
              style={{ maxWidth: "320px" }}
            >
              <video width="100%" controls>
                <source src={item.url} type="video/mp4" />
                <track kind="captions" />
                Seu navegador não suporta a tag de vídeo.
              </video>
            </Card>
          ))}
      </div>
    </div>
  );
};

export default ListaBaseConhecimento;
