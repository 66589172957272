import React from "react";
import { pdfjs, Document, Page } from "react-pdf";

import PropTypes from "prop-types";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import "./styled.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const PDFPreview = ({ file }) => (
  <div className="box-pdf">
    <Document
      file={file}
      onLoadError={console.error}
      loading={<p>Loading PDF...</p>}
    >
      <Page pageNumber={1} />
    </Document>
  </div>
);

PDFPreview.propTypes = {
  file: PropTypes.string.isRequired
};

export default PDFPreview;
