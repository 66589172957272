import React from "react";

import PropTypes from "prop-types";

import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";

const InformacoesPessoais = ({
  dados,
  HandleChange,
  HandleDropdownChange,
  Escolaridades
}) => (
  <div className="row g-3">
    <div className="col-md-6 col-lg-3">
      <label htmlFor="pis" className="form-label">
        PIS
      </label>
      <InputMask
        id="pis"
        name="pis"
        value={dados.pis}
        onChange={HandleChange}
        className="form-control"
        mask="999.99999.99-9"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="carteiraTrabalho" className="form-label">
        Carteira de Trabalho
      </label>
      <InputMask
        id="carteiraTrabalho"
        name="carteiraTrabalho"
        value={dados.carteiraTrabalho}
        onChange={HandleChange}
        className="form-control"
        mask="9999999"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="tituloEleitor" className="form-label">
        Título de Eleitor
      </label>
      <InputMask
        id="tituloEleitor"
        name="tituloEleitor"
        value={dados.tituloEleitor}
        onChange={HandleChange}
        className="form-control"
        mask="9999 9999 9999"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="escolaridadeId" className="form-label">
        Escolaridade
      </label>
      <Dropdown
        id="escolaridadeId"
        value={dados.escolaridadeId}
        onChange={(e) => HandleDropdownChange(e, "escolaridadeId")}
        options={Escolaridades}
        placeholder="Selecione o nível de escolaridade"
        className="w-100"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="tamanhoUniforme" className="form-label">
        Tamanho do Uniforme
      </label>
      <Dropdown
        id="tamanhoUniforme"
        name="tamanhoUniforme"
        value={dados.tamanhoUniforme}
        onChange={HandleChange}
        options={["PP", "P", "M", "G", "GG"]}
        placeholder="Selecione o Tamanho do Uniforme"
        className="w-100"
      />
    </div>
  </div>
);

InformacoesPessoais.propTypes = {
  dados: PropTypes.shape({
    pis: PropTypes.string.isRequired,
    carteiraTrabalho: PropTypes.string.isRequired,
    tituloEleitor: PropTypes.string.isRequired,
    escolaridadeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tamanhoUniforme: PropTypes.string
  }).isRequired,
  HandleChange: PropTypes.func.isRequired,
  HandleDropdownChange: PropTypes.func.isRequired,
  Escolaridades: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ).isRequired
};

export default InformacoesPessoais;
