import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";

const CampoAbilitavel = ({ value, onChange, labelSwitch, labelInput }) => {
  const [checked, setChecked] = useState(false);

  const UpDataChecked = (valueChecked) => {
    setChecked(valueChecked);
    if (!valueChecked) {
      onChange(null);
    }
  };

  useEffect(() => {
    if (value != null) {
      setChecked(true);
    }
  }, [value]);

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-column gap-2">
        <label>{labelSwitch}</label>
        <InputSwitch
          checked={checked}
          onChange={(e) => UpDataChecked(e.value)}
        />
      </div>
      <div className="d-flex flex-column gap-2">
        <label>{labelInput}</label>
        <InputNumber
          value={value || ""}
          onChange={(e) => onChange(e.value)}
          mode="currency"
          locale="pt-BR"
          currency="BRL"
          disabled={!checked}
        />
      </div>
    </div>
  );
};

CampoAbilitavel.propTypes = {
  labelSwitch: PropTypes.string.isRequired,
  labelInput: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

// Specifies the default values for props:
CampoAbilitavel.defaultProps = {
  value: null
};

export default CampoAbilitavel;
