import { useState, useEffect } from "react";
import {
  FaEnvelope,
  FaUserShield,
  FaIdCard,
  FaUser,
  FaBuilding
} from "react-icons/fa";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";

import Api from "utils/Api";

const MeusDados = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();

  const [dados, setDados] = useState({
    acesso: "",
    senha: "",
    permissoes: [],
    nomeCompletoOuRazaoSocial: "",
    apelidoOuNomeFantasia: "",
    cpfOuCnpj: "",
    empresaMatrizId: ""
  });

  const GetConta = async () => {
    try {
      const data = await Requicicao.Get({
        endpoint: "/Conta/MeusDados",
        params: { id: auth.user.id },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        const newDados = {
          ...data.objetoResposta,
          permissoes: data.objetoResposta.permissoes.map((item) => ({
            label: item.permissaoNome,
            valor: item.permissaoId
          })),
          empresaMatrizId: data.objetoResposta.empresaMatrizId
        };
        setDados(newDados);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const Go = async () => {
      try {
        setLoading(true);
        await GetConta();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (auth.isAuthenticated()) Go();
  }, [auth]);

  return (
    <div className="card p-4 shadow-lg border-0 rounded">
      <div className="list-group">
        <div className="list-group-item d-flex align-items-center">
          <FaEnvelope className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">
              Acesso (E-mail):
            </label>
            <p className="m-0 ms-1">{dados.acesso}</p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaUserShield className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">Permissões:</label>
            <p className="m-0 ms-1">
              {dados?.permissoes.map((item, i) => (
                <span key={item.label}>
                  {item.label}
                  {i < dados.permissoes.length - 1 ? ", " : "."}
                </span>
              ))}
            </p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaIdCard className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">CPF ou CNPJ:</label>
            <p className="m-0 ms-1">{dados.cpfOuCnpj}</p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaUser className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">
              Nome Completo ou Razão Social:
            </label>
            <p className="m-0 ms-1">{dados.nomeCompletoOuRazaoSocial}</p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaBuilding className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">
              Apelido ou Nome Fantasia:
            </label>
            <p className="m-0 ms-1">{dados.apelidoOuNomeFantasia}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeusDados;
