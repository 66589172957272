/* eslint-disable no-plusplus */

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

class MaskUtil {

  static valicaDado(value) {
    return value == null
  }

  static applyNumeroBancoMask(value) {
    if (this.valicaDado(value)) return null
    return this.applyGenericMask(value, '###');
  }

  static applyNumeroAgenciaMask(value) {
    if (this.valicaDado(value)) return null
    return this.applyGenericMask(value, '####');
  }

  static applyNumeroContaMask(value) {
    if (this.valicaDado(value)) return null
    return this.applyGenericMask(value.replace(/\D/g, ''), '#######-#');
  }

  static applyDataUSMask(value) {
    try {
      if (this.valicaDado(value)) return null
      return format(new Date(value), "yyyy-MM-dd");
    } catch (error) {
      console.error("Erro ao formatar a data:", error);
      return null;
    }
  }

  static applyDataMask(value) {
    try {
      if (this.valicaDado(value)) return null
      return format(new Date(value), "dd/MM/yyyy");
    } catch (error) {
      console.error("Erro ao formatar a data:", error);
      return null;
    }
  }

  static applyMesAnoMask(value) {
    try {
      if (this.valicaDado(value)) return null
      return format(new Date(value), "MMMM yyyy", { locale: ptBR });
    } catch (error) {
      console.error("Erro ao formatar a data:", error);
      return null;
    }
  }

  static applyDataAndHoraMask(value) {
    try {
      if (this.valicaDado(value)) return null
      return format(new Date(value), "dd/MM/yyyy HH:mm:ss");
    } catch (error) {
      console.error("Erro ao formatar a data e hora:", error);
      return null;
    }
  }

  static applyCnhMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, '###########');
  }

  static applyRgMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, '##.###.###-##');
  }

  static applyCpfMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, '###.###.###-##');
  }

  static applyCnpjMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, '##.###.###/####-##');
  }

  static applyCpfCnpjMask(value) {
    // Remove caracteres não numéricos
    const numericValue = value.replace(/\D/g, '');

    // Aplica a máscara independentemente do número de caracteres
    if (numericValue.length < 12) {
      // Máscara para CPF
      return this.applyCpfMask(numericValue);
    }

    // Máscara para CNPJ
    return this.applyCnpjMask(numericValue)
  }

  static applyCepMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, "#####-###")
  }

  static applyTelefoneMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, "#####-####")
  }

  static applyMonetaryMask(value) {
    if (this.valicaDado(value)) return null
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value)
  }

  static applyGenericMask(value, mask) {
    let maskedValue = '';
    let i = 0;
    let j = 0;

    while (i < value.length && j < mask.length) {
      if (mask[j] === '#') {
        maskedValue += value[i];
        i++;
      } else {
        maskedValue += mask[j];
      }
      j++;
    }

    return maskedValue;
  }

  static convertDateToIso(date) {
    const [day, month, year] = date.split('/');
    return new Date(`${year}-${month}-${day}`).toISOString();
  }
}

export default MaskUtil;
