import { useState, useEffect } from "react";
import {
  FaUser,
  FaCalendarAlt,
  FaBriefcase,
  FaCheckCircle,
  FaTimesCircle,
  FaExclamationCircle
} from "react-icons/fa";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import MaskUtil from "utils/MaskUtil";
import useQuery from "utils/useQuery";

const InfoUser = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const query = useQuery();
  const Requicicao = new Api();
  const { StatusFuncionario } = MaskEnum;

  const [dados, setDados] = useState({});

  const GetConta = async (id) => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/Funcionarios/Info",
        params: { id },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setDados(data.objetoResposta);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const id = query.get("id");
    if (auth.isAuthenticated() && id != null) GetConta(id);
  }, [auth, query]);

  return (
    <div className="card p-4 shadow-lg border-0 rounded">
      <div className="list-group">
        <div className="list-group-item d-flex align-items-center">
          <FaUser className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">Nome:</label>
            <p className="m-0 ms-1">
              {dados.nome ? (
                dados.nome
              ) : (
                <span className="text-muted">Informação não disponível</span>
              )}
            </p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaCalendarAlt className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">
              Data de Nascimento:
            </label>
            <p className="m-0 ms-1">
              {dados.dataNascimento ? (
                MaskUtil.applyDataMask(dados.dataNascimento)
              ) : (
                <span className="text-muted">Informação não disponível</span>
              )}
            </p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaCalendarAlt className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">
              Data de Admissão:
            </label>
            <p className="m-0 ms-1">
              {dados.dataAdmissao &&
              dados.dataAdmissao !== "0001-01-01T00:00:00" ? (
                MaskUtil.applyDataMask(dados.dataAdmissao)
              ) : (
                <span className="text-muted">Não admitido</span>
              )}
            </p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaBriefcase className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">Cargo:</label>
            <p className="m-0 ms-1">
              {dados.cargo ? (
                dados.cargo
              ) : (
                <span className="text-muted">Informação não disponível</span>
              )}
            </p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          {dados.status ? (
            <FaCheckCircle className="me-2 text-success" />
          ) : (
            <FaExclamationCircle className="me-2 text-warning" />
          )}
          <div>
            <label className="fw-semibold text-secondary">Status:</label>
            <p className="m-0 ms-1">
              {dados.status ? (
                StatusFuncionario.find((x) => x.value === dados.status).label
              ) : (
                <span className="text-muted">Status não disponível</span>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoUser;
