import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { format } from "date-fns";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const ListaPontoVenda = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [Lista, setLista] = useState([]);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: "/PontoVendaAnfitriao",
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        const lista = [];
        resposta.objetoResposta.forEach((element) => {
          lista.push({
            id: element.id,
            nome: element.nomeCompletoOuRazaoSocial,
            nomeFantasia: element.apelidoOuNomeFantasia,
            cpfOuCnpj: element.cpfOuCnpj,
            dataCriacao: element.dataCriacao,
            dataAtualizacao: element.dataAtualizacao
          });
        });
        setLista(lista);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nome: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      },
      nomeFantasia: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
    setGlobalFilterValue("");
  };

  const Desativars = async (rowData) => {
    try {
      setLoading(true);
      await Requicicao.Delete({
        endpoint: "/PontoVendaAnfitriao",
        params: { id: rowData.id },
        config: auth.GetHeaders()
      });
      notify({
        type: "success",
        message: "Exclusão realizada com sucesso."
      });
      StateDataPage();
    } catch (error) {
      console.error(error);
      notify({
        type: "error",
        message: "Erro para executar essa ação, entre em contato com o suporte."
      });
    } finally {
      setLoading(false);
    }
  };

  const Confirm = (event, rowData) => {
    confirmPopup({
      group: "headless",
      target: event.currentTarget,
      message: "Deseja realmente excluir este item?",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
      accept: () => {
        Desativars(rowData);
      },
      reject: () => {},
      acceptLabel: "Sim",
      rejectLabel: "Não"
    });
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filters };

      thisFilters.global.value = value;

      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
    initFilters();
  }, [auth]);

  return (
    <div className="card p-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex flex-row gap-3">
          <Link to="/app/pontoVenda/new" className="btn btn-primary">
            Criar
          </Link>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Limpar filtro"
            outlined
            onClick={clearFilter}
          />
        </div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Pesquisa Global"
          />
        </span>
      </div>
      <DataTable
        value={Lista}
        stripedRows
        paginator
        rows={20}
        tableStyle={{ minWidth: "50rem" }}
        emptyMessage="Nenhuma loja encontrada."
        globalFilterFields={["nome", "nomeFantasia"]}
        filters={filters}
      >
        <Column field="nome" header="Nome" sortable />
        <Column field="nomeFantasia" header="Nome Fantasia" sortable />
        <Column
          field="dataCriacao"
          header="Data Criação"
          sortable
          body={(rowData) =>
            rowData.dataCriacao
              ? format(new Date(rowData.dataCriacao), "dd/MM/yyyy HH:mm:ss")
              : "NaN"
          }
        />
        <Column
          header="Ações"
          body={(rowData) => (
            <div>
              <ConfirmPopup />
              <div className="d-flex flex-row gap-3">
                <Link
                  to={`/app/pontoVenda/editar?id=${rowData.id}`}
                  className="btn btn-tabela"
                >
                  <i className="bi bi-pencil" />
                </Link>
                <Button
                  className="btn btn-tabela"
                  type="button"
                  icon="bi bi-trash"
                  onClick={(event) => {
                    Confirm(event, rowData);
                  }}
                />
              </div>
            </div>
          )}
        />
      </DataTable>
    </div>
  );
};

export default ListaPontoVenda;
