import { useState } from "react";

import { Button } from "primereact/button";
import { Password } from "primereact/password";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const TrocaSenha = () => {
  const auth = useAuth();
  const Requicicao = new Api();
  const { setLoading } = useLoading();
  const notify = useNotification();

  const [SenhaAtual, setSenhaAtual] = useState("");
  const [SenhaNova, setSenhaNova] = useState("");
  const [SenhaConfirme, setSenhaConfirme] = useState("");

  const ValidaSenha = () => {
    if (SenhaNova !== SenhaConfirme || SenhaAtual === "" || SenhaNova === "") {
      if (SenhaNova === "")
        notify({
          type: "aviso",
          message: "Informe a nova senhas"
        });
      if (SenhaNova !== SenhaConfirme)
        notify({
          type: "aviso",
          message: "As senhas nao conferem"
        });
      if (SenhaAtual === "")
        notify({
          type: "aviso",
          message: "Informe a senha atual"
        });
      return false;
    }
    return true;
  };

  const Submit = async (params) => {
    params.preventDefault();
    try {
      setLoading(true);
      if (ValidaSenha()) {
        await Requicicao.Put({
          endpoint: "/Conta/atualizar-senha",
          data: { Id: auth.user.id, SenhaAtual, NovaSenha: SenhaNova },
          config: auth.GetHeaders()
        });
        notify({
          type: "sucesso",
          message: "Senha atualizada com sucesso"
        });
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.mensagemAdicional)
        notify({
          type: "erro",
          message: error.response.data.mensagemAdicional
        });
      else
        notify({
          type: "erro",
          message: "Erro ao atualizar a senha"
        });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="card p-3">
      <form onSubmit={Submit}>
        <div className="row g-3">
          <div className="col-sm-12 col-md-6 d-flex flex-column gap-1">
            <label>Nova senha</label>
            <Password
              value={SenhaNova}
              onChange={(e) => setSenhaNova(e.target.value)}
              promptLabel="Digite uma senha"
              weakLabel="Fraca"
              mediumLabel="Média"
              strongLabel="Forte"
            />
          </div>
          <div className="col-sm-12 col-md-6 d-flex flex-column gap-1">
            <label>Confirme a nova senha</label>
            <Password
              value={SenhaConfirme}
              onChange={(e) => setSenhaConfirme(e.target.value)}
              promptLabel="Digite uma senha"
              weakLabel="Fraca"
              mediumLabel="Média"
              strongLabel="Forte"
            />
          </div>
          <div className="col-sm-12 col-md-6 d-flex flex-column gap-1">
            <label>Senha atul</label>
            <Password
              value={SenhaAtual}
              onChange={(e) => setSenhaAtual(e.target.value)}
              feedback={false}
              promptLabel="Digite uma senha"
            />
          </div>
        </div>
        <div className="d-flex flex-row-reverse mt-3">
          <Button label="Salva" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default TrocaSenha;
