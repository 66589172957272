import React from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";

import MaskUtil from "utils/MaskUtil";

const InformacoesDependentes = ({
  dados,
  HandleChange,
  dependente,
  setDependente,
  HandleAddDependente,
  RemoverDependente
}) => {
  const parentescos = [
    { label: "Filho(a)", value: "filho" },
    { label: "Cônjuge", value: "conjuge" },
    { label: "Outros", value: "outros" }
  ];

  return (
    <div className="row g-3">
      <div className="col-md-6 col-lg-3">
        <div className="d-flex flex-column">
          <label htmlFor="possuiDependentes" className="form-label">
            Possui Dependentes?
          </label>
          <InputSwitch
            id="possuiDependentes"
            checked={dados.possuiDependentes}
            onChange={(e) =>
              HandleChange({
                target: { name: "possuiDependentes", value: e.value }
              })
            }
          />
        </div>
      </div>
      {dados?.possuiDependentes && (
        <div className="col-12">
          <div className="row g-3">
            <div className="col-12">
              <h4>Cadastro de Dependentes</h4>
            </div>
            <div className="col-md-6 col-lg-3">
              <label>Nome do Dependente</label>
              <InputText
                value={dependente.nome}
                onChange={(e) =>
                  setDependente({
                    ...dependente,
                    nome: e.target.value
                  })
                }
                className="w-100"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <label>Data de Nascimento</label>
              <Calendar
                value={dependente.dataNascimento}
                onChange={(e) =>
                  setDependente({
                    ...dependente,
                    dataNascimento: e.value
                  })
                }
                className="w-100"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <label>Parentesco</label>
              <Dropdown
                value={dependente.parentesco}
                options={parentescos}
                onChange={(e) =>
                  setDependente({
                    ...dependente,
                    parentesco: e.value
                  })
                }
                className="w-100"
              />
            </div>
            <div className="col-md-6 col-lg-3">
              <label>CPF</label>
              <InputText
                value={dependente.cpf}
                onChange={(e) =>
                  setDependente({
                    ...dependente,
                    cpf: MaskUtil.applyCpfMask(e.target.value)
                  })
                }
                className="w-100"
              />
            </div>
            <div className="col-12">
              <div className="d-flex flex-row-reverse">
                <Button
                  type="button"
                  label="Adicionar Dependente"
                  icon="pi pi-plus"
                  onClick={HandleAddDependente}
                />
              </div>
            </div>
            <div className="col-12">
              <DataTable value={dados.dependentes}>
                <Column field="nome" header="Nome" />
                <Column field="parentesco" header="Parentesco" />
                <Column field="cpf" header="CPF" />
                <Column
                  field="dataNascimento"
                  header="Data de Nascimento"
                  body={(row) => MaskUtil.applyDataMask(row.dataNascimento)}
                />
                <Column
                  header="Ação"
                  body={(row) => (
                    <Button
                      type="button"
                      label="Remover"
                      onClick={() => RemoverDependente(row.cpf)}
                    />
                  )}
                />
              </DataTable>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

InformacoesDependentes.propTypes = {
  dados: PropTypes.shape({
    possuiDependentes: PropTypes.bool.isRequired,
    dependentes: PropTypes.arrayOf(
      PropTypes.shape({
        nome: PropTypes.string.isRequired,
        parentesco: PropTypes.string.isRequired,
        cpf: PropTypes.string.isRequired,
        dataNascimento: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.instanceOf(Date)
        ]).isRequired
      })
    ).isRequired
  }).isRequired,
  HandleChange: PropTypes.func.isRequired,
  dependente: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    parentesco: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
    dataNascimento: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]).isRequired
  }).isRequired,
  setDependente: PropTypes.func.isRequired,
  HandleAddDependente: PropTypes.func.isRequired,
  RemoverDependente: PropTypes.func.isRequired
};

export default InformacoesDependentes;
