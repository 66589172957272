import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";
import useQuery from "utils/useQuery";

import ListaFuncionario from "pages/RH/listaFuncionario";

const Dependentes = ({ dados }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const notify = useNotification();
  const query = useQuery();
  const Requicicao = new Api();

  const parentescos = [
    { label: "Filho(a)", value: "filho" },
    { label: "Cônjuge", value: "conjuge" },
    { label: "Outros", value: "outros" }
  ];

  const startData = {
    nome: "",
    dataNascimento: null,
    parentesco: "",
    cpf: ""
  };

  const [PossuiDependentes, setPossuiDependentes] = useState(false);
  const [Lista, setLista] = useState([]);
  const [dependente, setDependente] = useState(startData);

  const GetDependente = async () => {
    const data = await Requicicao.Get({
      endpoint: "/Funcionarios/Dependente",
      params: { funcionarioId: dados.id },
      config: auth.GetHeaders()
    });
    if (data.codigoEstadoHttp === 200) {
      setLista(data.objetoResposta);
    }
    return true;
  };

  const UpdeteDependente = async (lista) => {
    try {
      setLoading(true);
      const data = { id: dados.id };
      data.dependentes =
        dependente.nome !== "" ? [...lista, dependente] : lista;

      const response = await Requicicao.Put({
        endpoint: "/Funcionarios/Dependente",
        data,
        config: auth.GetHeaders()
      });

      if (response.codigoEstadoHttp === 200) {
        setDependente(startData);
        await GetDependente();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const RemoverDependente = (cpf) => {
    const lista = Lista.filter((x) => x.cpf !== cpf);
    UpdeteDependente(lista);
  };

  useEffect(() => {
    if (dados?.dependentes?.length > 0) {
      setPossuiDependentes(true);
      setLista(dados.dependentes);
    }
  }, [dados.dependentes]);

  return (
    <div className="row g-3">
      <div className="col-md-6 col-lg-3">
        <div className="d-flex flex-column">
          <label htmlFor="possuiDependentes" className="form-label">
            Possui Dependentes?
          </label>
          <InputSwitch
            id="possuiDependentes"
            checked={PossuiDependentes}
            onChange={(e) => setPossuiDependentes(e.value)}
            disabled={Lista.length > 0}
          />
        </div>
      </div>
      {PossuiDependentes ? (
        <>
          <div className="col-12">
            <h4>Cadastro de Dependentes</h4>
          </div>
          <div className="col-md-6 col-lg-3">
            <label>Nome do Dependente</label>
            <InputText
              value={dependente.nome}
              onChange={(e) =>
                setDependente({
                  ...dependente,
                  nome: e.target.value
                })
              }
              className="w-100"
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <label>Data de Nascimento</label>
            <Calendar
              value={dependente.dataNascimento}
              onChange={(e) =>
                setDependente({
                  ...dependente,
                  dataNascimento: e.value
                })
              }
              className="w-100"
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <label>Parentesco</label>
            <Dropdown
              value={dependente.parentesco}
              options={parentescos}
              onChange={(e) =>
                setDependente({
                  ...dependente,
                  parentesco: e.value
                })
              }
              className="w-100"
            />
          </div>
          <div className="col-md-6 col-lg-3">
            <label>CPF</label>
            <InputText
              value={dependente.cpf}
              onChange={(e) =>
                setDependente({
                  ...dependente,
                  cpf: MaskUtil.applyCpfMask(e.target.value)
                })
              }
              className="w-100"
            />
          </div>
          <div className="col-12">
            <div className="d-flex flex-row-reverse">
              <Button
                type="button"
                label="Adicionar Dependente"
                icon="pi pi-plus"
                onClick={() => UpdeteDependente(Lista)}
              />
            </div>
          </div>
          <div className="col-12">
            <DataTable value={Lista}>
              <Column field="nome" header="Nome" />
              <Column field="parentesco" header="Parentesco" />
              <Column field="cpf" header="CPF" />
              <Column
                field="nascimento"
                header="Data de Nascimento"
                body={(row) => MaskUtil.applyDataMask(row.nascimento)}
              />
              <Column
                header="Ação"
                body={(row) => (
                  <Button
                    type="button"
                    label="Remover"
                    onClick={() => RemoverDependente(row.cpf)}
                  />
                )}
              />
            </DataTable>
          </div>
        </>
      ) : (
        <p>Não tem dependentes</p>
      )}
    </div>
  );
};

Dependentes.propTypes = {
  dados: PropTypes.shape({
    possuiDependentes: PropTypes.bool.isRequired,
    dependentes: PropTypes.arrayOf(
      PropTypes.shape({
        nome: PropTypes.string.isRequired,
        parentesco: PropTypes.string.isRequired,
        cpf: PropTypes.string.isRequired,
        dataNascimento: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.instanceOf(Date)
        ]).isRequired
      })
    ).isRequired
  }).isRequired,
  dependente: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    parentesco: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
    dataNascimento: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]).isRequired
  }).isRequired
};

export default Dependentes;
