import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";

import PDFPreview from "components/PDFPreview";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const ExameMedico = ({ dados, statusAptidao, BoxBtns }) => {
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();
  const { setLoading } = useLoading();

  const [Doc, setDoc] = useState(null);

  const [nameSelectedImage, setNameSelectedImage] = useState("");

  const [ShowMoldaPdf, setShowMoldaPdf] = useState(false);
  const [PdfFile, setPdfFile] = useState(null);

  const HandleDownloadDocumento = async (nomeArquivo, tipo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Funcionarios/${dados.id}/${tipo}`,
        params: { nomeArquivo },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob"
        }
      });

      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return true;
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
      notify({
        type: "error",
        message: "Erro ao carregar a imagem. Tente novamente mais tarde."
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const HandleShowDocumento = async (nomeArquivo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Funcionarios/${dados.id}/DownloadDoc`,
        params: { nomeArquivo },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob" // Importante para obter o arquivo como Blob
        }
      });

      const blob = new Blob([response], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      setPdfFile(blobUrl);
      setShowMoldaPdf(true);
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
      notify({
        type: "error",
        message: "Erro ao carregar a imagem. Tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const Go = async () => {
      try {
        const data = await Requicicao.Get({
          endpoint: "/Funcionarios/DocumentoMedico",
          params: { funcionariosId: dados.id },
          config: auth.GetHeaders()
        });
        if (data.codigoEstadoHttp === 200) setDoc(data.objetoResposta);
      } catch (error) {
        console.error(error);
      }
    };
    if (dados.id != null && dados.id !== "") Go();
  }, [dados.id]);

  return (
    <div className="row g-3">
      <div className="col-md-6 col-lg-3">
        <label htmlFor="nomeMedico" className="form-label">
          Nome do Médico
        </label>
        <InputText
          id="nomeMedico"
          name="nomeMedico"
          value={dados.nomeMedico}
          disabled
          className="form-control"
        />
      </div>
      <div className="col-md-6 col-lg-3">
        <label htmlFor="crm" className="form-label">
          CRM
        </label>
        <InputMask
          id="crm"
          name="crm"
          value={dados.crm}
          disabled
          className="form-control"
          mask="99999999-9/BR"
        />
      </div>
      <div className="col-md-6 col-lg-3">
        <label htmlFor="dataAdmissaoExam" className="form-label">
          Data do Exame
        </label>
        <Calendar
          disabled
          dateFormat="dd/mm/yy"
          locale="pt-BR"
          className="w-100"
          value={new Date(dados.dataAdmissaoExam)}
        />
      </div>
      <div className="col-md-6 col-lg-3">
        <label className="mb-2">Status de Aptidão Médica</label>
        <div className="d-flex flex-wrap gap-3">
          <div className="d-flex align-items-center gap-1">
            <RadioButton
              inputId="statu1"
              name="statusAptidao"
              value="Apto"
              disabled
              checked={statusAptidao}
            />
            <label htmlFor="statu1">Apto</label>
          </div>
          <div className="d-flex align-items-center gap-1">
            <RadioButton
              inputId="statu2"
              name="statusAptidao"
              value="Não Apto"
              disabled
              checked={!statusAptidao}
            />
            <label htmlFor="statu2">Não Apto</label>
          </div>
        </div>
      </div>
      {Doc != null && (
        <div className="col-12">
          <p className="mb-1">Exame Medico</p>
          <div className="d-flex flex-row gap-3">
            <Button
              type="button"
              icon="pi pi-eye"
              onClick={() => {
                HandleShowDocumento(Doc.nomeArquivo);
                setNameSelectedImage(
                  Doc ? Doc.nomeArquivo : "Documento não encontrado"
                );
              }}
              tooltip="Visualizar"
              tooltipOptions={{ position: "bottom" }}
            />
            <Button
              type="button"
              icon="pi pi-download"
              onClick={() => {
                HandleDownloadDocumento(Doc.nomeArquivo, "DownloadDoc");
              }}
              tooltip="Baixa"
              tooltipOptions={{ position: "bottom" }}
            />
          </div>
        </div>
      )}
      <Dialog
        header={nameSelectedImage}
        visible={ShowMoldaPdf}
        onHide={() => setShowMoldaPdf(false)}
      >
        <PDFPreview file={PdfFile} />
      </Dialog>
      <div className="col-12">
        <BoxBtns />
      </div>
    </div>
  );
};

ExameMedico.propTypes = {
  dados: PropTypes.shape({
    id: PropTypes.string,
    nomeMedico: PropTypes.string,
    crm: PropTypes.string,
    dataAdmissaoExam: PropTypes.string
  }).isRequired,
  statusAptidao: PropTypes.bool.isRequired,
  BoxBtns: PropTypes.elementType.isRequired
};

export default ExameMedico;
