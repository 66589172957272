import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import PDFPreview from "components/PDFPreview";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";

const ListaDocumentos = ({ dados, funcionarioId, legible, setLegible }) => {
  const auth = useAuth();
  const notify = useNotification();
  const { setLoading } = useLoading();
  const Requicicao = new Api();

  const [nameSelectedImage, setNameSelectedImage] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);
  const [ShowMoldaImg, setShowMoldaImg] = useState(false);
  const [rotation, setRotation] = useState(0);

  const [ShowMoldaPdf, setShowMoldaPdf] = useState(false);
  const [PdfFile, setPdfFile] = useState(null);

  const ToggleLegibility = (id, valor) => {
    if (valor)
      setLegible((prevLegible) =>
        prevLegible.map((item) =>
          item.id === id ? { ...item, legivel: valor } : item
        )
      );
    else
      setLegible((prevLegible) =>
        prevLegible.map((item) =>
          item.id === id ? { ...item, legivel: valor, motivo: null } : item
        )
      );
  };

  const OnChange = (id, valor) => {
    setLegible((prevLegible) =>
      prevLegible.map((item) =>
        item.id === id ? { ...item, motivo: valor } : item
      )
    );
  };

  const HandleDownloadDocumento = async (nomeArquivo, tipo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Funcionarios/${funcionarioId}/${tipo}`,
        params: { nomeArquivo },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob"
        }
      });

      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return true;
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
      notify({
        type: "error",
        message: "Erro ao carregar a imagem. Tente novamente mais tarde."
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const HandleShowDocumento = async (nomeArquivo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Funcionarios/${funcionarioId}/DownloadArquivo`,
        params: { nomeArquivo },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob" // Importante para obter o arquivo como Blob
        }
      });

      const contentType = nomeArquivo;

      if (contentType.includes(".pdf")) {
        const blob = new Blob([response], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        setPdfFile(blobUrl);
        setShowMoldaPdf(true);
      } else if (
        contentType.includes(".jpg") ||
        contentType.includes(".jpeg") ||
        contentType.includes(".png")
      ) {
        // Trata o arquivo como imagem
        const blob = new Blob([response], { type: "image/" });
        const url = window.URL.createObjectURL(blob);
        setSelectedImage(url);
        setShowMoldaImg(true);
        setRotation(0);
      } else {
        throw new Error("Tipo de arquivo não suportado");
      }
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
      notify({
        type: "error",
        message: "Erro ao carregar a imagem. Tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  const openImage = async (fileName) => {
    try {
      setLoading(true);
      await HandleShowDocumento(fileName);
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
    } finally {
      setLoading(false);
    }
  };

  const RotateImage = (angle) => {
    setRotation((prevRotation) => prevRotation + angle);
  };

  const RemoveDoc = async (id) => {
    try {
      setLoading(true);
      const data = await Requicicao.Delete({
        endpoint: "/Funcionarios/RemoverArquivo",
        params: { id },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        notify({
          type: "sucesso",
          message: "Documento removeido com sucesso"
        });
      }
    } catch (error) {
      console.error(error);
      notify({
        type: "erro",
        message: "Erro ao remover documento"
      });
    } finally {
      setLoading(false);
    }
  };

  const ConfirmRemove = (id) => {
    confirmDialog({
      message: (
        <p className="m-0">
          Após a remoção, ele não poderá mais ser exigido, mesmo que ainda não
          tenha sido aprovado.
          <br />
          <b>Tem certeza de que deseja remover este documento?</b>
        </p>
      ),
      header: "Confirmação de Remoção",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => RemoveDoc(id), // Executa a remoção se o usuário aceitar
      reject: () => {} // Opcional, para lidar com a rejeição
    });
  };

  useEffect(() => {
    if (dados.arquivosContratacao != null && legible.length === 0) {
      setLegible(
        dados.arquivosContratacao.map((item) => ({
          id: item.id,
          legivel: item.legivel,
          motivo: item.motivo
        }))
      );
    }
  }, [dados.arquivosContratacao]);

  return (
    <div className="d-flex flex-row flex-wrap gap-3">
      <ConfirmDialog />
      {legible.length > 0 ? (
        dados.arquivosContratacao?.map((item) => {
          const docInfo = MaskEnum.TiposDocs.find(
            (obj) => obj.name === item.nomeArquivo.split("_")[0]
          );

          return (
            <div key={item.id} className="card p-3">
              <p className="mb-1">
                {docInfo ? docInfo.label : "Documento não encontrado"}
              </p>

              <div className="d-flex gap-3">
                <div className="d-flex flex-row align-items-center gap-1 mb-2">
                  <label htmlFor={`legible-${item.id}`} className="ml-2">
                    Não aprovar
                  </label>
                  <Checkbox
                    inputId={`legible-${item.id}`}
                    checked={
                      legible.find((x) => x.id === item.id)?.legivel === false
                    }
                    onChange={() => ToggleLegibility(item.id, false)}
                  />
                </div>
                <div className="d-flex flex-row align-items-center gap-1 mb-2">
                  <label htmlFor={`legible-${item.id}`} className="ml-2">
                    Aprovar
                  </label>
                  <Checkbox
                    inputId={`legible-${item.id}`}
                    checked={
                      legible.find((x) => x.id === item.id)?.legivel === true
                    }
                    onChange={() => ToggleLegibility(item.id, true)}
                  />
                </div>
              </div>
              {legible.find((x) => x.id === item.id)?.legivel === false && (
                <div className="mb-3">
                  <label htmlFor="motivo">Motivo</label>
                  <InputText
                    id="motivo"
                    className="w-100"
                    value={legible.find((x) => x.id === item.id)?.motivo ?? ""}
                    onChange={(e) => OnChange(item.id, e.target.value)}
                  />
                </div>
              )}
              <div className="d-flex flex-row-reverse flex-wrap gap-2">
                <Button
                  type="button"
                  icon="pi pi-eye"
                  className="p-button-sm p-button-text"
                  onClick={() => {
                    openImage(item.nomeArquivo);
                    setNameSelectedImage(
                      docInfo ? docInfo.label : "Documento não encontrado"
                    );
                  }}
                  tooltip="Visualizar"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  type="button"
                  icon="pi pi-download"
                  className="p-button-sm p-button-text"
                  onClick={() => {
                    HandleDownloadDocumento(
                      item.nomeArquivo,
                      "DownloadArquivo"
                    );
                  }}
                  tooltip="Baixa"
                  tooltipOptions={{ position: "bottom" }}
                />
                {/* <Button
                  type="button"
                  severity="danger"
                  icon="pi pi-file-excel"
                  onClick={() => ConfirmRemove(item.id)}
                  tooltip="Remover"
                  tooltipOptions={{ position: "bottom" }}
                /> */}
              </div>
            </div>
          );
        })
      ) : (
        <p>Documentos ainda não enviados</p>
      )}
      <Dialog
        header={nameSelectedImage}
        visible={ShowMoldaPdf}
        onHide={() => setShowMoldaPdf(false)}
      >
        <PDFPreview file={PdfFile} />
      </Dialog>
      <Dialog
        header={nameSelectedImage}
        visible={ShowMoldaImg}
        style={{ width: "50vw" }}
        onHide={() => setShowMoldaImg(false)}
        maximizable
      >
        {selectedImage && (
          <img
            src={selectedImage}
            alt="Imagem ampliada"
            style={{
              width: "100%",
              transform: `rotate(${rotation}deg)`,
              transition: "transform 0.5s ease"
            }}
          />
        )}
        <div className="d-flex flex-row gap-3 mt-3">
          <Button
            onClick={() => RotateImage(-90)}
            label="Girar Esquerda"
            icon="pi pi-replay"
          />
          <Button
            onClick={() => RotateImage(90)}
            label="Girar Direita"
            icon="pi pi-refresh"
          />
        </div>
      </Dialog>
    </div>
  );
};

ListaDocumentos.propTypes = {
  dados: PropTypes.shape({
    arquivosContratacao: PropTypes.arrayOf(
      PropTypes.shape({
        nomeArquivo: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  funcionarioId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  legible: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      legivel: PropTypes.bool.isRequired
    })
  ).isRequired,
  setLegible: PropTypes.func.isRequired
};

export default ListaDocumentos;
