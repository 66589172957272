import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";

const ModalPermissoes = ({ FuncionarioId, ShowModalPermissoes, Close }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const { Permissoes } = MaskEnum;

  const [SelectedPermissao, setSelectedPermissao] = useState([]);

  // const AdicionarPremisao = async (params) => {
  //   params.preventDefault();
  //   try {
  //     setLoading(true);
  //     const data = await Requicicao.Post({
  //       endpoint: "/Funcionarios/Permissao",
  //       data: { FuncionarioId, PermissoeId: SelectedPermissao[0] }
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const RemoverPremisao = async (params) => {
  //   params.preventDefault();
  //   try {
  //     setLoading(true);
  //     const data = await Requicicao.Delete({
  //       endpoint: "/Funcionarios/Permissao",
  //       params: { FuncionarioId, PermissoeId: SelectedPermissao[0] }
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const AtulizaPremisao = async (params) => {
    params.preventDefault();
    try {
      setLoading(true);
      const data = await Requicicao.Put({
        endpoint: "/Funcionarios/Permissao",
        data: { FuncionarioId, PermissoesIds: SelectedPermissao },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        notify({ type: "ok", message: "Novas Permissoes Salva." });
        Close();
        setSelectedPermissao([]);
      } else {
        notify({ type: "erro", message: data.mensagemAdicional });
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.mensagemAdicional != null)
        notify({
          type: "erro",
          message: error.response.data.mensagemAdicional
        });
    } finally {
      setLoading(false);
    }
  };

  const GetMinhasPermissoes = async () => {
    try {
      if (ShowModalPermissoes === true) {
        setLoading(true);
        const data = await Requicicao.Get({
          endpoint: "/Funcionarios/Permissao",
          params: { funcionarioId: FuncionarioId },
          config: auth.GetHeaders()
        });
        if (data.codigoEstadoHttp === 200) {
          const lista = data.objetoResposta.map((x) => x.value);
          setSelectedPermissao([...new Set(lista)]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated() && FuncionarioId != null) GetMinhasPermissoes();
  }, [FuncionarioId]);

  return (
    <Dialog header="Permissões" visible={ShowModalPermissoes} onHide={Close}>
      <form onSubmit={AtulizaPremisao}>
        <div>
          <label className="form-label">Permissoes</label>
          <div className="card flex justify-content-center">
            <MultiSelect
              value={SelectedPermissao}
              onChange={(e) => setSelectedPermissao(e.value)}
              options={Permissoes}
              placeholder="Select Permissao"
              maxSelectedLabels={3}
              className="w-100"
              style={{ minWidth: "200px" }}
            />
          </div>
          <div className="mt-3 d-flex flex-row-reverse">
            <Button label="Salvar" />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

ModalPermissoes.propTypes = {
  FuncionarioId: PropTypes.string.isRequired,
  ShowModalPermissoes: PropTypes.bool.isRequired,
  Close: PropTypes.func.isRequired
};

export default ModalPermissoes;
