/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useRef, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { OverlayPanel } from "primereact/overlaypanel";

import { useAuth } from "context/AuthContext";

import Api from "utils/Api";

import IconUser from "../../assets/icons/l-1.jpg";
import Logo from "../../assets/img/logo/black-full.63543a82.png";
import "./styled.css";

const Header = () => {
  const auth = useAuth();
  const op = useRef(null);
  const notification = useRef(null);
  const navigate = useNavigate();
  const Requicicao = new Api();

  const notificacaoConfig = {
    0: { icon: "pi pi-info-circle", color: "#2196F3" },
    1: { icon: "pi pi-check-circle", color: "#4CAF50" },
    2: { icon: "pi pi-exclamation-triangle", color: "#FFC107" },
    3: { icon: "pi pi-times-circle", color: "#F44336" },
    4: { icon: "pi pi-cog", color: "#9E9E9E" },
    5: { icon: "pi pi-user", color: "#3F51B5" },
    6: { icon: "pi pi-list", color: "#8BC34A" },
    7: { icon: "pi pi-envelope", color: "#FF9800" },
    8: { icon: "pi pi-refresh", color: "#00BCD4" },
    9: { icon: "pi pi-calendar", color: "#9C27B0" },
    10: { icon: "pi pi-flag", color: "#E91E63" },
    11: { icon: "pi pi-check", color: "#607D8B" },
    12: { icon: "pi pi-bell", color: "#FFEB3B" },
    13: { icon: "pi pi-exclamation-circle", color: "#D32F2F" },
    14: { icon: "pi pi-star", color: "#FF5722" }
  };

  // const NotificationMenuEX = [
  //   {
  //     id: 1,
  //     titulo: "Informação geral",
  //     tipoNotificacao: 0,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 2,
  //     titulo: "Operação concluída",
  //     tipoNotificacao: 1,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 3,
  //     titulo: "Atenção necessária",
  //     tipoNotificacao: 2,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 4,
  //     titulo: "Erro no sistema",
  //     tipoNotificacao: 3,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 5,
  //     titulo: "Configuração do sistema",
  //     tipoNotificacao: 4,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 6,
  //     titulo: "Novo usuário registrado",
  //     tipoNotificacao: 5,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 7,
  //     titulo: "Tarefa concluída",
  //     tipoNotificacao: 6,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 8,
  //     titulo: "Nova mensagem",
  //     tipoNotificacao: 7,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 9,
  //     titulo: "Atualização disponível",
  //     tipoNotificacao: 8,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 10,
  //     titulo: "Evento importante",
  //     tipoNotificacao: 9,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 11,
  //     titulo: "Solicitação pendente",
  //     tipoNotificacao: 10,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 12,
  //     titulo: "Confirmação requerida",
  //     tipoNotificacao: 11,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 13,
  //     titulo: "Lembrete de reunião",
  //     tipoNotificacao: 12,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 14,
  //     titulo: "Alerta crítico",
  //     tipoNotificacao: 13,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   },
  //   {
  //     id: 15,
  //     titulo: "Notificação personalizada",
  //     tipoNotificacao: 14,
  //     dataCriacao: new Date(),
  //     visualizado: false
  //   }
  // ];

  const [NotificationMenu, setNotificationMenu] = useState([]);
  const [Nome, setNome] = useState("Nome");

  const SortNotification = (a, b) => {
    const dataA = new Date(a.dataCriacao);
    const dataB = new Date(b.dataCriacao);
    return dataB - dataA;
  };

  const GetNotificao = async () => {
    try {
      const data = await Requicicao.Get({
        endpoint: "/Notificacao/My",
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setNotificationMenu(
          data.objetoResposta
            .sort(SortNotification)
            .filter((x) => x.visualizado === false)
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const AtulizaNotificao = async () => {
    // minutos
    const intervalo = 10;
    await GetNotificao();
    setTimeout(() => GetNotificao(), intervalo * 6000);
  };

  const MarcarComoLida = async (params) => {
    try {
      await Requicicao.Put({
        endpoint: "/Notificacao/Visualizado",
        data: { NotificacaoId: params },
        config: auth.GetHeaders()
      });
      await GetNotificao();
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * limita o tamanho do texto, baseado em um tamanho específico
   * @param {string} texto
   * @param {number} tamanho
   * @returns {string}
   */
  const LimeteTamanhoTesto = (texto, tamanho) => {
    if (texto.length > tamanho) {
      return `${texto.substring(0, tamanho)}...`;
    }
    return texto;
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      AtulizaNotificao();

      const nomeCompleto = auth.UserNome().toString();
      const nomeAjustado = LimeteTamanhoTesto(nomeCompleto, 40);

      setNome(nomeAjustado);
    }
  }, [auth]);

  return (
    <header className="header-container">
      <div className="header-box">
        <Link to="/app" className="header-item-center">
          <img src={Logo} alt="Logo da Empresa" style={{ height: "40px" }} />
        </Link>
        <div className="header-item-right">
          <div className="header-box-item-menu">
            <button
              type="button"
              className="button-none"
              onClick={(e) => {
                if (NotificationMenu.length > 0) {
                  notification.current.toggle(e);
                } else {
                  navigate("/app/notifications");
                }
              }}
            >
              <Avatar
                className="p-overlay-badge"
                icon="pi pi-bell"
                shape="circle"
                style={{ backgroundColor: "#0000000", color: "#000000" }}
              >
                {NotificationMenu.length > 0 && (
                  <Badge value={NotificationMenu.length} severity="danger" />
                )}
              </Avatar>
            </button>
            <OverlayPanel ref={notification}>
              <div className="box-notifications">
                {NotificationMenu.map((item) => {
                  const { icon, color } =
                    notificacaoConfig[item.tipoNotificacao];

                  return (
                    <div
                      key={item.id}
                      className="notification-item shadow-sm"
                      style={{ borderLeft: `5px solid ${color}` }}
                    >
                      <div className="d-flex gap-1">
                        <i
                          className={`notification-icon ${icon}`}
                          style={{ color }}
                        />
                        <h5>{LimeteTamanhoTesto(item.titulo, 30)}</h5>
                      </div>
                      <span className="notification-date">
                        <div className="d-flex justify-content-between align-items-center mt-1">
                          <div className="d-flex gap-1">
                            <button
                              type="button"
                              className="btn btn-notification"
                              onClick={() => MarcarComoLida(item.id)}
                            >
                              Lido
                            </button>
                            <Link
                              to="/app/notifications"
                              className="btn btn-notification"
                            >
                              Ler mais
                            </Link>
                          </div>

                          {new Date(item.dataCriacao).toLocaleDateString()}
                        </div>
                      </span>
                    </div>
                  );
                })}
              </div>
            </OverlayPanel>
          </div>
          <button
            type="button"
            className="button-none header-button-user"
            onClick={(e) => op.current.toggle(e)}
          >
            <span>{Nome}</span>
            <span>
              <img className="header-button-img" src={IconUser} alt="user" />
            </span>
          </button>
          <OverlayPanel ref={op}>
            <div className="d-flex flex-column gap-3">
              {/* <Link className="btn btn-user" to="/app/seusdados">
                Seus dados
              </Link> */}
              <button
                className="btn btn-user"
                type="button"
                onClick={() => {
                  auth.logout();
                  navigate("/");
                }}
              >
                Sair
              </button>
            </div>
          </OverlayPanel>
        </div>
      </div>
    </header>
  );
};

export default Header;
