import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Password } from "primereact/password";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

const FormContas = ({ ContaId }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();
  const navigate = useNavigate();

  const NivelEnum = [
    { label: "GestaoPortal", valor: 20 },
    { label: "UsuarioLocal", valor: 30 },
    { label: "GestaoCaixa", valor: 40 },
    { label: "Sincronizador", valor: 100 }
  ];

  const [dados, setDados] = useState({
    acesso: "",
    senha: "",
    permissoes: [],
    nomeCompletoOuRazaoSocial: "",
    apelidoOuNomeFantasia: "",
    cpfOuCnpj: "",
    empresaMatrizId: ""
  });
  const [ListaMatris, setListaMatris] = useState([]);

  const HandleChangeDados = (event) => {
    try {
      const { name, value } = event.target;
      if (name === "cpfOuCnpj") {
        const maskedValue = MaskUtil.applyCpfCnpjMask(value);
        setDados((prevState) => ({ ...prevState, [name]: maskedValue }));
      } else {
        setDados((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UpdateConta = async (data) => {
    try {
      await Requicicao.Put({
        endpoint: "/Conta",
        data,
        config: auth.GetHeaders()
      });
      notify({
        type: "sucesso",
        message: "Atualização realizada com sucesso."
      });
      navigate("/app/conta");
    } catch (error) {
      console.error(error);
      notify({
        type: "erro",
        message:
          "Erro ao tentar executar esta ação. Por favor, entre em contato com o suporte."
      });
    }
  };

  const AddConta = async (data) => {
    try {
      await Requicicao.Post({
        endpoint: "/conta",
        data,
        config: auth.GetHeaders()
      });
      notify({
        type: "sucesso",
        message: "Conta criada com sucesso"
      });
      navigate("/app/conta");
    } catch (error) {
      notify({
        type: "erro",
        message:
          "Erro ao tentar executar esta ação. Por favor, entre em contato com o suporte."
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const data = {
        ...dados,
        permissoes: dados.permissoes.map((item) => item.valor),
        empresaMatrizId: dados.empresaMatrizId.valor
      };
      if (ContaId === "") {
        await AddConta(data);
      } else {
        data.id = ContaId;
        await UpdateConta(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const GetConta = async (listaMatris) => {
    try {
      const data = await Requicicao.Get({
        endpoint: "/Conta/ObterPorId",
        params: { id: ContaId },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        const newDados = {
          ...data.objetoResposta,
          permissoes: data.objetoResposta.permissoes.map((item) => ({
            label: item.permissaoNome,
            valor: item.permissaoId
          })),
          empresaMatrizId: listaMatris.filter(
            (item) => item.valor === data.objetoResposta.empresaMatrizId
          )[0]
        };
        setDados(newDados);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetListaMastri = async () => {
    try {
      const data = await Requicicao.Get({
        endpoint: "/Empresa/ObterMatrizes",
        config: auth.GetHeaders()
      });

      const newLista = [];

      data.objetoResposta.forEach((e) => {
        newLista.push({ label: e.nomeCompletoOuRazaoSocial, valor: e.id });
      });
      setListaMatris(newLista);
      return newLista;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    const Go = async () => {
      try {
        setLoading(true);
        const lsita = await GetListaMastri();
        if (ContaId !== "") await GetConta(lsita);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (auth.isAuthenticated()) Go();
  }, [auth]);

  return (
    <form className="card-body" onSubmit={handleSubmit}>
      <div className="row g-3">
        <div className="col-6">
          <label htmlFor="acesso" className="form-label">
            Acesso (E-mail)
          </label>
          <InputText
            id="acesso"
            name="acesso"
            className="form-control"
            value={dados.acesso}
            onChange={HandleChangeDados}
          />
        </div>

        <div className="col-6">
          <label htmlFor="permissoes" className="form-label">
            Permissões
          </label>
          <MultiSelect
            id="permissoes"
            name="permissoes"
            value={dados.permissoes}
            onChange={(e) =>
              HandleChangeDados({
                target: { value: e.value, name: "permissoes" }
              })
            }
            options={NivelEnum}
            placeholder="Selecione as permissões"
            maxSelectedLabels={3}
            className="w-100"
          />
        </div>

        <div className="col-6">
          <label htmlFor="senha" className="form-label">
            Senha
          </label>
          <Password
            id="senha"
            name="senha"
            className="w-100"
            value={dados.senha}
            onChange={HandleChangeDados}
            toggleMask
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="cpfOuCnpj" className="form-label">
            CPF ou CNPJ
          </label>
          <InputText
            id="cpfOuCnpj"
            name="cpfOuCnpj"
            value={dados.cpfOuCnpj}
            onChange={HandleChangeDados}
            className="form-control"
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="nomeCompletoOuRazaoSocial" className="form-label">
            Nome Completo ou Razão Social
          </label>
          <InputText
            id="nomeCompletoOuRazaoSocial"
            name="nomeCompletoOuRazaoSocial"
            value={dados.nomeCompletoOuRazaoSocial}
            onChange={HandleChangeDados}
            className="form-control"
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="apelidoOuNomeFantasia" className="form-label">
            Apelido ou Nome Fantasia
          </label>
          <InputText
            id="apelidoOuNomeFantasia"
            name="apelidoOuNomeFantasia"
            value={dados.apelidoOuNomeFantasia}
            onChange={HandleChangeDados}
            className="form-control"
          />
        </div>

        {auth.GetAccessLevel().includes(10) && (
          <div className="col-md-6 mb-3">
            <label htmlFor="empresaMatrizId" className="form-label">
              Matriz
            </label>
            <Dropdown
              id="empresaMatrizId"
              name="empresaMatrizId"
              className="w-100"
              options={ListaMatris}
              value={dados.empresaMatrizId}
              onChange={(e) =>
                HandleChangeDados({
                  target: { value: e.value, name: "empresaMatrizId" }
                })
              }
            />
          </div>
        )}

        <div className="col-12">
          <div className="d-flex flex-row-reverse">
            <Button label="Savar" type="submit" />
          </div>
        </div>
      </div>
    </form>
  );
};

FormContas.propTypes = { ContaId: PropTypes.string };

FormContas.defaultProps = {
  ContaId: ""
};

export default FormContas;
