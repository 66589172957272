import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => (
  <div className="container mt-5">
    <div className="row">
      <div className="col-md-6 offset-md-3 text-center">
        <h1 className="display-4">
          Erro 404
          <br />
          Página não encontrada
        </h1>
        <p className="lead">Essa rota ou não existe ou foi apagada.</p>
        <p>
          Entre em contato com o administrador do sistema para obter
          assistência.
        </p>
        <Link to="/app" className="btn btn-primary mt-3">
          Voltar para a Página Inicial
        </Link>
      </div>
    </div>
  </div>
);

export default Error404;
