import { useRef } from "react";

import { toPng } from "html-to-image";
import jsPDF from "jspdf";
import PropTypes from "prop-types";
import { QRCodeSVG } from "qrcode.react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const QrCode = ({ FuncionarioId, ShowQRcode, setShowQRcode }) => {
  const qrCodeRef = useRef(null);

  const HandleDownloadPDF = async () => {
    try {
      if (qrCodeRef.current) {
        // Gera a imagem PNG a partir do QR Code
        const imgData = await toPng(qrCodeRef.current);
        // Cria um novo documento PDF
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF();
        // Adiciona a imagem ao PDF
        pdf.addImage(imgData, "PNG", 10, 10, 180, 180);
        // Salva o PDF
        pdf.save("QRCode.pdf");
      }
    } catch (error) {
      console.error("Erro ao gerar PDF", error);
    }
  };

  return (
    <Dialog
      header="QR Code"
      visible={ShowQRcode}
      onHide={() => setShowQRcode(false)}
      footer={() => (
        <Button type="button" onClick={HandleDownloadPDF} label="Baixar PDF" />
      )}
    >
      <div ref={qrCodeRef} className="box-qr">
        <QRCodeSVG
          value={`http://localhost:3000/login?info=${FuncionarioId}`}
        />
      </div>
    </Dialog>
  );
};

QrCode.propTypes = {
  FuncionarioId: PropTypes.string.isRequired,
  ShowQRcode: PropTypes.bool.isRequired,
  setShowQRcode: PropTypes.func.isRequired
};

export default QrCode;
