import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { AutoComplete } from "primereact/autocomplete"; // Colocar antes de primereact/button
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import TrataRetornoApi from "utils/TrataRetornoApi";

import EmailModule from "./modules/EmailModule";
import EnderecoModule from "./modules/EnderecoModule";
import PessoaModule from "./modules/PessoaModule";
import TelefoneModule from "./modules/TelefoneModule";

const FormEmpresa = ({ EmpresaId }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const requisicao = new Api();
  const tratarRetornoApi = new TrataRetornoApi();
  const exibirNotificacao = useNotification();
  const navigate = useNavigate();
  const [exibirCampoMatriz, setExibirCampoMatriz] = useState(true);
  const [exibirCampoOperador, setExibirCampoOperador] = useState(false);
  const [dados, setDados] = useState({
    ehMatriz: true,
    matrizId: null,
    pontoVendaAnfitriaoId: null,
    restricoes: "",
    operadorId: null,
    senhaCertificadoDigital: "",
    csc: "",
    idcsc: "",
    anyDesk: "",
    pessoa: {
      nomeCompletoOuRazaoSocial: "",
      apelidoOuNomeFantasia: "",
      cpfOuCnpj: "",
      inscricaoEstadual: "",
      inscricaoMunicipal: "",
      enderecos: [],
      telefones: [],
      emails: []
    }
  });

  const [Pessoa, setPessoa] = useState({
    nomeCompletoOuRazaoSocial: "",
    apelidoOuNomeFantasia: "",
    cpfOuCnpj: ""
  });

  const [ListaEnderecos, setListaEnderecos] = useState([]);
  const [LitasTelefone, setLitasTelefone] = useState([]);
  const [ListaEmail, setListaEmail] = useState([]);

  const [ListaMatris, setListaMatris] = useState([]);

  const [SelectedMatriz, setSelectedMatriz] = useState(null);
  const [SelectedPontoVenda, setSelectedPontoVenda] = useState(null);
  const [operadorSelecionado, setOperadorSelecionado] = useState(null);

  const [ListaPontaVenda, setListaPontaVenda] = useState([]);
  const [listaOperadores, setListaOperadores] = useState([]);

  const [filteredPontosVenda, setFilteredPontosVenda] = useState([]); // Renomear

  const [filteredMatrizes, setFilteredMatrizes] = useState([]); // Estado para armazenar as matrizes filtradas

  const [mostrarSenha, setMostrarSenha] = useState(false);

  const searchPontosVenda = (event) => {
    setFilteredPontosVenda(
      ListaPontaVenda.filter((pv) =>
        pv.name.toLowerCase().includes(event.query.toLowerCase())
      )
    );
  };

  const searchMatrizes = (event) => {
    setFilteredMatrizes(
      ListaMatris.filter((matriz) =>
        matriz.name.toLowerCase().includes(event.query.toLowerCase())
      )
    );
  };

  const HandleChangeDados = (event) => {
    try {
      const { name, value } = event.target;

      if (name.startsWith("pessoa.")) {
        const pessoaField = name.split(".")[1];
        setDados((prevState) => ({
          ...prevState,
          pessoa: {
            ...prevState.pessoa,
            [pessoaField]: value
          }
        }));
      } else {
        // Se não, atualize-o normalmente
        setDados((prevState) => ({
          ...prevState,
          [name]: value
        }));
      }

      if (name === "ehMatriz" || value === false) {
        setDados((aqui) => ({ ...aqui, matrizId: 0 }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const AddLoja = async () => {
    try {
      await requisicao.Post({
        endpoint: "/Empresa",
        data: dados,
        config: auth.GetHeaders()
      });
      exibirNotificacao({
        type: "sucesso",
        message: "Loja criada com sucesso"
      });
      navigate("/app/empresa");
    } catch (error) {
      exibirNotificacao({
        type: "erro",
        message: tratarRetornoApi.obterMensagemErro(error)
      });
    }
  };

  const UpdateLoja = async () => {
    try {
      const resposta = await requisicao.Put({
        endpoint: "/Empresa",
        data: dados,
        config: auth.GetHeaders()
      });

      if (resposta?.codigoEstadoHttp === 200) {
        exibirNotificacao({
          type: "sucesso",
          message: "Atualização realizada com sucesso."
        });
      }
    } catch (error) {
      exibirNotificacao({
        type: "erro",
        message: tratarRetornoApi.obterMensagemErro(error)
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (EmpresaId === "") await AddLoja();
      else await UpdateLoja();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const FitroElementoslista = (lista, tiop) => {
    const newListe = [];

    switch (tiop) {
      case "enderecos":
        lista.forEach((e) => {
          const {
            id,
            apelido,
            cep,
            enderecoDaPessoa,
            numero,
            complemento,
            estado,
            cidade,
            bairro
          } = e;
          newListe.push({
            id,
            apelido,
            cep,
            enderecoDaPessoa,
            numero,
            complemento,
            estado,
            cidade,
            bairro
          });
        });
        return newListe;
      case "telefones":
        lista.forEach((e) => {
          const { id, apelido, ddd, numero } = e;
          newListe.push({
            id,
            apelido,
            ddd,
            numero
          });
        });
        return newListe;
      case "emails":
        lista.forEach((e) => {
          const { id, apelido, emailDaPessoa } = e;
          newListe.push({
            id,
            apelido,
            emailDaPessoa
          });
        });
        return newListe;
      default:
        return newListe;
    }
  };

  const GetThisEmpresa = async (
    listaMastri,
    listaPontaVenda,
    listaOperadores2
  ) => {
    try {
      setLoading(true);
      const { objetoResposta } = await requisicao.Get({
        endpoint: "/empresa/ObterPorId",
        params: { id: EmpresaId },
        config: auth.GetHeaders()
      });
      const {
        id,
        nomeCompletoOuRazaoSocial,
        apelidoOuNomeFantasia,
        cpfOuCnpj,
        inscricaoEstadual,
        inscricaoMunicipal
        // senhaCertificadoDigital,
        // csc,
        // idcsc,
        // anyDesk
      } = objetoResposta.pessoa;
      setPessoa({
        id,
        nomeCompletoOuRazaoSocial,
        apelidoOuNomeFantasia,
        cpfOuCnpj,
        inscricaoEstadual,
        inscricaoMunicipal
      });

      setDados((esta) => ({
        ...esta,
        ...{
          id: objetoResposta.id,
          ehMatriz: objetoResposta.ehMatriz,
          restricoes: objetoResposta.restricoes,
          pontoVendaAnfitriaoId: objetoResposta.pontoVendaAnfitriaoId,
          senhaCertificadoDigital: objetoResposta.senhaCertificadoDigital, // Mapeando os novos campos
          csc: objetoResposta.csc,
          idcsc: objetoResposta.idcsc,
          anyDesk: objetoResposta.anyDesk,
          pessoa: {
            ...esta.pessoa,
            ...{
              id,
              nomeCompletoOuRazaoSocial,
              apelidoOuNomeFantasia,
              cpfOuCnpj,
              inscricaoEstadual,
              inscricaoMunicipal,
              enderecos: FitroElementoslista(
                objetoResposta.pessoa.enderecos,
                "enderecos"
              ),
              telefones: FitroElementoslista(
                objetoResposta.pessoa.telefones,
                "telefones"
              ),
              emails: FitroElementoslista(
                objetoResposta.pessoa.emails,
                "emails"
              )
            }
          }
        }
      }));

      if (objetoResposta.matrizId !== null) {
        setDados((esta) => ({ ...esta, matrizId: objetoResposta.matrizId }));
      } else {
        setDados((esta) => ({ ...esta, matrizId: null }));
      }

      const listaEnderecos = [];
      objetoResposta.pessoa.enderecos.forEach((element, i) => {
        listaEnderecos.push({ ...element, indice: i });
      });
      setListaEnderecos(listaEnderecos);
      const listaTelefone = [];
      objetoResposta.pessoa.telefones.forEach((element, i) => {
        listaTelefone.push({ ...element, indice: i });
      });
      setLitasTelefone(listaTelefone);
      const listaEmail = [];
      objetoResposta.pessoa.emails.forEach((element, i) => {
        listaEmail.push({ ...element, indice: i });
      });
      setListaEmail(listaEmail);

      const matriz = listaMastri.filter(
        (e) => e.code === objetoResposta.matrizId
      );
      setSelectedMatriz(matriz[0]);

      const pontoVenda = listaPontaVenda.filter(
        (e) => e.code === objetoResposta.pontoVendaAnfitriaoId
      );
      setSelectedPontoVenda(pontoVenda[0]);

      const operador = listaOperadores2.filter(
        (e) => e.code === objetoResposta.operadorId
      );
      setOperadorSelecionado(operador[0]);

      if (objetoResposta.ehMatriz) {
        setExibirCampoMatriz(false);
        setExibirCampoOperador(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const GetListaMastri = async () => {
    try {
      const data = await requisicao.Get({
        endpoint: "/Empresa/ObterMatrizes",
        config: auth.GetHeaders()
      });

      const newLista = [];

      data.objetoResposta.forEach((e) => {
        newLista.push({ name: e.nomeCompletoOuRazaoSocial, code: e.id });
      });

      setListaMatris(newLista);
      return newLista;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const GetPontoVendaAnfitriao = async () => {
    try {
      const data = await requisicao.Get({
        endpoint: "/PontoVendaAnfitriao",
        config: auth.GetHeaders()
      });
      const lista = [];
      data.objetoResposta.forEach((e) => {
        lista.push({ name: e.nomeCompletoOuRazaoSocial, code: e.id });
      });
      setListaPontaVenda(lista);
      return lista;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const toggleMostrarSenha = () => {
    setMostrarSenha((prev) => !prev);
  };

  const obterOperadores = async () => {
    const lista = [];

    try {
      const resposta = await requisicao.Get({
        endpoint: `/Operador`,
        config: auth.GetHeaders()
      });

      if (resposta?.codigoEstadoHttp === 200) {
        resposta.objetoResposta.forEach((e) => {
          lista.push({ name: e.pessoa.nomeCompletoOuRazaoSocial, code: e.id });
        });

        setListaOperadores(lista);
      }
    } catch (error) {
      exibirNotificacao({
        type: "erro",
        message: tratarRetornoApi.obterMensagemErro(error)
      });
    }

    return lista;
  };

  useEffect(() => {
    const Go = async () => {
      const listaMastri = await GetListaMastri();
      const listaPontaVenda = await GetPontoVendaAnfitriao();
      const listaOperadores2 = await obterOperadores();
      if (EmpresaId !== "")
        await GetThisEmpresa(listaMastri, listaPontaVenda, listaOperadores2);
    };

    if (auth.isAuthenticated()) Go();
  }, [auth]);

  useEffect(() => {
    setDados({ ...dados, pessoa: { ...dados.pessoa, ...Pessoa } });
  }, [Pessoa]);

  useEffect(() => {
    if (LitasTelefone.length > 0) {
      const newLista = [];
      LitasTelefone.forEach((e) => {
        const { id, apelido, ddd, numero } = e;
        newLista.push({
          id,
          apelido,
          ddd,
          numero
        });
      });

      setDados((este) => ({
        ...este,
        pessoa: { ...dados.pessoa, telefones: newLista }
      }));
    }
  }, [LitasTelefone]);

  useEffect(() => {
    if (ListaEnderecos.length > 0) {
      const newLista = [];
      ListaEnderecos.forEach((e) => {
        const {
          id,
          apelido,
          cep,
          enderecoDaPessoa,
          numero,
          complemento,
          estado,
          cidade,
          bairro
        } = e;
        newLista.push({
          id,
          apelido,
          cep,
          enderecoDaPessoa,
          numero,
          complemento,
          estado,
          cidade,
          bairro
        });
      });

      setDados((este) => ({
        ...este,
        pessoa: { ...dados.pessoa, enderecos: newLista }
      }));
    }
  }, [ListaEnderecos]);

  useEffect(() => {
    if (ListaEmail.length > 0) {
      const newLista = [];
      ListaEmail.forEach((e) => {
        const { id, apelido, emailDaPessoa } = e;
        newLista.push({ id, apelido, emailDaPessoa });
      });

      setDados((este) => ({
        ...este,
        pessoa: { ...dados.pessoa, emails: newLista }
      }));
    }
  }, [ListaEmail]);

  return (
    <form onSubmit={handleSubmit} className="card-body">
      <div className="row">
        <div className="col-md-6 mb-3">
          <div
            className="h-100 d-flex align-items-end"
            style={{ paddingBottom: "5.6px" }}
          >
            <div className="w-100 d-flex gap-3 align-items-center">
              <label htmlFor="ehMatriz">Filial</label>
              <InputSwitch
                id="ehMatriz"
                name="ehMatriz"
                checked={dados.ehMatriz}
                onChange={(e) => {
                  setDados((este) => ({
                    ...este,
                    ehMatriz: e.value
                  }));
                  if (e.target.value) {
                    setSelectedMatriz(null);
                    setSelectedPontoVenda(null);
                    setDados((este) => ({
                      ...este,
                      ...{ matrizId: null, pontoVendaAnfitriaoId: null }
                    }));
                    setExibirCampoMatriz(false);
                    setExibirCampoOperador(true);
                  } else {
                    setOperadorSelecionado(null);
                    setExibirCampoMatriz(true);
                    setExibirCampoOperador(false);
                  }
                }}
              />
              <label>Matriz</label>
            </div>
          </div>
        </div>

        {exibirCampoMatriz && (
          <>
            <div className="col-md-6 mb-3">
              <label className="form-label">Matriz</label>
              <AutoComplete
                value={SelectedMatriz}
                suggestions={filteredMatrizes}
                completeMethod={searchMatrizes}
                field="name"
                dropdown
                onChange={(e) => {
                  setSelectedMatriz(e.value);
                  setDados({ ...dados, matrizId: e.value.code });
                }}
                placeholder="Selecione a Matriz"
                className="w-100"
                disabled={dados.ehMatriz}
              />
            </div>

            <div className="col-md-6 mb-3">
              <label className="form-label">Loja</label>
              <AutoComplete
                value={SelectedPontoVenda}
                suggestions={filteredPontosVenda}
                completeMethod={searchPontosVenda}
                field="name"
                dropdown
                onChange={(e) => {
                  setSelectedPontoVenda(e.value);
                  setDados({
                    ...dados,
                    pontoVendaAnfitriaoId: e.value.code,
                    ehMatriz: false
                  });
                }}
                placeholder="Selecione a Loja"
                className="w-100"
              />
            </div>
          </>
        )}

        {exibirCampoOperador && (
          <div className="col-md-6 mb-3">
            <label className="form-label">Operador</label>
            <Dropdown
              value={operadorSelecionado}
              onChange={(e) => {
                setOperadorSelecionado(e.value);
                setDados({ ...dados, operadorId: e.value.code });
              }}
              options={listaOperadores}
              optionLabel="name"
              placeholder="Selecione o Operador"
              className="w-100"
              disabled={!dados.ehMatriz}
            />
          </div>
        )}

        <div className="col-md-6 mb-3">
          <label htmlFor="restricoes" className="form-label">
            Restrições
          </label>
          <InputTextarea
            id="restricoes"
            name="restricoes"
            className="form-control"
            autoResize
            value={dados.restricoes}
            onChange={HandleChangeDados}
            rows={5}
            cols={30}
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="inscricaoEstadual" className="form-label">
            Inscrição Estadual
          </label>
          <InputText
            id="inscricaoEstadual"
            name="pessoa.inscricaoEstadual"
            className="form-control"
            value={dados.pessoa.inscricaoEstadual}
            onChange={HandleChangeDados}
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="inscricaoMunicipal" className="form-label">
            Inscrição Municipal
          </label>
          <InputText
            id="inscricaoMunicipal"
            name="pessoa.inscricaoMunicipal"
            value={dados.pessoa.inscricaoMunicipal}
            onChange={HandleChangeDados}
            className="form-control"
          />
        </div>

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <PessoaModule Pessoa={Pessoa} setPessoa={setPessoa} />

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <EnderecoModule
          ListaEnderecos={ListaEnderecos}
          setListaEnderecos={setListaEnderecos}
        />

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <TelefoneModule
          LitasTelefone={LitasTelefone}
          setLitasTelefone={setLitasTelefone}
        />

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <EmailModule ListaEmail={ListaEmail} setListaEmail={setListaEmail} />

        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="senhaCertificadoDigital" className="form-label">
            Senha do Certificado Digital
          </label>
          <div className="p-inputgroup">
            <InputText
              id="senhaCertificadoDigital"
              name="senhaCertificadoDigital"
              type={mostrarSenha ? "text" : "password"}
              value={dados.senhaCertificadoDigital}
              onChange={HandleChangeDados}
              className="form-control"
            />
            <Button
              type="button"
              icon={mostrarSenha ? "pi pi-eye-slash" : "pi pi-eye"}
              onClick={toggleMostrarSenha}
              className="p-button-text p-button-plain"
            />
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="csc" className="form-label">
            CSC
          </label>
          <InputText
            id="csc"
            name="csc"
            value={dados.csc}
            onChange={HandleChangeDados}
            className="form-control"
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="idcsc" className="form-label">
            idcsc
          </label>
          <InputText
            id="idcsc"
            name="idcsc"
            value={dados.idcsc}
            onChange={HandleChangeDados}
            className="form-control"
          />
        </div>

        <div className="col-md-6 mb-3">
          <label htmlFor="anyDesk" className="form-label">
            AnyDesk
          </label>
          <InputText
            id="anyDesk"
            name="anyDesk"
            value={dados.anyDesk}
            onChange={HandleChangeDados}
            className="form-control"
          />
        </div>

        <div className="col-md-12 mb-3">
          <div className="w-100 d-flex flex-row-reverse">
            <Button
              type="submit"
              label={EmpresaId === "" ? "Salvar" : "Atualizar"}
              className="btn btn-primary"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

FormEmpresa.propTypes = { EmpresaId: PropTypes.string };

FormEmpresa.defaultProps = {
  EmpresaId: ""
};

export default FormEmpresa;
