import { useState } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

const ModalAlocacao = ({
  FuncionarioId,
  ShowModalAlocado,
  setShowModalAlocado,
  ListaEmpresas,
  ListaFucao
}) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [SelectedEmpresa, setSelectedEmpresa] = useState(null);
  const [SelectedFuncao, setSelectedFuncao] = useState(null);
  const [FilterValue, setFilterValue] = useState("");
  const [Salario, setSalario] = useState("");
  const [Motivo, setMotivo] = useState("");

  const [ListaAlocacao, setListaAlocacao] = useState([]);

  // Função para filtrar as opções com base no valor do filtro
  const filtrarEmpresas = (option) => {
    try {
      const codigo = option.codigoLoja ?? "";
      return (
        option.nome.toLowerCase().includes(FilterValue.toLowerCase()) ||
        option.cnpj.replace(/\D/g, "").includes(FilterValue) ||
        codigo.toLowerCase().includes(FilterValue.toLowerCase())
      );
    } catch (error) {
      console.error(error);
      return option;
    }
  };

  const LimpraCamposAlocacao = () => {
    setSelectedEmpresa(null);
    setSelectedFuncao(null);
    setFilterValue("");
    setSalario("");
    setMotivo("");
  };

  const GetAlocacao = async () => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/Funcionarios/Alocado",
        params: { funcionarioId: FuncionarioId },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setListaAlocacao(
          data.objetoResposta.sort((a, b) => {
            // Pega a data de atualização ou data de criação, se a atualização for null
            const dataA = new Date(a.dataCriacao);
            const dataB = new Date(b.dataCriacao);
            return dataB - dataA;
          })
        );
      } else if (data.codigoEstadoHttp === 404) {
        notify({
          type: "info",
          message:
            "Nenhum histórico de alocação encontrado para este funcionário"
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const SalvarAlocacao = async (params) => {
    params.preventDefault();
    try {
      setLoading(false);
      const data = await Requicicao.Post({
        endpoint: "/Funcionarios/Alocado",
        data: {
          FuncionarioId,
          EmpresaId: SelectedEmpresa.id,
          FuncaoId: SelectedFuncao.id,
          Salario,
          Motivo,
          DataInicio: MaskUtil.applyDataUSMask(new Date()),
          Ativo: false,
          EContratado: false
        },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        notify({
          type: "ok",
          message: "Alocação Salva."
        });
        LimpraCamposAlocacao();
        GetAlocacao();
      } else if (data.codigoEstadoHttp === 404 && data.mensagemAdicional) {
        notify({
          type: "error",
          message: data.mensagemAdicional
        });
      }
    } catch (error) {
      console.error(error);
      notify({
        type: "error",
        message: "Erro ao salva o Alocação."
      });
    } finally {
      setLoading(false);
    }
  };

  const FilterTemplate = () => (
    <span className="p-input-icon-left" style={{ width: "100%" }}>
      <i className="pi pi-search" />
      <InputText
        type="text"
        value={FilterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        placeholder="Buscar empresa"
        style={{ width: "100%" }}
      />
    </span>
  );

  const countryOptionTemplate = (option) => (
    <div
      className="d-flex flex-column align-items-center"
      style={{ maxWidth: "calc(500px - (19*2px))" }}
    >
      <p className="m-0">Nome: {option.nome}</p>
      <p className="m-0">CNPJ: {option.cnpj}</p>
      {option.codigoLoja && (
        <p className="m-0">Codigo da loja: {option.codigoLoja}</p>
      )}
    </div>
  );

  return (
    <Dialog
      header="Alocado"
      visible={ShowModalAlocado}
      onHide={() => {
        setShowModalAlocado(false);
        LimpraCamposAlocacao();
        setListaAlocacao([]);
      }}
    >
      <form className="row g-3" onSubmit={SalvarAlocacao}>
        <div className="col-md-4">
          <label htmlFor="empresa" className="form-label">
            Empresa
          </label>
          <Dropdown
            id="empresa"
            className="w-100"
            value={SelectedEmpresa}
            onChange={(e) => setSelectedEmpresa(e.value)}
            options={ListaEmpresas.filter(filtrarEmpresas)}
            itemTemplate={countryOptionTemplate}
            optionLabel="nome"
            filter
            filterTemplate={FilterTemplate}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="funcao" className="form-label">
            Função
          </label>
          <Dropdown
            id="funcao"
            className="w-100"
            value={SelectedFuncao}
            onChange={(e) => setSelectedFuncao(e.value)}
            options={ListaFucao}
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="salario" className="form-label">
            Salário
          </label>
          <InputNumber
            id="salario"
            mode="currency"
            locale="pt-BR"
            currency="BRL"
            value={Salario}
            onChange={(e) => setSalario(e.value)}
            className="w-100"
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="motivo" className="form-label">
            Motivo
          </label>
          <InputText
            id="motivo"
            className="w-100"
            value={Motivo}
            onChange={(e) => setMotivo(e.target.value)}
          />
        </div>
        <div className="col-12">
          <div className="d-flex flex-row-reverse gap-3">
            <Button label="Salvar" className="btn btn-primary" />
            <Button
              className="btn"
              type="button"
              label="Carregar Histórico"
              onClick={GetAlocacao}
            />
          </div>
        </div>
      </form>
      {ListaAlocacao.length > 0 && (
        <div className="mt-3">
          <DataTable value={ListaAlocacao} stripedRows rows={3} paginator>
            <Column field="funcaoNome" header="Função" />
            <Column field="empresaCnpj" header="CNPJ da Empresa" />
            <Column field="motivo" header="Motivo" />
            <Column
              field="salario"
              header="Salário"
              body={(row) => MaskUtil.applyMonetaryMask(row.salario)}
            />
            <Column
              field="dataInicio"
              header="Data de Início"
              body={(row) => MaskUtil.applyDataMask(row.dataInicio)}
            />
            <Column
              field="dataFim"
              header="Data de Fim"
              body={(row) => MaskUtil.applyDataMask(row.dataFim)}
            />
          </DataTable>
        </div>
      )}
    </Dialog>
  );
};

ModalAlocacao.propTypes = {
  FuncionarioId: PropTypes.string.isRequired,
  ShowModalAlocado: PropTypes.bool.isRequired,
  setShowModalAlocado: PropTypes.func.isRequired,
  ListaEmpresas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      nome: PropTypes.string,
      cnpj: PropTypes.string,
      codigoLoja: PropTypes.string
    })
  ).isRequired,
  ListaFucao: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired
};

export default ModalAlocacao;
