import React from "react";

import PropTypes from "prop-types";

import CampoAbilitavel from "./CampoAbilitavel";

const BenefCios = ({ dados, HandleChange, BoxBtns }) => {
  const ConverteValeNumero = (valor) => {
    if (valor == null) {
      return null;
    }
    return Number(valor);
  };

  return (
    <div className="row g-3">
      <div className="col-md-4 col-lg-3 col-xl-2">
        <CampoAbilitavel
          labelSwitch="Tem Vale Alimentação?"
          labelInput="Valor do Vale Alimentação"
          value={dados.valeAlimentacao}
          onChange={(e) =>
            HandleChange({
              target: {
                name: "valeAlimentacao",
                value: ConverteValeNumero(e)
              }
            })
          }
        />
      </div>
      <div className="col-md-4 col-lg-3 col-xl-2">
        <CampoAbilitavel
          labelSwitch="Tem Vale Refeição?"
          labelInput="Valor do Vale Refeição"
          value={dados.valeRefeicao}
          onChange={(e) =>
            HandleChange({
              target: {
                name: "valeRefeicao",
                value: ConverteValeNumero(e)
              }
            })
          }
        />
      </div>
      <div className="col-md-4 col-lg-3 col-xl-2">
        <CampoAbilitavel
          labelSwitch="Tem Vale Transporte?"
          labelInput="Valor do Vale Transporte"
          value={dados.valeTransporte}
          onChange={(e) =>
            HandleChange({
              target: {
                name: "valeTransporte",
                value: ConverteValeNumero(e)
              }
            })
          }
        />
      </div>
      <div className="col-md-4 col-lg-3 col-xl-2">
        <CampoAbilitavel
          labelSwitch="Tem Plano de Saúde?"
          labelInput="Valor do Plano de Saúde"
          value={dados.planoSaude}
          onChange={(e) =>
            HandleChange({
              target: { name: "planoSaude", value: ConverteValeNumero(e) }
            })
          }
        />
      </div>
      <div className="col-md-4 col-lg-3 col-xl-2">
        <CampoAbilitavel
          labelSwitch="Tem Plano Odontológico?"
          labelInput="Valor do Plano Odontológico"
          value={dados.planoOdontologico}
          onChange={(e) =>
            HandleChange({
              target: {
                name: "planoOdontologico",
                value: ConverteValeNumero(e)
              }
            })
          }
        />
      </div>
      <div className="col-md-4 col-lg-3 col-xl-2">
        <CampoAbilitavel
          labelSwitch="Tem Seguro de Vida?"
          labelInput="Valor do Seguro de Vida"
          value={dados.seguroVida}
          onChange={(e) =>
            HandleChange({
              target: { name: "seguroVida", value: ConverteValeNumero(e) }
            })
          }
        />
      </div>
      <div className="col-md-4 col-lg-3 col-xl-2">
        <CampoAbilitavel
          labelSwitch="Tem Auxílio Creche?"
          labelInput="Valor do Auxílio-Creche"
          value={dados.auxilioCreche}
          onChange={(e) =>
            HandleChange({
              target: {
                name: "auxilioCreche",
                value: ConverteValeNumero(e)
              }
            })
          }
        />
      </div>
      <div className="col-md-4 col-lg-3 col-xl-2">
        <CampoAbilitavel
          labelSwitch="Possui Gympass/Academia?"
          labelInput="Valor do Gympass/Academia"
          value={dados.valeAcademia}
          onChange={(e) =>
            HandleChange({
              target: {
                name: "valeAcademia",
                value: ConverteValeNumero(e)
              }
            })
          }
        />
      </div>
      <div className="col-md-4 col-lg-3 col-xl-2">
        <CampoAbilitavel
          labelSwitch="Possui Auxílio Home Office?"
          labelInput="Valor do Auxílio Home Office"
          value={dados.auxilioHomeOffice}
          onChange={(e) =>
            HandleChange({
              target: {
                name: "auxilioHomeOffice",
                value: ConverteValeNumero(e)
              }
            })
          }
        />
      </div>
      <div className="col-12">
        <BoxBtns />
      </div>
    </div>
  );
};

BenefCios.propTypes = {
  dados: PropTypes.shape({
    auxilioHomeOffice: PropTypes.string,
    valeAcademia: PropTypes.string,
    auxilioCreche: PropTypes.string,
    seguroVida: PropTypes.string,
    planoOdontologico: PropTypes.string,
    planoSaude: PropTypes.string,
    valeTransporte: PropTypes.string,
    valeRefeicao: PropTypes.string,
    valeAlimentacao: PropTypes.string
  }).isRequired,
  HandleChange: PropTypes.func.isRequired,
  BoxBtns: PropTypes.func.isRequired
};

export default BenefCios;
