import { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import { Button } from "primereact/button";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import useQuery from "utils/useQuery";

import jsPDF from "jspdf";

const CartaProposta = () => {
  const notify = useNotification();
  const query = useQuery();
  const navigate = useNavigate();
  const auth = useAuth();
  const { setLoading } = useLoading();
  const Requicicao = new Api();

  const { TipoEscala } = MaskEnum;

  const [funcionario, setFuncionario] = useState(null);
  const [aceite, setAceite] = useState(false);
  const [funcionarioId, setfuncionarioId] = useState(null);

  const HandleAceite = async () => {
    try {
      setLoading(true);

      await Requicicao.Get({
        endpoint: "/Funcionarios/AssinaturaDocuSign",
        params: { funcionarioId }
      });

      notify({
        type: "success",
        message: "Termo aceito com sucesso!",
        life: 3000
      });

      navigate({
        pathname: "/boasVindas/finalizarCadastro",
        search: createSearchParams({
          id: funcionarioId
        }).toString()
      });
    } catch (error) {
      notify({
        type: "warn",
        message: "Você precisa aceitar o termo antes de continuar.",
        life: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const HandleBaixarCarta = () => {
    if (aceite) {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();

      // Define as margens e tamanhos de texto
      const margemEsquerda = 20;
      const margemTopo = 20;
      const linhaAltura = 10;
      doc.setFontSize(10); // Diminuído o tamanho da fonte

      // Pega os valores do funcionário
      const {
        nome,
        empresaContratante,
        enderecoEmpresaContratada,
        dataAdmissao,
        cargo,
        departamento,
        salario,
        horarioTrabalho
      } = funcionario;

      // Formata o salário
      const salarioFormatado = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(salario);

      // Texto da carta com quebras de linha
      const textoCarta = `
        Caro(a), ${nome}.
        Formalizamos por meio deste documento, a proposta de trabalho com vistas à sua admissão
        no(a) ${empresaContratante}, localizado(a) no(a) ${enderecoEmpresaContratada}.

        Data de Admissão: ${new Date(dataAdmissao).toLocaleDateString()}
        Cargo: ${cargo}
        Departamento: ${departamento}
        Salário: ${salarioFormatado} Por mês
        Horário de Trabalho: ${horarioTrabalho}

        Você estará conosco em um momento de grandes desafios e oportunidades.
        Estamos convencidos que suas habilidades serão muito bem-vindas e contribuirão com o sucesso de nossa Empresa.

        Atenciosamente,
        Recursos Humanos
      `;

      // Divide o texto em linhas para caber na largura do documento
      const linhasTexto = doc.splitTextToSize(textoCarta.trim(), 170);

      // Adiciona cada linha de texto ao PDF
      linhasTexto.forEach((linha, index) => {
        doc.text(linha, margemEsquerda, margemTopo + index * linhaAltura);
      });

      // Baixa o PDF
      doc.save("Carta_Proposta.pdf");
    } else {
      notify({
        type: "warn",
        message: "Você precisa aceitar o termo antes de baixar a carta.",
        life: 3000
      });
    }
  };

  useEffect(() => {
    setfuncionarioId(query.get("id"));
  }, [query]);

  useEffect(() => {
    const fetchFuncionario = async () => {
      try {
        if (funcionarioId != null) {
          const response = await Requicicao.Get({
            endpoint: `/Funcionarios/${funcionarioId}`,
            config: { headers: { Authorization: `Bearer ${auth.token}` } }
          });

          if (response.codigoEstadoHttp === 200) {
            setFuncionario(response.objetoResposta);
          } else {
            notify({
              type: "error",
              message:
                response.mensagemAdicional ||
                "Erro ao buscar informações do funcionário.",
              life: 3000
            });
          }
        }
      } catch (error) {
        console.error("Erro ao buscar informações do funcionário:", error);
        notify({
          type: "error",
          message:
            "Ocorreu um erro ao buscar as informações do funcionário. Por favor, tente novamente mais tarde.",
          life: 3000
        });
      }
    };

    fetchFuncionario();
  }, [funcionarioId, auth]);

  if (!funcionario) {
    return (
      <div
        style={{
          textAlign: "center",
          marginTop: "50px",
          fontFamily: "Arial, sans-serif"
        }}
      >
        <p>Carregando...</p>
      </div>
    );
  }

  return (
    <div className="box-bem-vindo">
      <div
        className="card p-3 pe-5 ps-5 mt-3 mb-3"
        style={{ width: "min-content", minWidth: "700px", maxWidth: "100%" }}
      >
        <div className="d-flex justify-content-center ">
          <h1 className="text-center" style={{ color: "#4CAF50" }}>
            Bem-vindo, {funcionario.nome}!
          </h1>
        </div>
        <div
          style={{
            margin: "20px auto",
            minWidth: "75%",
            backgroundColor: "#f9f9f9",
            padding: "20px",
            borderRadius: "5px",
            border: "1px solid #ddd"
          }}
        >
          <p>Caro(a), {funcionario.nome}.</p>
          <p>
            Formalizamos por meio deste documento, a proposta de trabalho com
            vistas à sua admissão
          </p>
          <p>
            no(a) {funcionario.empresaContratante}, localizado(a) no(a){" "}
            {funcionario.enderecoEmpresaContratada}.
          </p>
          <p>
            Data de Admissão:{" "}
            {new Date(funcionario.dataAdmissao).toLocaleDateString()}
          </p>
          <p>Cargo: {funcionario.cargo}</p>

          <p>
            Salário:{" "}
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL"
            }).format(funcionario.salario)}{" "}
            Por mês
          </p>
          <p>
            Escala:{" "}
            {TipoEscala.find((x) => x.value === funcionario.tipoEscala)?.label}
          </p>
          <p>Horário de Trabalho: {funcionario.horarioTrabalho}</p>
          <br />
          <p>
            Você estará conosco em um momento de grandes desafios e
            oportunidades.
          </p>
          <p>
            Estamos convencidos que suas habilidades serão muito bem-vindas e
            contribuirão com o sucesso de nossa Empresa.
          </p>
          <br />
          <p>Atenciosamente,</p>
          <p>Recursos Humanos</p>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div style={{ margin: "20px" }}>
            <input
              type="checkbox"
              id="aceite"
              checked={aceite}
              onChange={() => setAceite(!aceite)}
              style={{ marginRight: "10px" }}
            />
            <label htmlFor="aceite">
              Eu aceito os termos da carta proposta.
            </label>
          </div>

          <div className="">
            <Button
              label="Baixar Carta Proposta"
              icon="pi pi-download"
              onClick={HandleBaixarCarta}
              style={{ marginRight: "10px" }}
              disabled={!aceite}
            />
            <Button
              label="Continuar"
              icon="pi pi-check"
              onClick={HandleAceite}
              disabled={!aceite}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartaProposta;
