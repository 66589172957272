import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

const EscalaFuncionario = ({ id }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const notify = useNotification();
  const Requicicao = new Api();

  const [mesAno, setMesAno] = useState(null);
  const [tipoEscala, setTipoEscala] = useState(null);
  const [diasTrabalho, setDiasTrabalho] = useState([]);
  const [horarioInicio, setHorarioInicio] = useState("");
  const [horarioFim, setHorarioFim] = useState("");

  const [Lista, setLista] = useState([]);

  const tipoEscalaOptions = [
    { label: "5x2", value: "5x2" },
    { label: "6x1", value: "6x1" }
  ];

  const diasSemanaOptions = [
    { label: "Domingo", value: "Domingo" },
    { label: "Segunda", value: "Segunda" },
    { label: "Terça", value: "Terça" },
    { label: "Quarta", value: "Quarta" },
    { label: "Quinta", value: "Quinta" },
    { label: "Sexta", value: "Sexta" },
    { label: "Sábado", value: "Sábado" }
  ];

  const MaxDiasTrabalho = () => {
    let maxDiasTrabalho = 0;
    switch (tipoEscala) {
      case "5x2":
        maxDiasTrabalho = 5;
        break;
      case "6x1":
        maxDiasTrabalho = 6;
        break;
      default:
        maxDiasTrabalho = 0;
    }
    return maxDiasTrabalho;
  };

  const HandleDiasTrabalhoChange = (e) => {
    if (e.value.length <= MaxDiasTrabalho()) {
      setDiasTrabalho(e.value);
    } else {
      notify({
        type: "aviso",
        message: `A escala ${tipoEscala} permite no máximo ${MaxDiasTrabalho()} dias de trabalho.`
      });
    }
  };

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/Funcionarios/Escala",
        params: { id },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setLista(data.objetoResposta);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const LimpraCampos = () => {
    setMesAno(null);
    setTipoEscala(null);
    setDiasTrabalho([]);
    setHorarioInicio("");
    setHorarioFim("");
  };

  const HandleSalvarEscala = async () => {
    try {
      setLoading(true);
      const payload = {
        mesAno,
        tipoEscala,
        diasTrabalho: diasTrabalho.toString(),
        funcionarioId: id,
        horarioInicio,
        horarioFim
      };

      const data = await Requicicao.Post({
        endpoint: "/Funcionarios/Escala",
        data: payload,
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        notify({
          type: "success",
          message: "Escala Salva."
        });

        StateDataPage();
        LimpraCampos();
      } else {
        notify({
          type: "Erro",
          message: "Erro ao atribir a nova escala."
        });
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        if (error.response?.data?.title != null)
          notify({
            type: "Erro",
            message: error.response.data.title
          });
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Limpar dias de trabalho quando o tipo de escala mudar
    setDiasTrabalho([]);
  }, [tipoEscala]);

  useEffect(() => {
    if (id != null) StateDataPage();
  }, [id]);

  return (
    <div className="row g-3">
      <div className="col-12 col-md-4">
        <label htmlFor="mesAno">Mês e Ano</label>
        <Calendar
          id="mesAno"
          value={mesAno}
          onChange={(e) => setMesAno(e.value)}
          view="month"
          dateFormat="mm/yy"
          showButtonBar
          placeholder="Escolha Mês e Ano"
          className="w-100"
        />
      </div>

      <div className="col-12 col-md-4">
        <label htmlFor="tipoEscala">Tipo de Escala</label>
        <Dropdown
          id="tipoEscala"
          value={tipoEscala}
          options={tipoEscalaOptions}
          onChange={(e) => setTipoEscala(e.value)}
          placeholder="Selecione o Tipo de Escala"
          className="w-100"
        />
      </div>

      <div className="col-12 col-md-4">
        <label htmlFor="diasTrabalho">Dias de Trabalho</label>
        <MultiSelect
          id="diasTrabalho"
          value={diasTrabalho}
          options={diasSemanaOptions}
          onChange={HandleDiasTrabalhoChange}
          placeholder="Selecione os Dias de Trabalho"
          className="w-100"
          disabled={!tipoEscala}
        />
      </div>

      <div className="col-md-4">
        <label htmlFor="horario-inicio" className="form-label">
          Horário de início
        </label>
        <InputText
          id="horario-inicio"
          name="horario-inicio"
          value={horarioInicio}
          onChange={(e) => setHorarioInicio(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="col-md-4">
        <label htmlFor="horario-fim" className="form-label">
          Horário de Fim
        </label>
        <InputText
          id="horario-fim"
          name="horario-fim"
          value={horarioFim}
          onChange={(e) => setHorarioFim(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="col-12">
        <div className="d-flex justify-content-end">
          <Button
            label="Salvar Escala"
            className="btn btn-primary"
            onClick={HandleSalvarEscala}
          />
        </div>
      </div>

      <div className="col-12">
        <DataTable value={Lista} stripedRows rows={20}>
          <Column
            field="mesAno"
            header="Mês e Ano"
            body={(row) => MaskUtil.applyMesAnoMask(row.mesAno)}
          />
          <Column field="tipoEscala" header="Tipo de Escala" />
          <Column field="horarioInicio" header="Horário de Início" />
          <Column field="horarioFim" header="Horário de Fim" />
          <Column
            field="diasTrabalho"
            header="Dias de Trabalho"
            body={(row) => row.diasTrabalho.replaceAll(",", ", ")}
          />
          <Column
            field="dataCriacao"
            header="Data de Criação"
            body={(row) => MaskUtil.applyDataAndHoraMask(row.dataCriacao)}
          />
        </DataTable>
      </div>
    </div>
  );
};

EscalaFuncionario.propTypes = {
  id: PropTypes.string.isRequired
};

export default EscalaFuncionario;
