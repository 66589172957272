import { useState, useRef } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

import { useAuth } from "context/AuthContext";
import { useSystemVersion } from "context/SystemVersionContext";

import Api from "utils/Api";
import useQuery from "utils/useQuery";

import BG from "assets/img/akkadian_fundo.e164cd6d.jpg";
import Logo from "assets/img/logo/black.e9eb3087.png";

import "./styled.css";
import "styles/Form.css";

const Login = () => {
  const query = useQuery();
  const toastRef = useRef(null);
  const auth = useAuth();
  const Requicicao = new Api();
  const navigate = useNavigate();
  const systemVersion = useSystemVersion();

  const [Email, setEmail] = useState("");
  const [Senha, setSenha] = useState("");
  const [Load, setLoad] = useState(false);

  const GoLogin = async (event) => {
    event.preventDefault();
    try {
      setLoad(true);
      const resposta = await Requicicao.Post({
        endpoint: "/Conta/Autenticar",
        data: {
          usuario: Email,
          senha: Senha,
          origem: 2 // PDV Nuvem
        }
      });
      if (resposta.codigoEstadoHttp === 200) {
        const info = query.get("info");
        auth.login(resposta.objetoResposta);
        if (info != null) {
          navigate({
            pathname: "/app/info-user",
            search: createSearchParams({
              id: info
            }).toString()
          });
        } else {
          navigate("/app");
        }
      } else if (resposta.codigoEstadoHttp === 400) {
        toastRef.current.show({
          severity: "error",
          summary: "error",
          detail: resposta.mensagemAdicional,
          life: 5000
        });
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 400 || error?.response?.status === 401) {
        if (error.response.data.mensagemAdicional) {
          toastRef.current.show({
            severity: "error",
            summary: "error",
            detail: error.response.data.mensagemAdicional,
            life: 5000
          });
        }
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "error",
          detail: "Servidor não responde",
          life: 5000
        });
      }
    } finally {
      setLoad(false);
    }
  };

  return (
    <div className="login-box-main">
      <Toast ref={toastRef} />
      <div className="login-box-img-bg">
        <img src={BG} alt="bg" />
      </div>
      <div className="logim-card">
        <div className="logim-img-box-card">
          <img src={Logo} alt="logo" />
        </div>
        <form onSubmit={GoLogin} className="logim-form">
          <div className="logim-form-box-text">
            <p>Autenticação</p>
          </div>
          <div className="form-group">
            <label htmlFor="email" className="label-input">
              E-mail
            </label>
            <InputText
              id="email"
              name="email"
              type="text"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="senha" className="label-input">
              Senha
            </label>
            <InputText
              id="senha"
              name="senha"
              type="password"
              value={Senha}
              onChange={(e) => setSenha(e.target.value)}
              required
            />
          </div>
          <div className="logim-form-box-btn">
            <a href="/#">Esqueceu a senha?</a>
            <Button
              label="Autenticar"
              className="btn-primary btn-big"
              loading={Load}
            />
          </div>
          <div className="version-info">
            <small>Versão do Sistema: {systemVersion}</small>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
