import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import MaskUtil from "utils/MaskUtil";

const Status = ({ id }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const { MotivoDesligamentoOptions, AvisoPrevioOptions, StatusFuncionario } =
    MaskEnum;

  const [Dados, setDados] = useState({});
  const [TipoAfastamento, setTipoAfastamento] = useState("");

  const [Lista, setLista] = useState([]);
  const [Arquivo, setArquivo] = useState(null);

  const [ShowForm, setShowForm] = useState(false);

  const HandleDropdownChange = (e, name) => {
    setDados((prevState) => ({
      ...prevState,
      [name]: e.value
    }));
  };

  const HandleInputChange = (e) => {
    const { name, value } = e.target;
    setDados((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const HandleFileSelect = (e) => {
    setArquivo(e.files[0]); // Captura o primeiro arquivo selecionado
  };

  const SubirArquivo = async (statusFuncionarioId) => {
    try {
      setLoading(true);

      const formData = new FormData();

      const dataToForm = {
        funcionarioId: id,
        nomeArquivo: `${id}_${MaskUtil.applyDataAndHoraMask(new Date())}`,
        descricao: StatusFuncionario.find((x) => x.value === 4).label,
        tipoArquivo: 3,
        statusFuncionarioId
      };

      // Adiciona os dados do funcionário ao FormData
      for (const key in dataToForm) {
        if (Object.prototype.hasOwnProperty.call(dataToForm, key)) {
          formData.append(key, dataToForm[key]);
        }
      }

      // Adiciona o arquivo ao FormData
      if (Arquivo) {
        formData.append("arquivoFuncionario", Arquivo); // 'arquivo' deve ser o nome esperado pelo servidor

        await Requicicao.Put({
          endpoint: `/Funcionarios/AdicionarArquivosFuncionario`,
          data: formData,
          config: auth.GetHeaders("multipart/form-data")
        });

        notify({
          type: "sucesso",
          message: "Documento Salvo"
        });
      } else {
        notify({
          type: "aviso",
          message: "Por favor, selecione um arquivo para fazer upload."
        });
      }
    } catch (error) {
      console.error(error);
      notify({
        type: "erro",
        message: "Erro ao salva documento."
      });
    } finally {
      setLoading(false);
    }
  };

  const GetHistorico = async () => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/Funcionarios/Status",
        params: { id },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setLista(
          data.objetoResposta.sort((a, b) => {
            // Pega a data de atualização ou data de criação, se a atualização for null
            const dataA = new Date(a.dataAtualizacao || a.dataCriacao);
            const dataB = new Date(b.dataAtualizacao || b.dataCriacao);
            return dataB - dataA;
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const Submit = async (params, payload) => {
    params.preventDefault();
    try {
      setLoading(true);

      const Payload = Dados;
      Payload.status = TipoAfastamento;
      if (Dados.dataInicio)
        Payload.dataInicio = Dados.dataInicio
          ? MaskUtil.applyDataUSMask(Dados.dataInicio)
          : null;
      if (Dados.dataFim)
        Payload.dataFim = Dados.dataFim
          ? MaskUtil.applyDataUSMask(Dados.dataFim)
          : null;

      const data = await Requicicao.Post({
        endpoint: "/Funcionarios/Status",
        data: payload ?? Payload,
        config: auth.GetHeaders()
      });

      if (data.codigoEstadoHttp === 200) {
        notify({
          type: "sucesso",
          message: "Status Salvo"
        });
        setDados({ funcionarioId: id });
        setTipoAfastamento("");
        if (Dados.status === 4) await SubirArquivo(data.objetoResposta.id);
        setShowForm(false);
        await GetHistorico();
      } else if (data.mensagemAdicional) {
        notify({
          type: "erro",
          message: data.mensagemAdicional
        });
      }
    } catch (error) {
      console.error(error);
      notify({
        type: "erro",
        message: "Erro ao salvo"
      });
    } finally {
      setLoading(false);
    }
  };

  const Ativar = async (e) => {
    const payload = {
      funcionarioId: id,
      status: 1,
      dataInicio: new Date()
    };
    await Submit(e, payload);
  };

  const Desativar = async (e) => {
    const payload = {
      funcionarioId: id,
      status: 2,
      dataInicio: new Date()
    };
    await Submit(e, payload);
  };

  const RemoverStatus = async (statusId) => {
    try {
      setLoading(true);
      await Requicicao.Delete({
        endpoint: "/Funcionarios/Status",
        params: { id: statusId },
        config: auth.GetHeaders()
      });
      await GetHistorico();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const ConfirmacaoDesligar = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Tem certeza que quero desativar esse funcionário?",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: Desativar,
      reject: () => {}
    });
  };

  const ConfirmacaoAtivar = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Tem certeza que quero ativo esse funcionário agora?",
      icon: "pi pi-info-circle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: Ativar,
      reject: () => {}
    });
  };

  useEffect(() => {
    setDados((e) => ({ ...e, funcionarioId: id }));
  }, [id]);

  return (
    <div>
      <ConfirmPopup />
      {ShowForm ? (
        <form className="row g-3" onSubmit={Submit}>
          <div className="col-md-4">
            <label htmlFor="tipoAfastamento" className="form-label">
              Tipo de Afastamento
            </label>
            <Dropdown
              id="tipoAfastamento"
              value={TipoAfastamento}
              onChange={(e) => setTipoAfastamento(e.value)}
              options={StatusFuncionario.filter(
                (x) => x.value !== 1 && x.value !== 2 && x.value !== 0
              )}
              className="w-100"
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="data-inicio" className="form-label">
              Data de Início
            </label>
            <Calendar
              id="data-inicio"
              dateFormat="dd/mm/yy"
              value={Dados.dataInicio}
              onChange={(e) =>
                HandleInputChange({
                  target: {
                    name: "dataInicio",
                    value: e.value
                  }
                })
              }
              className="w-100"
              disabled={TipoAfastamento === ""}
            />
          </div>

          {TipoAfastamento && (
            <>
              {TipoAfastamento !== "desligamento" && (
                <div className="col-md-4">
                  <label htmlFor="data-termino" className="form-label">
                    Data de Término
                  </label>
                  <Calendar
                    id="data-termino"
                    dateFormat="dd/mm/yy"
                    value={Dados.dataFim}
                    onChange={(e) =>
                      HandleInputChange({
                        target: {
                          name: "dataFim",
                          value: e.value
                        }
                      })
                    }
                    className="w-100"
                  />
                </div>
              )}

              {TipoAfastamento === 4 && (
                <>
                  {/* <div className="col-md-4">
                  <label htmlFor="atestado" className="form-label">
                    Atestado Médico de Afastamento
                  </label>
                  <InputText
                    id="atestado"
                    name="atestado"
                    value={Dados.atestado}
                    onChange={HandleInputChange}
                    className="w-100"
                  />
                </div> */}
                  <div className="col-md-4">
                    <label htmlFor="nomeMedico" className="form-label">
                      Nome do Médico
                    </label>
                    <InputText
                      id="nomeMedico"
                      name="nomeMedico"
                      value={Dados.nomeMedico}
                      onChange={HandleInputChange}
                      className="w-100"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="crm" className="form-label">
                      CRM
                    </label>
                    <InputMask
                      id="crm"
                      name="crm"
                      value={Dados.crm}
                      onChange={HandleInputChange}
                      className="form-control"
                      mask="99999999-9/BR"
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="dadosBancarios" className="form-label">
                      Atestado Médico de Afastamento
                    </label>
                    <FileUpload
                      chooseLabel="Escolher"
                      uploadLabel="Enviar"
                      cancelLabel="Cancelar"
                      accept="application/pdf"
                      emptyTemplate={
                        <p className="m-0">
                          Arraste e solte os arquivos aqui para fazer upload.
                        </p>
                      }
                      customUpload
                      onSelect={HandleFileSelect} // Captura o evento de seleção de arquivo
                    />
                  </div>
                </>
              )}

              {TipoAfastamento === 3 && (
                <>
                  <div className="col-md-4">
                    <label
                      htmlFor="motivos-desligamento"
                      className="form-label"
                    >
                      Motivo de Desligamento
                    </label>
                    <Dropdown
                      id="motivos-desligamento"
                      value={Dados.motivosDesligamento}
                      onChange={(e) =>
                        HandleDropdownChange(e, "motivosDesligamento")
                      }
                      options={MotivoDesligamentoOptions}
                      className="w-100"
                      optionLabel="label"
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="tipo-aviso-previo" className="form-label">
                      Tipo de Aviso Prévio
                    </label>
                    <Dropdown
                      id="tipo-aviso-previo"
                      value={Dados.tipoAvisoPrevio}
                      onChange={(e) =>
                        HandleDropdownChange(e, "tipoAvisoPrevio")
                      }
                      options={AvisoPrevioOptions}
                      className="w-100"
                    />
                  </div>
                </>
              )}

              <div className="col-md-12">
                <label htmlFor="descricao" className="form-label">
                  Anotação
                </label>
                <InputTextarea
                  id="descricao"
                  name="descricao"
                  value={Dados.descricao}
                  onChange={HandleInputChange}
                  className="w-100"
                />
              </div>
            </>
          )}

          <div className="col-md-12">
            <div className="d-flex flex-row-reverse gap-3">
              <Button
                className="btn btn-primary"
                type="submit"
                label="Atualizar"
              />
              <Button
                type="button"
                label="Cancelar"
                className="btn"
                onClick={() => setShowForm(false)}
              />
            </div>
          </div>
        </form>
      ) : (
        <div className="d-flex flex-row-reverse gap-3">
          <Button
            className="btn btn-primary"
            type="submit"
            label="Adicionar Afastamento"
            icon="pi pi-bookmark"
            onClick={() => setShowForm(true)}
          />
          <Button
            type="button"
            label="Desligar"
            severity="danger"
            icon="pi pi-user-minus"
            tooltip="Desliga o funcionário agora"
            tooltipOptions={{ position: "bottom" }}
            onClick={ConfirmacaoDesligar}
          />
          <Button
            type="button"
            label="Voltou a tarabalhar"
            severity="success"
            icon="pi pi-refresh"
            tooltip="Ativa o funcionário agora"
            tooltipOptions={{ position: "bottom" }}
            onClick={ConfirmacaoAtivar}
          />
          <Button
            type="button"
            className="btn"
            label="Carregar Históricos"
            icon="pi pi-list"
            onClick={GetHistorico}
          />
        </div>
      )}

      {Lista.length > 0 && (
        <div className="mt-3">
          <DataTable value={Lista} stripedRows paginator rows={10}>
            <Column
              field="status"
              header="Status"
              body={(row) =>
                StatusFuncionario.find((x) => x.value === row.status)?.label ??
                "N/A"
              }
            />
            <Column field="descricao" header="Descrição" />
            <Column
              field="dataInicio"
              header="Data de Início"
              body={(row) => MaskUtil.applyDataMask(row.dataInicio)}
            />
            <Column
              field="dataFim"
              header="Data de Término"
              body={(row) => MaskUtil.applyDataMask(row.dataFim)}
            />
            <Column
              field="dataCriacao"
              header="Data de Criação"
              body={(row) => MaskUtil.applyDataAndHoraMask(row.dataCriacao)}
            />
            <Column
              header="Ação"
              body={(row) => (
                <Button
                  type="button"
                  label="Remover"
                  className="btn btn-tabela"
                  icon="pi pi-trash"
                  onClick={() => RemoverStatus(row.id)}
                />
              )}
            />
          </DataTable>
        </div>
      )}
    </div>
  );
};

Status.propTypes = {
  id: PropTypes.string.isRequired
};

export default Status;
