import React from "react";

import PropTypes from "prop-types";

import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";

import MaskUtil from "utils/MaskUtil";

const DadosColaborrador = ({
  dados,
  setDados,
  HandleChange,
  Cep,
  setCep,
  SetDadosEndereco,
  NumeroEndereco,
  setNumeroEndereco
}) => (
  <div className="row g-3">
    <div className="col-md-6 col-lg-3">
      <label htmlFor="nome" className="form-label">
        Nome Completo
      </label>
      <InputText
        id="nome"
        name="nome"
        value={dados.nome}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="cpf" className="form-label">
        CPF
      </label>
      <InputText
        id="cpf"
        name="cpf"
        value={dados.cpf}
        onChange={(e) =>
          setDados({
            ...dados,
            cpf: MaskUtil.applyCpfMask(e.target.value)
          })
        }
        className="form-control"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="rg" className="form-label">
        RG
      </label>
      <InputText
        id="rg"
        name="rg"
        value={dados.rg}
        onChange={(e) =>
          setDados({
            ...dados,
            rg: MaskUtil.applyRgMask(e.target.value)
          })
        }
        className="form-control"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="cep" className="form-label">
        CEP
      </label>
      <InputMask
        id="cep"
        name="cep"
        value={Cep}
        onChange={(e) => {
          const { value } = e.target;
          setCep(value);
        }}
        onBlur={(e) => {
          const { value } = e.target;
          if (value.replace(" ", "").replace("_", "").length === 9) {
            SetDadosEndereco(value.replace("-", ""));
          }
        }}
        className="form-control"
        mask="99999-999"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="endereco" className="form-label">
        Endereço
      </label>
      <InputText
        id="endereco"
        name="endereco"
        value={dados.endereco}
        onChange={HandleChange}
        className="form-control"
        disabled
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="numero-endereco" className="form-label">
        Número do Endereço
      </label>
      <InputText
        id="numero-endereco"
        name="numero-endereco"
        value={NumeroEndereco}
        onChange={(e) => setNumeroEndereco(e.target.value)}
        className="form-control"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="email" className="form-label">
        Email
      </label>
      <InputText
        id="email"
        name="email"
        value={dados.email}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="telefone" className="form-label">
        Telefone
      </label>
      <InputText
        id="telefone"
        name="telefone"
        value={dados.telefone}
        onChange={(e) =>
          setDados({
            ...dados,
            telefone: MaskUtil.applyTelefoneMask(e.target.value)
          })
        }
        className="form-control"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="banco" className="form-label">
        Banco
      </label>
      <InputMask
        id="banco"
        name="banco"
        value={dados.banco}
        onChange={HandleChange}
        className="form-control"
        mask="999"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="agencia" className="form-label">
        Agência
      </label>
      <InputMask
        id="agencia"
        name="agencia"
        value={dados.agencia}
        onChange={HandleChange}
        className="form-control"
        mask="9999"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="contaCorrente" className="form-label">
        Número da Conta Corrente
      </label>
      <InputMask
        id="contaCorrente"
        name="contaCorrente"
        value={dados.contaCorrente}
        onChange={HandleChange}
        className="form-control"
        mask="9999999-9"
      />
    </div>
  </div>
);

DadosColaborrador.propTypes = {
  dados: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
    rg: PropTypes.string.isRequired,
    endereco: PropTypes.string.isRequired,
    telefone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    banco: PropTypes.string.isRequired,
    agencia: PropTypes.string.isRequired,
    contaCorrente: PropTypes.string.isRequired
  }).isRequired,
  HandleChange: PropTypes.func.isRequired,
  setDados: PropTypes.func.isRequired,
  setCep: PropTypes.func.isRequired,
  Cep: PropTypes.string.isRequired,
  SetDadosEndereco: PropTypes.func.isRequired,
  NumeroEndereco: PropTypes.string.isRequired,
  setNumeroEndereco: PropTypes.func.isRequired
};

export default DadosColaborrador;
