import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";

import PDFPreview from "components/PDFPreview";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";
import useQuery from "utils/useQuery";

const ListaDocumentosFuncionario = () => {
  const notify = useNotification();
  const query = useQuery();
  const navigate = useNavigate();
  const auth = useAuth();
  const { setLoading } = useLoading();
  const Requicicao = new Api();

  const [Lista, setLista] = useState([]);

  const [ContaId, setContaId] = useState();
  const [FuncionarioId, setFuncionarioId] = useState(null);

  const [Dados, setDados] = useState({
    funcionarioId: null,
    nomeArquivo: "",
    ano: new Date().getFullYear(),
    mes: new Date().getMonth(),
    descricao: "",
    tipoArquivo: null
  });

  const [arquivo, setArquivo] = useState(null);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);

  const HandleFileSelect = (e) => {
    setArquivo(e.files[0]); // Captura o primeiro arquivo selecionado
  };

  const HandleInputChange = (e) => {
    const { name, value } = e.target;
    setDados((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const HandleDropdownChange = (e) => {
    setDados((prevState) => ({
      ...prevState,
      tipoArquivo: e.value
    }));
  };

  const HandleDateChange = (e) => {
    const selectedDate = e.value;
    setDados((prevState) => ({
      ...prevState,
      ano: selectedDate.getFullYear(),
      mes: selectedDate.getMonth() + 1
    }));
  };

  const ValidaCampos = () => {
    const mensagensErro = [];

    if (Dados.funcionarioId === null) {
      mensagensErro.push("Funcionário não identificado");
    }
    if (Dados.nomeArquivo === "") {
      mensagensErro.push("Informe o nome do documento");
    }
    if (Dados.descricao === "") {
      mensagensErro.push("Informe a descrição do documento");
    }
    if (Dados.tipoArquivo === null) {
      mensagensErro.push("Informe o tipo do documento");
    }

    if (mensagensErro.length > 0) {
      mensagensErro.forEach((msg) =>
        notify({
          type: "aviso",
          message: msg.toString().replace(",", ", ")
        })
      );
      return false;
    }

    return true;
  };

  const Submit = async (params) => {
    params.preventDefault();
    try {
      setLoading(true);

      if (ValidaCampos()) {
        const formData = new FormData();

        const dataToForm = Dados;

        // Adiciona os dados do funcionário ao FormData
        for (const key in dataToForm) {
          if (Object.prototype.hasOwnProperty.call(Dados, key)) {
            formData.append(key, Dados[key]);
          }
        }

        // Adiciona o arquivo ao FormData
        if (arquivo) {
          formData.append("arquivoFuncionario", arquivo); // 'arquivo' deve ser o nome esperado pelo servidor
        } else {
          notify({
            type: "aviso",
            message: "Por favor, selecione um arquivo para fazer upload."
          });
          return false;
        }

        await Requicicao.Put({
          endpoint: `/Funcionarios/AdicionarArquivosFuncionario`,
          data: formData,
          config: auth.GetHeaders("multipart/form-data")
        });

        notify({
          type: "sucesso",
          message: "Documento Salvo"
        });
        navigate(-1);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      notify({
        type: "erro",
        message: "Erro ao salva documento."
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const OpenImage = async (nomeArquivo, tipo, acao) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Funcionarios/${FuncionarioId}/${tipo}`,
        params: { nomeArquivo },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob"
        }
      });
      if (acao === "show") {
        const blob = new Blob([response], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob); // Cria um URL temporário para o Blob
        setPdfFile(blobUrl); // Define o estado pdfFile com o URL do Blob
        setDialogVisible(true);
      } else {
        const blob = new Blob([response], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nomeArquivo);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/Funcionarios/ArquivosFuncioario",
        params: { funcioarioId: Dados.funcionarioId },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setFuncionarioId(data.objetoResposta.id);
        setLista(data.objetoResposta.arquivosFuncioario);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const RemoveDoc = async (id) => {
    try {
      setLoading(true);
      const data = await Requicicao.Delete({
        endpoint: "/Funcionarios/RemoverDocumento",
        params: { id },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) await StateDataPage();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const ConfirmRemove = (id) => {
    confirmDialog({
      message: "Você tem certeza de que deseja remover?",
      header: "Confirmação de Remoção",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      accept: () => RemoveDoc(id), // Executa a remoção se o usuário aceitar
      reject: () => {} // Opcional, para lidar com a rejeição
    });
  };

  useEffect(() => {
    if (auth.isAuthenticated() && ContaId != null) {
      StateDataPage();
    }
  }, [auth, ContaId]);

  useEffect(() => {
    const id = query.get("id");
    const contaId = query.get("contaId");
    if (id != null) {
      setDados((e) => ({ ...e, funcionarioId: id }));
    }
    if (contaId != null) {
      setContaId(contaId);
    }
  }, [query]);

  return (
    <div className="card p-3">
      <ConfirmDialog />
      <form className="row g-3" onSubmit={Submit}>
        <div className="col-md-4">
          <label htmlFor="tipo-documento" className="form-label">
            Tipo de Documento
          </label>
          <Dropdown
            id="tipo-documento"
            value={Dados.tipoArquivo}
            onChange={HandleDropdownChange}
            options={[
              { label: "Holerite", value: 1 },
              { label: "InformeRendimento", value: 2 }
            ]}
            placeholder="Selecione o Tipo de Documento"
            className="w-100"
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="nomeArquivo" className="form-label">
            Nome do arquivo
          </label>
          <InputText
            id="nomeArquivo"
            name="nomeArquivo"
            className="w-100"
            value={Dados.nomeArquivo}
            onChange={HandleInputChange}
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="data" className="form-label">
            Mês/Ano
          </label>
          <Calendar
            id="data"
            view="month"
            dateFormat="mm/yy"
            value={new Date(Dados.ano, Dados.mes)}
            onChange={HandleDateChange}
            className="w-100"
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="descricao" className="form-label">
            Descrição
          </label>
          <InputText
            id="descricao"
            name="descricao"
            className="w-100"
            value={Dados.descricao}
            onChange={HandleInputChange}
          />
        </div>

        <div className="col-md-4">
          <FileUpload
            chooseLabel="Escolher"
            uploadLabel="Enviar"
            cancelLabel="Cancelar"
            accept="application/pdf"
            emptyTemplate={
              <p className="m-0">
                Arraste e solte os arquivos aqui para fazer upload.
              </p>
            }
            customUpload
            onSelect={HandleFileSelect} // Captura o evento de seleção de arquivo
          />
        </div>

        <div className="col-md-12">
          <div className="d-flex flex-row-reverse">
            <Button type="submit" className="btn btn-primary" label="Enviar" />
          </div>
        </div>
      </form>
      <hr />
      <div className="mt-3">
        <h2 className="fs-3">Documentos</h2>
        <DataTable value={Lista}>
          <Column field="nomeArquivo" header="Nome do arquivo" />
          <Column field="descricao" header="Descrição" />
          <Column field="ano" header="Ano" />
          <Column field="mes" header="Mes" />
          <Column
            field="dataCriacao"
            header="Data de criação"
            body={(row) => MaskUtil.applyDataAndHoraMask(row.dataCriacao)}
          />
          <Column
            header="Ação"
            body={(row) => (
              <div className="d-flex gap-3">
                <Button
                  label="Visualizador"
                  icon="pi pi-eye"
                  onClick={() =>
                    OpenImage(row.nomeArquivo, "DownloadDoc", "show")
                  }
                />
                <Button
                  label="Baixar"
                  icon="pi pi-download"
                  onClick={() => OpenImage(row.nomeArquivo, "DownloadDoc")}
                />
                <Button
                  label="Remover"
                  severity="danger"
                  icon="pi pi-file-excel"
                  onClick={() => ConfirmRemove(row.id)}
                  disabled={row.nomeArquivo === "ExameMedico"}
                />
              </div>
            )}
          />
        </DataTable>
      </div>
      <Dialog visible={dialogVisible} onHide={() => setDialogVisible(false)}>
        <PDFPreview file={pdfFile} />
      </Dialog>
    </div>
  );
};

export default ListaDocumentosFuncionario;
