import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

const FormVendedor = ({ VendedorId }) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();
  const navigate = useNavigate();

  const [Data, setData] = useState({
    nome: "",
    apelido: "",
    cpf: "",
    dataAdmissao: "",
    porcentagemAoVender: 0,
    porcentagemAoReceber: 0,
    porcentagemImpostoRendaRetidoFonteIrrf: 0,
    empresasIds: []
  });

  const [ListaFiliais, setListaFiliais] = useState([]);
  const [cale, setcale] = useState(null);

  const HandleChangeData = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "cpf":
        setData((aqui) => ({ ...aqui, [name]: MaskUtil.applyCpfMask(value) }));
        break;
      case "dataAdmissao":
        const valorOriginal = new Date(value);
        const ano = valorOriginal.getFullYear();
        const mes = valorOriginal.getMonth() + 1;
        const dia = valorOriginal.getDate();
        setData((aqui) => ({
          ...aqui,
          [name]: `${ano}-${mes.toString().padStart(2, "0")}-${dia
            .toString()
            .padStart(2, "0")}`
        }));
        break;
      default:
        setData((aqui) => ({ ...aqui, [name]: value }));
        break;
    }
  };

  const CriarVendedor = async () => {
    try {
      await Requicicao.Post({
        endpoint: "/Vendedor",
        data: Data,
        config: auth.GetHeaders()
      });
      notify({
        type: "sucesso",
        message: "Criado com sucesso"
      });
      navigate("/app/vendedor");
    } catch (error) {
      notify({
        type: "erro",
        message:
          "Erro ao tentar executar esta ação. Por favor, entre em contato com o suporte."
      });
    }
  };

  const AtualizacaoVendedor = async () => {
    try {
      await Requicicao.Put({
        endpoint: "/Vendedor",
        data: Data,
        config: auth.GetHeaders()
      });
      notify({
        type: "sucesso",
        message: "Vendedor atualizado com sucesso"
      });
    } catch (error) {
      notify({
        type: "erro",
        message:
          "Erro ao tentar executar esta ação. Por favor, entre em contato com o suporte."
      });
    }
  };

  const Submit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (VendedorId === "") await CriarVendedor();
      else await AtualizacaoVendedor();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const GetFiliais = async () => {
    try {
      const data = await Requicicao.Get({
        endpoint: "/Empresa/ObterFiliais",
        config: auth.GetHeaders()
      });
      const lista = data.objetoResposta.map((element) => ({
        name: `${element.codigoLojaQueEstaInserida} - ${element.apelidoOuNomeFantasia}`,
        code: element.id,
        fullName: element.nomeCompletoOuRazaoSocial
      }));
      setListaFiliais(lista);
      return lista;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const GetThisVendedor = async () => {
    try {
      const { objetoResposta } = await Requicicao.Get({
        endpoint: "/Vendedor/ObterPorId",
        params: { id: VendedorId },
        config: auth.GetHeaders()
      });

      const empresas = objetoResposta.vendedorEmpresa.map((e) => ({
        name: `${e.codigoLojaQueEstaInserida} - ${e.empresaApelido}`,
        code: e.empresaId,
        fullName: e.empresaNome
      }));

      setData({
        id: VendedorId,
        nome: objetoResposta.nome,
        apelido: objetoResposta.apelido,
        cpf: objetoResposta.cpf,
        dataAdmissao: objetoResposta.dataAdmissao,
        porcentagemAoVender: objetoResposta.porcentagemAoVender,
        porcentagemAoReceber: objetoResposta.porcentagemAoReceber,
        porcentagemImpostoRendaRetidoFonteIrrf:
          objetoResposta.porcentagemImpostoRendaRetidoFonteIrrf,
        empresasIds: empresas.map((e) => e.code)
      });
      setcale(new Date(objetoResposta.dataAdmissao));
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmpresaChange = (empresaId) => {
    setData((prevState) => {
      const { empresasIds } = prevState;
      if (empresasIds.includes(empresaId)) {
        return {
          ...prevState,
          empresasIds: empresasIds.filter((id) => id !== empresaId)
        };
      }
      return {
        ...prevState,
        empresasIds: [...empresasIds, empresaId]
      };
    });
  };

  useEffect(() => {
    const Go = async () => {
      try {
        setLoading(true);
        await GetFiliais();
        if (VendedorId !== "") await GetThisVendedor();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (auth.isAuthenticated()) {
      Go();
    }
  }, [auth]);

  return (
    <form onSubmit={Submit} className="card-body">
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="nome">Nome</label>
          <InputText
            id="nome"
            name="nome"
            className="form-control"
            value={Data.nome}
            onChange={HandleChangeData}
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="apelido">Apelido</label>
          <InputText
            id="apelido"
            name="apelido"
            className="form-control"
            value={Data.apelido}
            onChange={HandleChangeData}
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="cpf">CPF</label>
          <InputText
            id="cpf"
            name="cpf"
            className="form-control"
            value={Data.cpf}
            onChange={HandleChangeData}
          />
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <label className="dataAdmissao">Data de Admissão</label>
            <Calendar
              id="dataAdmissao"
              dateFormat="dd/mm/yy"
              value={cale}
              onChange={(e) => {
                setcale(e.value);
                HandleChangeData({
                  target: { name: "dataAdmissao", value: e.value }
                });
              }}
            />
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <label className="porcentagemAoVender">Porcentagem ao Vender</label>
            <InputNumber
              id="porcentagemAoVender"
              min={0}
              max={100}
              useGrouping={false}
              maxFractionDigits={2}
              minFractionDigits={2}
              locale="pt-br"
              value={Data.porcentagemAoVender}
              onChange={(e) =>
                HandleChangeData({
                  target: {
                    name: "porcentagemAoVender",
                    value: e.value
                  }
                })
              }
            />
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <label className="porcentagemAoReceber">
              Porcentagem ao Receber
            </label>
            <InputNumber
              id="porcentagemAoReceber"
              min={0}
              max={100}
              useGrouping={false}
              maxFractionDigits={2}
              minFractionDigits={2}
              locale="pt-br"
              value={Data.porcentagemAoReceber}
              onChange={(e) =>
                HandleChangeData({
                  target: {
                    name: "porcentagemAoReceber",
                    value: e.value
                  }
                })
              }
            />
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-group">
            <label className="porcentagemImpostoRendaRetidoFonteIrrf">
              Porcentagem Imposto Renda Retido Fonte IRRF
            </label>
            <InputNumber
              id="porcentagemImpostoRendaRetidoFonteIrrf"
              min={0}
              max={100}
              useGrouping={false}
              maxFractionDigits={2}
              minFractionDigits={2}
              locale="pt-br"
              value={Data.porcentagemImpostoRendaRetidoFonteIrrf}
              onChange={(e) =>
                HandleChangeData({
                  target: {
                    name: "porcentagemImpostoRendaRetidoFonteIrrf",
                    value: e.value
                  }
                })
              }
            />
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <label className="empresa">Empresa</label>
          <div className="form-group">
            {ListaFiliais.map((filial) => (
              <div key={filial.code} className="field-checkbox mb-3">
                <Checkbox
                  inputId={filial.code}
                  name="empresasIds"
                  value={filial.code}
                  onChange={() => handleEmpresaChange(filial.code)}
                  checked={Data.empresasIds.includes(filial.code)}
                />
                <label htmlFor={filial.code} className="ml-2 ms-2 me-2">
                  {filial.name}
                  <Tooltip target={`#tooltip_${filial.code}`} />
                  <i
                    id={`tooltip_${filial.code}`}
                    className="pi pi-info-circle ml-2"
                    data-pr-tooltip={filial.fullName}
                  />
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-12">
          <div className="w-100 d-flex flex-row-reverse">
            <Button
              type="submit"
              label={VendedorId === "" ? "Salvar" : "Atualizar"}
              className="btn btn-primary"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

FormVendedor.propTypes = { VendedorId: PropTypes.string };

FormVendedor.defaultProps = {
  VendedorId: ""
};

export default FormVendedor;
