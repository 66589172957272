import { useState, useEffect } from "react";

import {
  FilterMatchMode,
  FilterOperator,
  locale,
  addLocale
} from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";

import ExportToExcel from "components/ExportToExcel";
import ptBrLocale from "components/Locale/locales";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

addLocale("pt-BR", ptBrLocale);
locale("pt-BR");

const ListaVendasConsolidadas = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [Lista, setLista] = useState([]);
  const [Colunas, setColunas] = useState([]);
  const [ListaEmpresas, setListaEmpresas] = useState([]);
  const [SelectedEmpresa, setSelectedEmpresa] = useState(null);
  const [filters, setFilters] = useState(null);
  const [dateRange, setDateRange] = useState(() => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return [firstDayOfMonth, lastDayOfMonth];
  });

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      produtoNome: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]
      }
    });
  };

  const ClearFilter = () => {
    InitFilters();
    setSelectedEmpresa(ListaEmpresas[0] || null);
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    setDateRange([firstDayOfMonth, lastDayOfMonth]);
  };

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString("pt-BR", options);
  };

  const formatCurrency = (value) => {
    if (typeof value === "number") {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    }
    return null;
  };

  const transformData = (data) => {
    const pivotData = [];
    const dates = [...new Set(data.map((item) => item.data))];
    const lojas = [...new Set(data.map((item) => item.codigoLoja))];
    const colTotals = {};

    dates.forEach((date) => {
      const row = {
        Data: new Date(date),
        DataVenda: formatDate(date),
        Total: 0
      };
      lojas.forEach((loja) => {
        const venda = data.find(
          (item) => item.data === date && item.codigoLoja === loja
        );
        const valor = venda ? venda.valorTotal : 0;
        row[loja] = formatCurrency(valor);
        row.Total += valor;
        colTotals[loja] = (colTotals[loja] || 0) + valor;
      });
      row.Total = formatCurrency(row.Total);
      pivotData.push(row);
    });

    pivotData.sort((a, b) => a.Data - b.Data); // Ordena as linhas pela data

    // Add totals row
    const totalsRow = { DataVenda: "Total", Total: 0 };
    lojas.forEach((loja) => {
      totalsRow[loja] = formatCurrency(colTotals[loja]);
      totalsRow.Total += colTotals[loja];
    });
    totalsRow.Total = formatCurrency(totalsRow.Total);
    pivotData.push(totalsRow);

    return { pivotData, lojas };
  };

  const GetVendas = async (matrizId) => {
    try {
      setLoading(true);
      const params = {};
      if (matrizId) params.matrizId = matrizId;
      const startDate = dateRange && dateRange[0] ? dateRange[0] : new Date();
      const endDate = dateRange && dateRange[1] ? dateRange[1] : new Date();
      params.dataInicio = startDate.toISOString();
      params.dataFim = endDate.toISOString();

      const data = await Requicicao.Get({
        endpoint: "/Venda/ObterResumoVendasConsolidadaDia",
        params,
        config: auth.GetHeaders()
      });

      const { pivotData, lojas } = transformData(data.objetoResposta);
      setLista(pivotData);
      setColunas(["DataVenda", ...lojas, "Total"]);
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter as vendas."
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const truncate = (str, length) => {
    if (str.length <= length) return str;
    return `${str.substring(0, length)}...`;
  };

  const GetAllEmpresas = async () => {
    try {
      const resposta = await Requicicao.Get({
        endpoint: "/Empresa/ObterMatrizesUsuarioAutenticado",
        config: auth.GetHeaders()
      });
      const empresas = resposta.objetoResposta.map((item) => ({
        code: item.id,
        name: `${truncate(item.nomeCompletoOuRazaoSocial, 30)} ${
          item.cpfOuCnpj
        }`
      }));
      setListaEmpresas(empresas);
      if (empresas.length > 0) {
        setSelectedEmpresa(empresas[0]);
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter a lista de empresas."
      });
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      GetAllEmpresas();
    }
  }, [auth]);

  useEffect(() => {
    if (SelectedEmpresa || (dateRange && dateRange[0] && dateRange[1])) {
      GetVendas(SelectedEmpresa ? SelectedEmpresa.code : null);
    }
  }, [SelectedEmpresa, dateRange]);

  const totalTemplate = (rowData, column) => {
    const isTotalRow = rowData.DataVenda === "Total";
    const value = rowData[column.field];
    return (
      <span
        style={{
          fontWeight: isTotalRow || column.field === "Total" ? "bold" : "normal"
        }}
      >
        {value}
      </span>
    );
  };

  return (
    <div>
      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-row gap-3">
            <ExportToExcel data={Lista} fileName="Vendas" />{" "}
            {/* Use o componente aqui */}
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Limpar filtro"
              outlined
              onClick={ClearFilter}
            />
          </div>
          <div className="d-flex gap-3">
            <Dropdown
              value={SelectedEmpresa}
              onChange={(e) => setSelectedEmpresa(e.value)}
              options={ListaEmpresas}
              optionLabel="name"
              placeholder="Filtro por empresa"
            />
            <Calendar
              value={dateRange}
              onChange={(e) => setDateRange(e.value)}
              selectionMode="range"
              readOnlyInput
              placeholder="Selecionar período"
              dateFormat="dd/mm/yy"
              locale="pt-BR"
            />
            <Button
              type="button"
              icon="pi pi-search"
              label="Buscar"
              onClick={() =>
                GetVendas(SelectedEmpresa ? SelectedEmpresa.code : null)
              }
            />
          </div>
        </div>
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={32}
          tableStyle={{ minWidth: "20rem" }}
          emptyMessage="Nenhuma venda encontrada."
          globalFilterFields={Colunas}
          filters={filters}
        >
          {Colunas.map((col) => (
            <Column key={col} field={col} header={col} body={totalTemplate} />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default ListaVendasConsolidadas;
