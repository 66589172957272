import { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";

import Api from "utils/Api";

const ListaEmpresasRH = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const navigate = useNavigate(); // Hook para navegação

  const [Lista, setLista] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [showEmpresaDialog, setShowEmpresaDialog] = useState(false);
  const [empresaSelecionada, setEmpresaSelecionada] = useState(null);

  const [ViLista, setViLista] = useState([]);

  const mostrarDetalhesEmpresa = (empresa) => {
    setEmpresaSelecionada(empresa);
    setShowEmpresaDialog(true);
  };

  const fecharDialog = () => {
    setShowEmpresaDialog(false);
    setEmpresaSelecionada(null);
  };

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: "/Empresa/RH/ObterTodos",
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        const lista = resposta.objetoResposta.map((element) => ({
          id: element.id,
          nome: element.pessoa.nomeCompletoOuRazaoSocial,
          nomeFantasia: element.pessoa.apelidoOuNomeFantasia,
          cnpj: element.pessoa.cpfOuCnpj,
          ehMatriz: element.ehMatriz,
          matrizId: element.ehMatriz ? null : element.matrizId,
          codigoLojaAtual: element.codigoLojaAtual,
          operador: element.operador,
          telefone: element.pessoa.telefones?.[0]
            ? `${element.pessoa.telefones[0].ddd} ${element.pessoa.telefones[0].numero}`
            : null,
          email: element.pessoa.emails?.[0]?.emailDaPessoa || null,
          endereco: element.pessoa.enderecos?.[0]
            ? `${element.pessoa.enderecos[0].enderecoDaPessoa}, ${element.pessoa.enderecos[0].numero} - ${element.pessoa.enderecos[0].bairro}, ${element.pessoa.enderecos[0].cidade} - ${element.pessoa.enderecos[0].estado}`
            : null,
          contas: element.contas || [],
          filiais: resposta.objetoResposta.filter(
            (filial) => filial.matrizId === element.id
          )
        }));
        setLista(lista);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
  }, [auth]);

  useEffect(() => {
    setViLista(Lista.filter((empresa) => empresa.ehMatriz));
  }, [Lista]);

  const renderFiliais = (filiais, id) =>
    filiais.length > 0 ? (
      <div className="row rh-filiais g-3">
        {filiais.map((filial) => (
          <div className="col-sm-12 col-md-6 col-lg-3" key={filial.id}>
            <Card title={filial.pessoa.apelidoOuNomeFantasia}>
              <p>
                <strong>CNPJ:</strong> {filial.pessoa.cpfOuCnpj}
              </p>
              <p>
                <strong>Código da Loja:</strong> {filial.codigoLojaAtual}
              </p>
              <p>
                <strong>Telefone:</strong>{" "}
                {filial.pessoa.telefones?.[0]
                  ? `${filial.pessoa.telefones[0].ddd} ${filial.pessoa.telefones[0].numero}`
                  : "N/A"}
              </p>
              <p>
                <strong>Email:</strong>{" "}
                {filial.pessoa.emails?.[0]?.emailDaPessoa || "N/A"}
              </p>
              <div className="d-flex flex-wrap justify-content-between align-items-center mt-2">
                <Button
                  type="button"
                  icon="pi pi-calendar"
                  label="Doc. Anual"
                  className="p-button-sm p-button-text"
                  title="Documentação Anual"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() =>
                    navigate({
                      pathname: "/app/rh/documento/anual",
                      search: createSearchParams({
                        id: filial.id
                      }).toString()
                    })
                  }
                  tooltip="Documentos anual"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  type="button"
                  icon="pi pi-calendar-times"
                  label="Doc. Mensal"
                  className="p-button-sm p-button-text"
                  title="Documentação Mensal"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() =>
                    navigate({
                      pathname: "/app/rh/documento/mensal",
                      search: createSearchParams({
                        id: filial.id
                      }).toString()
                    })
                  }
                  tooltip="Documentos mesal"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  type="button"
                  icon="pi pi-id-card"
                  label="Contratados"
                  className="p-button-sm p-button-text"
                  title="Lista de Funcionários Contratados"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() =>
                    navigate({
                      pathname: "/app/rh/funcionarios/contratados",
                      search: createSearchParams({
                        filial: filial.id,
                        matriz: id
                      }).toString()
                    })
                  }
                  tooltip="Lista de Funcionários Contratados"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  type="button"
                  icon="pi pi-users"
                  className="p-button-sm p-button-text"
                  label="Alocados"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() =>
                    navigate({
                      pathname: "/app/rh/funcionarios/alocados",
                      search: createSearchParams({
                        filial: filial.id,
                        matriz: id
                      }).toString()
                    })
                  }
                  tooltip="Funcionários Alocados"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  type="button"
                  icon="pi pi-info-circle"
                  label="Detalhes"
                  className="p-button-sm p-button-text"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() => mostrarDetalhesEmpresa(filial)}
                  title="Detalhes da Empresa"
                  tooltip="Detalhes da Empresa"
                  tooltipOptions={{ position: "bottom" }}
                />
                <Button
                  type="button"
                  icon="pi pi-chart-pie"
                  label="Indicadores"
                  className="p-button-sm p-button-text"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() =>
                    navigate({
                      pathname: "/app/rh/indicadores",
                      search: createSearchParams({
                        id: filial.id
                      }).toString()
                    })
                  }
                  title="Indicadores da Empresa"
                  tooltip="Indicadores da Empresa"
                  tooltipOptions={{ position: "bottom" }}
                />
              </div>
            </Card>
          </div>
        ))}
      </div>
    ) : (
      <p style={{ fontSize: "0.8rem" }}>Nenhuma filial encontrada.</p>
    );

  const renderMatrizActions = (rowData) => (
    <div className="d-flex justify-content-between align-items-center">
      <Button
        type="button"
        icon="pi pi-calendar"
        className="p-button-sm p-button-text"
        title="Documentação Anual"
        style={{ fontSize: "1rem" }}
        onClick={() =>
          navigate({
            pathname: "/app/rh/documento/anual",
            search: createSearchParams({
              id: rowData.id
            }).toString()
          })
        }
        tooltip="Documentação Anual"
        tooltipOptions={{ position: "bottom" }}
      />
      <Button
        type="button"
        icon="pi pi-calendar-times"
        className="p-button-sm p-button-text"
        title="Documentação Mensal"
        style={{ fontSize: "1rem" }}
        onClick={() =>
          navigate({
            pathname: "/app/rh/documento/mensal",
            search: createSearchParams({
              id: rowData.id
            }).toString()
          })
        }
        tooltip="Documentação Mensal"
        tooltipOptions={{ position: "bottom" }}
      />
      <Button
        type="button"
        icon="pi pi-id-card"
        className="p-button-sm p-button-text"
        title="Lista de Funcionários Contratados"
        style={{ fontSize: "1rem" }}
        onClick={() =>
          navigate({
            pathname: "/app/rh/funcionarios/contratados",
            search: createSearchParams({
              filial: rowData.id,
              matriz: rowData.id
            }).toString()
          })
        }
        tooltip="Lista de Funcionários Contratados"
        tooltipOptions={{ position: "bottom" }}
      />
      <Button
        type="button"
        icon="pi pi-users"
        className="p-button-sm p-button-text"
        title="Lista de Funcionários Alocados"
        style={{ fontSize: "1rem" }}
        onClick={() =>
          navigate({
            pathname: "/app/rh/funcionarios/alocados",
            search: createSearchParams({
              filial: rowData.id,
              matriz: rowData.id
            }).toString()
          })
        }
        tooltip="Lista de Funcionários Alocado"
        tooltipOptions={{ position: "bottom" }}
      />
      <Button
        type="button"
        icon="pi pi-info-circle"
        className="p-button-sm p-button-text"
        style={{ fontSize: "1rem" }}
        onClick={() => mostrarDetalhesEmpresa(rowData)}
        title="Detalhes da Empresa"
        tooltip="Detalhes da Empresa"
        tooltipOptions={{ position: "bottom" }}
      />
      <Button
        type="button"
        icon="pi pi-chart-pie"
        label="Indicadores"
        className="p-button-sm p-button-text"
        style={{ fontSize: "0.8rem" }}
        onClick={() =>
          navigate({
            pathname: "/app/rh/indicadores",
            search: createSearchParams({
              id: rowData.id
            }).toString()
          })
        }
        title="Indicadores da Empresa"
        tooltip="Indicadores da Empresa"
        tooltipOptions={{ position: "bottom" }}
      />
    </div>
  );

  return (
    <div className="card p-3">
      <Tooltip target=".custom-tooltip" />
      <div className="d-flex justify-content-between mb-3">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={(e) => setGlobalFilterValue(e.target.value)}
            placeholder="Pesquisa Global"
            style={{ fontSize: "1rem" }}
          />
        </span>
      </div>
      <DataTable
        value={ViLista}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={(rowData) =>
          renderFiliais(rowData.filiais, rowData.id)
        }
        dataKey="id"
        paginator
        rows={10}
        stripedRows
        emptyMessage="Nenhuma empresa encontrada."
        globalFilterFields={[
          "nome",
          "nomeFantasia",
          "codigoLojaAtual",
          "cnpj",
          "operador"
        ]}
      >
        <Column expander style={{ width: "3em" }} />
        <Column field="nome" header="Nome" sortable />
        <Column field="cnpj" header="CNPJ" sortable />
        <Column field="operador" header="Operador" sortable />
        <Column style={{ width: "3em" }} body={renderMatrizActions} />
      </DataTable>

      <Dialog
        header="Detalhes da Empresa"
        visible={showEmpresaDialog}
        style={{ width: "50vw" }}
        modal
        onHide={fecharDialog}
        className="rh-filiais"
      >
        {empresaSelecionada && (
          <div>
            <p>
              <strong>Nome:</strong> {empresaSelecionada.nome}
            </p>
            <p>
              <strong>CNPJ:</strong> {empresaSelecionada.cnpj}
            </p>
            <p>
              <strong>Operador:</strong> {empresaSelecionada.operador || "N/A"}
            </p>
            <p>
              <strong>Código da Loja:</strong>{" "}
              {empresaSelecionada.codigoLojaAtual}
            </p>
            <p>
              <strong>Telefone:</strong> {empresaSelecionada.telefone || "N/A"}
            </p>
            <p>
              <strong>Email:</strong> {empresaSelecionada.email || "N/A"}
            </p>
            <p>
              <strong>Endereço:</strong> {empresaSelecionada.endereco || "N/A"}
            </p>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ListaEmpresasRH;
