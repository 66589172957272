import { useState, useEffect } from "react";

import { Button } from "primereact/button";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import useQuery from "utils/useQuery";

const VisualizarFuncionario = () => {
  const [dados, setDados] = useState({});
  const { setLoading } = useLoading();
  const auth = useAuth();
  const notify = useNotification();
  const query = useQuery();
  const Requicicao = new Api();

  const { TiposDocs, TipoEscala } = MaskEnum;

  const [funcionarioId, setfuncionarioId] = useState(null);

  const FormatNomeTipoArquivo = (nomeTipoArquivo) =>
    nomeTipoArquivo.split("_")[0];

  const handleDownloadArquivo = async (nomeArquivo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Funcionarios/${funcionarioId}/DownloadArquivo`,
        params: { nomeArquivo },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob" // Important to get the file as a Blob
        }
      });

      // A verificação pode ser omitida se já temos um Blob.
      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
      notify({
        type: "error",
        message: "Erro ao baixar o arquivo. Tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setfuncionarioId(query.get("id"));
  }, [query]);

  useEffect(() => {
    const carregarDadosFuncionario = async () => {
      try {
        setLoading(true);
        const response = await Requicicao.Get({
          endpoint: `/Funcionarios/${funcionarioId}`,
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          setDados(response.objetoResposta);
        }
      } catch (error) {
        console.error("Erro ao carregar os dados do funcionário:", error);
        notify({
          type: "error",
          message:
            "Erro ao carregar os dados do funcionário. Tente novamente mais tarde."
        });
      } finally {
        setLoading(false);
      }
    };

    if (funcionarioId) {
      carregarDadosFuncionario();
    }
  }, [funcionarioId]);

  return (
    <div className="box-bem-vindo">
      <div className="card w-75 p-4 mb-5 mt-5">
        <div>
          <h1 className="fs-2">Ficha Cadastral</h1>
          <p className="fs-5">
            {MaskEnum.StatusCadastroFuncionari[dados.statusCadastro - 1]}
          </p>
        </div>

        <div className="row g-3 info-cadastro">
          <div className="col-md-4">
            <span>Nome</span>
            <p>{dados.nome}</p>
          </div>
          <div className="col-md-4">
            <span>CPF</span>
            <p>{dados.cpf}</p>
          </div>
          <div className="col-md-4">
            <span>RG</span>
            <p>{dados.rg}</p>
          </div>
          <div className="col-md-4">
            <span>Endereço</span>
            <p>{dados.endereco}</p>
          </div>
          <div className="col-md-4">
            <span>Data de Nascimento</span>
            <p>{new Date(dados.dataNascimento).toLocaleDateString()}</p>
          </div>
          <div className="col-md-4">
            <span>Data de Admissão</span>
            <p>{new Date(dados.dataAdmissao).toLocaleDateString()}</p>
          </div>
          <div className="col-md-4">
            <span>Data do Exame Admissional</span>
            <p>{new Date(dados.dataExameAdmissional).toLocaleDateString()}</p>
          </div>
          <div className="col-md-4">
            <span>Cargo</span>
            <p>{dados.cargo}</p>
          </div>
          <div className="col-md-4">
            <span>Escala</span>
            <p>{TipoEscala.find((x) => x.value === dados.tipoEscala)?.label}</p>
          </div>
          <div className="col-md-4">
            <span>Horário de Trabalho</span>
            <p>{dados.horarioTrabalho}</p>
          </div>
          <div className="col-md-4">
            <span>Banco</span>
            <p>{dados.banco}</p>
          </div>
          <div className="col-md-4">
            <span>Agência</span>
            <p>{dados.agencia}</p>
          </div>
          <div className="col-md-4">
            <span>Conta Corrente</span>
            <p>{dados.contaCorrente}</p>
          </div>
          <div className="col-md-4">
            <span>Email</span>
            <p>{dados.email}</p>
          </div>
          <div className="col-md-4">
            <span>Telefone</span>
            <p>{dados.telefone}</p>
          </div>
          <div className="col-md-4">
            <span>Endereço Empresa</span>
            <p>{dados.enderecoEmpresaContratada}</p>
          </div>
          <div className="col-md-4">
            <span>Empresa Contratante</span>
            <p>{dados.empresaContratante}</p>
          </div>
        </div>
        <hr />
        <h2 className="mt-3">Documentos</h2>
        <div className="row g-3">
          {dados.arquivosContratacao &&
            dados.arquivosContratacao.map((arquivo) => (
              <div className="col-3" key={arquivo.url}>
                <Button
                  icon="pi pi-download"
                  label={
                    TiposDocs.find(
                      (obj) =>
                        obj.name === FormatNomeTipoArquivo(arquivo.nomeArquivo)
                    ).label
                  }
                  className="p-button-text w-100"
                  onClick={() => handleDownloadArquivo(arquivo.nomeArquivo)}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default VisualizarFuncionario;
