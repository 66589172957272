import React, { useState, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Steps } from "primereact/steps";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";
import useQuery from "utils/useQuery";

import DadosColaborrador from "./modules/DadosColaborrador";
import Documentos from "./modules/Documentos";
import ExameMedico from "./modules/ExameMedico";
import InformacoesDependentes from "./modules/InformacoesDependentes";
import InformacoesPessoais from "./modules/InformacoesPessoais";

const FormularioFinalizacao = () => {
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const auth = useAuth();
  const notify = useNotification();
  const query = useQuery();
  const Requicicao = new Api();

  const steps = [
    { label: "Informações Pessoais" },
    { label: "Dados do Colaborador" },
    { label: "Exame Médico" },
    { label: "Informações dos Dependentes" },
    { label: "Documentos" }
  ];

  const [funcionarioId, setfuncionarioId] = useState(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const [Cep, setCep] = useState("");
  const [NumeroEndereco, setNumeroEndereco] = useState("");
  const [Escolaridades, setEscolaridades] = useState([]);
  const [statusAptidao, setStatusAptidao] = useState("");

  const [dados, setDados] = useState({
    nome: "",
    cpf: "",
    rg: "",
    endereco: "",
    email: "",
    telefone: "",
    banco: "",
    agencia: "",
    contaCorrente: ""
  });

  const [arquivos, setArquivos] = useState({
    foto: null,
    pisEsocial: null,
    rgCpf: null,
    dadosPessoais: null,
    endereco: null,
    carteiraTrabalho: null,
    dadosBancarios: null,
    exameAdmissional: null,
    tituloEleitor: null,
    reservista: null,
    cnh: null,
    escolaridade: null,
    dependentes: null,
    pensaoAlimenticia: null
  });

  const [dependente, setDependente] = useState({
    nome: "",
    dataNascimento: null,
    parentesco: "",
    cpf: ""
  });

  const [Exame, setExame] = useState(null);

  const HandleChange = (event) => {
    const { name, value } = event.target;
    setDados((prevState) => ({ ...prevState, [name]: value }));
  };

  const HandleFileChange = (e, category) => {
    const file = e.files && e.files[0];
    setArquivos((prevState) => ({ ...prevState, [category]: file }));
  };

  const HandleExameChange = (e) => {
    const file = e.files && e.files[0];
    setExame(file);
  };

  const ValidarCamposObrigatorios = () => {
    const camposObrigatorios = [
      "nome",
      "cpf",
      "rg",
      "endereco",
      "email",
      "telefone",
      "banco",
      "agencia",
      "contaCorrente"
    ];
    for (const campo of camposObrigatorios) {
      if (!dados[campo]) {
        notify({
          type: "error",
          message: `Por favor, preencha o campo: ${campo}`
        });
        return false;
      }
    }
    return true;
  };

  const ValidarArquivosObrigatorios = () => {
    const obrigatorios = [
      "foto",
      "pisEsocial",
      "rgCpf",
      "dadosPessoais",
      "endereco",
      "carteiraTrabalho",
      "dadosBancarios",
      "exameAdmissional"
    ];
    for (const categoria of obrigatorios) {
      if (!arquivos[categoria]) {
        notify({
          type: "error",
          message: `Por favor, envie o arquivo obrigatório: ${categoria}`
        });
        return false;
      }
    }
    return true;
  };

  const HandleNext = () => {
    setActiveIndex((prevIndex) => prevIndex + 1);
  };

  const HandleBack = () => {
    setActiveIndex((prevIndex) => prevIndex - 1);
  };

  const HandleDropdownChange = (e, field) => {
    setDados((prevState) => ({ ...prevState, [field]: e.value }));
  };

  const HandleSubmit = async (event) => {
    event.preventDefault();

    if (!ValidarCamposObrigatorios()) return;
    if (!ValidarArquivosObrigatorios()) return;

    try {
      setLoading(true);

      // Cria um FormData para enviar os dados do funcionário e os arquivos
      const formData = new FormData();

      const dataToForm = dados;
      dataToForm.statusAptidao = statusAptidao === "Apto";
      dataToForm.endereco = `${dataToForm.endereco} ${NumeroEndereco}`;
      delete dataToForm.dataExameAdmissional;
      delete dataToForm.pcdId;
      delete dataToForm.funcao;
      delete dataToForm.funcaoId;
      delete dataToForm.departamentoId;
      delete dataToForm.departamento;
      delete dataToForm.statusCadastro;
      delete dataToForm.valeAlimentacao;
      delete dataToForm.valeRefeicao;
      delete dataToForm.valeTransporte;
      delete dataToForm.planoSaude;
      delete dataToForm.planoOdontologico;
      delete dataToForm.seguroVida;
      delete dataToForm.auxilioCreche;
      delete dataToForm.valeAcademia;
      delete dataToForm.auxilioHomeOffice;
      delete dataToForm.tipoComissao;
      delete dataToForm.percentualComissao;
      delete dataToForm.comissaoFixa;

      // Adiciona os dados do funcionário ao FormData
      for (const key in dataToForm) {
        if (Object.prototype.hasOwnProperty.call(dados, key)) {
          formData.append(key, dados[key]);
        }
      }

      // Adiciona os arquivos ao FormData com o nome modificado
      for (const key in arquivos) {
        if (
          Object.prototype.hasOwnProperty.call(arquivos, key) &&
          arquivos[key]
        ) {
          const arquivo = arquivos[key];
          const nomeModificado = `${key}_${arquivo.name}`; // Modifica o nome do arquivo
          const arquivoModificado = new File([arquivo], nomeModificado, {
            type: arquivo.type
          });

          formData.append("arquivosContratacao", arquivoModificado);
        }
      }

      // Realiza a requisição PUT para atualizar os dados do funcionário com os documentos
      const data = await Requicicao.Put({
        endpoint: `/Funcionarios/${funcionarioId}/EditarComDocumentos`,
        data: formData,
        config: auth.GetHeaders("multipart/form-data")
      });

      if (data?.codigoEstadoHttp === 200) {
        if (dados?.dependentes?.length > 0) {
          await Requicicao.Put({
            endpoint: "/Funcionarios/Dependente",
            data: {
              id: funcionarioId,
              Dependentes: dados.dependentes
            }
          });
        }

        if (Exame) {
          const formDataExame = new FormData();

          const Dados = {
            funcionarioId,
            nomeArquivo: "ExameMedico",
            descricao: "documentos de exame de admissão Medico",
            tipoArquivo: 4
          };

          const dataToFormExame = Dados;

          // Adiciona os dados do funcionário ao FormData
          for (const key in dataToFormExame) {
            if (Object.prototype.hasOwnProperty.call(Dados, key)) {
              formDataExame.append(key, Dados[key]);
            }
          }

          // Adiciona o arquivo ao FormData
          if (Exame) {
            // 'arquivo' deve ser o nome esperado pelo servidor
            formDataExame.append("arquivoFuncionario", Exame);
          } else {
            notify({
              type: "aviso",
              message: "Por favor, selecione um arquivo para fazer upload."
            });
          }

          await Requicicao.Put({
            endpoint: `/Funcionarios/AdicionarArquivosFuncionario`,
            data: formDataExame,
            config: auth.GetHeaders("multipart/form-data")
          });
        }

        notify({
          type: "success",
          message: "Informações atualizadas com sucesso."
        });

        setTimeout(() => {
          navigate({
            pathname: "/boasVindas/dados",
            search: createSearchParams({
              id: funcionarioId
            }).toString()
          });
          setLoading(false);
        }, 1500);
      }
    } catch (error) {
      console.error("Erro ao salvar o funcionário:", error);
      notify({
        type: "error",
        message: "Erro ao salvar as informações. Tente novamente mais tarde."
      });
      setLoading(false);
    }
  };

  const SetDadosEndereco = async (cep) => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        url: `https://viacep.com.br/ws/${cep}/json/`
      });
      if (data.uf) {
        HandleChange({
          target: {
            name: "endereco",
            value: data.logradouro
          }
        });
      } else {
        notify({
          type: "error",
          message: "Cep não exite"
        });
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Cep não exite"
      });
    } finally {
      setLoading(false);
    }
  };

  const HandleAddDependente = () => {
    setDados((prev) => ({
      ...prev,
      dependentes: [
        ...(prev.dependentes ?? []),
        {
          cpf: dependente.cpf,
          nome: dependente.nome,
          nascimento: MaskUtil.applyDataUSMask(dependente.dataNascimento),
          parentesco: dependente.parentesco
        }
      ]
    }));
    setDependente({ nome: "", dataNascimento: null, parentesco: "", cpf: "" });
  };

  const RemoverDependente = (cpf) => {
    setDados((prev) => ({
      ...prev,
      dependentes: prev.dependentes.filter((x) => x.cpf !== cpf)
    }));
  };

  useEffect(() => {
    const CarregarEscolaridades = async () => {
      try {
        const response = await Requicicao.Get({
          endpoint: "/Funcionarios/Escolaridades",
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          const formattedEscolaridades = response.objetoResposta.map(
            (escolaridade) => ({
              label: escolaridade.descricao,
              value: escolaridade.id
            })
          );
          setEscolaridades(formattedEscolaridades);
        }
      } catch (error) {
        console.error("Erro ao carregar escolaridades:", error);
      }
    };

    const CarregarDadosFuncionario = async () => {
      try {
        setLoading(true);
        const response = await Requicicao.Get({
          endpoint: `/Funcionarios/${funcionarioId}`,
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          setDados((prevState) => ({
            ...prevState,
            ...response.objetoResposta,
            cpf: MaskUtil.applyCpfMask(response.objetoResposta.cpf),
            rg: MaskUtil.applyRgMask(response.objetoResposta.rg),
            telefone: MaskUtil.applyTelefoneMask(
              response.objetoResposta.telefone
            )
          }));
        }
      } catch (error) {
        console.error("Erro ao carregar os dados do funcionário:", error);
        notify({
          type: "error",
          message:
            "Erro ao carregar os dados do funcionário. Tente novamente mais tarde."
        });
      } finally {
        setLoading(false);
      }
    };

    if (funcionarioId != null) {
      CarregarDadosFuncionario();
      CarregarEscolaridades();
    }
  }, [funcionarioId]);

  useEffect(() => {
    setfuncionarioId(query.get("id"));
  }, [query]);

  return (
    <div className="box-bem-vindo">
      <div className="container">
        <form className="card p-4 mt-3 mb-3">
          <Steps model={steps} activeIndex={activeIndex} />
          <div>
            {activeIndex === 0 && (
              <InformacoesPessoais
                dados={dados}
                HandleChange={HandleChange}
                HandleDropdownChange={HandleDropdownChange}
                Escolaridades={Escolaridades}
              />
            )}

            {activeIndex === 1 && (
              <DadosColaborrador
                dados={dados}
                setDados={setDados}
                HandleChange={HandleChange}
                Cep={Cep}
                setCep={setCep}
                SetDadosEndereco={SetDadosEndereco}
                NumeroEndereco={NumeroEndereco}
                setNumeroEndereco={setNumeroEndereco}
              />
            )}

            {activeIndex === 2 && (
              <ExameMedico
                dados={dados}
                HandleChange={HandleChange}
                statusAptidao={statusAptidao}
                setStatusAptidao={setStatusAptidao}
                HandleExameChange={HandleExameChange}
              />
            )}

            {activeIndex === 3 && (
              <InformacoesDependentes
                dados={dados}
                HandleChange={HandleChange}
                dependente={dependente}
                setDependente={setDependente}
                HandleAddDependente={HandleAddDependente}
                RemoverDependente={RemoverDependente}
              />
            )}

            {activeIndex === 4 && (
              <Documentos HandleFileChange={HandleFileChange} />
            )}
          </div>

          <div className="d-flex justify-content-end gap-3">
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate(-1)}
              label="Voltar à Tela Anterior"
            />
            {activeIndex > 0 && (
              <Button
                type="button"
                className="btn btn-secondary"
                onClick={HandleBack}
                label="Voltar"
              />
            )}
            {activeIndex < steps.length - 1 && (
              <Button
                type="button"
                className="btn btn-primary"
                onClick={HandleNext}
                label="Próximo"
              />
            )}
            {activeIndex === steps.length - 1 && (
              <Button
                type="submit"
                className="btn btn-primary"
                onClick={HandleSubmit}
                label="Finalizar"
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormularioFinalizacao;
