import { createContext, useContext, useState, useEffect, useMemo } from "react";

import PropTypes from "prop-types";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth deve ser utilizado dentro de um AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  // const router = useRouter();
  const [user, setUser] = useState(null); // Pode conter os detalhes do usuário logado

  // Verifica se o usuário está autenticado (por exemplo, verifica o token)
  const isAuthenticated = () => !!user?.token; // Verifica se o token existe
  const GetAccessLevel = () => user.acesso;
  const UserNome = () => user.pessoa.apelidoOuNomeFantasia;

  // Função para fazer login (pode chamar sua API de login aqui)
  const login = (userData) => {
    setUser({
      ...userData,
      acesso: userData.permissoes.map((item) => item.permissaoId)
    });
    localStorage.setItem("useId", userData.id);
    localStorage.setItem(
      "acesso",
      JSON.stringify(userData.permissoes.map((item) => item.permissaoId))
    );
    localStorage.setItem("token", userData.token);
    localStorage.setItem("nomeFantasia", userData.pessoa.apelidoOuNomeFantasia);
    localStorage.setItem("nome", userData.pessoa.nomeCompletoOuRazaoSocial);
  };

  // Função para fazer logout
  const logout = () => {
    localStorage.removeItem("useId");
    localStorage.removeItem("acesso");
    localStorage.removeItem("token");
    localStorage.removeItem("nomeFantasia");
    localStorage.removeItem("nome");
    setUser(null);
  };

  const GetHeaders = (contentType) => {
    if (contentType != null) {
      return {
        headers: {
          Authorization: `Bearer ${user?.token}`,
          "Content-Type": contentType
        }
      };
    }
    return {
      headers: {
        Authorization: `Bearer ${user?.token}`,
        "Content-Type": "application/json"
      }
    };
  };

  const RotaRestrida = () => {
    try {
      const currentPath = window.location.pathname;
      if (currentPath.includes("/app")) {
        logout();
      }
    } catch (error) {
      console.error("RotasRestritas:", error);
    }
  };

  useEffect(() => {
    const VerificarAutenticacao = async () => {
      try {
        if (!isAuthenticated()) {
          const token = localStorage.getItem("token");

          if (token) {
            const acesso = JSON.parse(localStorage.getItem("acesso"));
            const apelidoOuNomeFantasia = localStorage.getItem("nomeFantasia");
            const nomeCompletoOuRazaoSocial = localStorage.getItem("nome");
            const useId = localStorage.getItem("useId");

            const data = {
              acesso,
              id: useId,
              token,
              pessoa: {
                apelidoOuNomeFantasia,
                nomeCompletoOuRazaoSocial
              }
            };

            setUser(data);
          } else {
            RotaRestrida();
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    VerificarAutenticacao();
  }, []);

  const value = useMemo(
    () => ({
      user,
      isAuthenticated,
      login,
      logout,
      GetHeaders,
      UserNome,
      GetAccessLevel
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired
};
