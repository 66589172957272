import { useState, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import useQuery from "utils/useQuery";

const ReenvioAquivos = () => {
  const navigate = useNavigate();
  const notify = useNotification();
  const query = useQuery();
  const auth = useAuth();
  const { setLoading } = useLoading();
  const Requicicao = new Api();
  const { TiposDocs } = MaskEnum;

  const [funcionarioId, setfuncionarioId] = useState(null);
  const [dados, setDados] = useState([]);

  const [arquivos, setArquivos] = useState({});

  const handleFileChange = (e, category) => {
    const file = e.files && e.files[0];
    setArquivos((prevState) => ({ ...prevState, [category]: file }));
  };

  const formatNomeTipoArquivo = (nomeTipoArquivo) =>
    nomeTipoArquivo.split("_")[0];

  const formatForlabel = (name) =>
    TiposDocs.find((obj) => obj.name === formatNomeTipoArquivo(name)).label;

  const handleSubmit = async () => {
    try {
      setLoading(true);

      // Cria um FormData para enviar os dados do funcionário e os arquivos
      const formData = new FormData();

      // Adiciona os arquivos ao FormData com o nome modificado
      for (const key in arquivos) {
        if (
          Object.prototype.hasOwnProperty.call(arquivos, key) &&
          arquivos[key]
        ) {
          const arquivo = arquivos[key];
          const nomeModificado = `${key}_${arquivo.name}`; // Modifica o nome do arquivo
          const arquivoModificado = new File([arquivo], nomeModificado, {
            type: arquivo.type
          });

          formData.append("arquivosContratacao", arquivoModificado);
        }
      }

      await Requicicao.Put({
        endpoint: `/Funcionarios/${funcionarioId}/AtualizarDocumentos`,
        data: formData,
        config: auth.GetHeaders("multipart/form-data")
      });

      notify({
        type: "success",
        message: "Informações atualizadas com sucesso."
      });

      navigate({
        pathname: "/boasVindas/dados",
        search: createSearchParams({
          id: funcionarioId
        }).toString()
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setfuncionarioId(query.get("id"));
  }, [query]);

  useEffect(() => {
    const GetData = async () => {
      try {
        setLoading(true);
        const data = await Requicicao.Get({
          endpoint: "/Funcionarios/FotosNaoProvados",
          params: { id: funcionarioId },
          config: auth.GetHeaders()
        });
        if (data.codigoEstadoHttp === 200)
          setDados(data.objetoResposta.arquivosContratacao);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (funcionarioId != null) {
      GetData();
    }
  }, [funcionarioId]);

  return (
    <div className="box-bem-vindo">
      <div className="card p-3">
        <h1 className="fs-3">Reenvio de Fotos</h1>
        <p>
          Essas fotos não foram aceitas. Por favor, faça o reenvio novamente.
        </p>
        <div className="row g-3">
          {dados.map((item) => (
            <div key={item.key} className="col-sm-12 col-md-6">
              <label
                htmlFor={formatNomeTipoArquivo(item.nomeArquivo)}
                className="form-label"
              >
                {formatForlabel(item.nomeArquivo)}
              </label>
              <FileUpload
                uploadLabel="Enviar"
                cancelLabel="Cancelar"
                id={formatNomeTipoArquivo(item.nomeArquivo)}
                name={formatNomeTipoArquivo(item.nomeArquivo)}
                chooseLabel={formatForlabel(item.nomeArquivo)}
                customUpload
                auto={false}
                uploadHandler={null}
                multiple={false}
                onSelect={(e) =>
                  handleFileChange(e, formatNomeTipoArquivo(item.nomeArquivo))
                }
              />
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end mt-3">
          <Button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Enviar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReenvioAquivos;
