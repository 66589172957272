import { useState, useEffect } from "react";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";

import PDFPreview from "components/PDFPreview";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import MaskUtil from "utils/MaskUtil";

const MeusDocs = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const notify = useNotification();

  const Requicicao = new Api();
  const { TiposDocs } = MaskEnum;

  const [Dados, setDados] = useState({});
  const [FuncionarioId, setFuncionarioId] = useState(null);
  const [Lista1, setLista1] = useState([]);
  const [Lista2, setLista2] = useState([]);

  const [nameSelectedImage, setNameSelectedImage] = useState("");

  const [ShowMoldaImg, setShowMoldaImg] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rotation, setRotation] = useState(0);

  const [ShowMoldaPdf, setShowMoldaPdf] = useState(false);
  const [PdfFile, setPdfFile] = useState(null);

  const RotateImage = (angle) => {
    setRotation((prevRotation) => prevRotation + angle);
  };

  const HandleDownloadDocumento = async (nomeArquivo, tipo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Funcionarios/${FuncionarioId}/${tipo}`,
        params: { nomeArquivo },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob"
        }
      });

      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return true;
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
      notify({
        type: "error",
        message: "Erro ao carregar a imagem. Tente novamente mais tarde."
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const HandleShowDocumento = async (nomeArquivo, tipo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Funcionarios/${FuncionarioId}/${tipo}`,
        params: { nomeArquivo },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob"
        }
      });

      if (tipo === "DownloadDoc") {
        const blob = new Blob([response], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        setPdfFile(blobUrl);
        setShowMoldaPdf(true);
        setNameSelectedImage(nomeArquivo);
      } else {
        const contentType = nomeArquivo;

        if (contentType.includes(".pdf")) {
          // Trata o arquivo como PDF
          const blob = new Blob([response], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blob);
          setPdfFile(blobUrl);
          setShowMoldaPdf(true);
        } else if (
          contentType.includes(".jpg") ||
          contentType.includes(".jpeg") ||
          contentType.includes(".png")
        ) {
          // Trata o arquivo como imagem
          const blob = new Blob([response], { type: "image/" });
          const url = window.URL.createObjectURL(blob);
          setSelectedImage(url);
          setShowMoldaImg(true);
          setRotation(0);
        } else {
          throw new Error("Tipo de arquivo não suportado");
        }
      }

      return true;
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
      notify({
        type: "error",
        message: "Erro ao carregar a imagem. Tente novamente mais tarde."
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const OpenImage = async (fileName, tipo) => {
    try {
      setLoading(true);
      await HandleShowDocumento(fileName, tipo);
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetConta = async () => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/Conta/MeusDocs",
        params: { id: auth.user.id },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setDados(data.objetoResposta);
      }
      const data2 = await Requicicao.Get({
        endpoint: "/Funcionarios/ArquivosConta",
        params: { contaId: auth.user.id },
        config: auth.GetHeaders()
      });
      if (data2.codigoEstadoHttp === 200) {
        setFuncionarioId(data2.objetoResposta.id);
        setLista1(
          data2.objetoResposta.arquivosFuncioario.filter(
            (x) => x.tipoArquivo === 1
          )
        );
        setLista2(
          data2.objetoResposta.arquivosFuncioario.filter(
            (x) => x.tipoArquivo === 2
          )
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const FormatNomeTipoArquivo = (nomeTipoArquivo) =>
    nomeTipoArquivo.split("_")[0];

  const FormatForlabel = (name) =>
    TiposDocs.find((obj) => obj.name === FormatNomeTipoArquivo(name)).label;

  useEffect(() => {
    if (auth.isAuthenticated()) GetConta();
  }, [auth]);

  return (
    <div className="card p-1">
      <Dialog
        header={nameSelectedImage}
        visible={ShowMoldaPdf}
        onHide={() => setShowMoldaPdf(false)}
      >
        <PDFPreview file={PdfFile} />
      </Dialog>
      <Dialog
        header={nameSelectedImage}
        visible={ShowMoldaImg}
        style={{ width: "50vw" }}
        onHide={() => setShowMoldaImg(false)}
        maximizable
      >
        {selectedImage && (
          <img
            src={selectedImage}
            alt="Imagem ampliada"
            style={{
              width: "100%",
              transform: `rotate(${rotation}deg)`,
              transition: "transform 0.5s ease"
            }}
          />
        )}
        <div className="d-flex flex-row gap-3 mt-3">
          <Button
            onClick={() => RotateImage(-90)}
            label="Girar Esquerda"
            icon="pi pi-replay"
          />
          <Button
            onClick={() => RotateImage(90)}
            label="Girar Direita"
            icon="pi pi-refresh"
          />
        </div>
      </Dialog>
      <TabView>
        <TabPanel header="Contratação">
          <div className="d-flex flex-wrap gap-3">
            {Dados?.arquivosContratacao != null ? (
              Dados?.arquivosContratacao?.map((item) => (
                <div key={item.id} className="card p-3">
                  <p>
                    {FormatForlabel(FormatNomeTipoArquivo(item.nomeArquivo))}
                  </p>
                  <div className="d-flex flex-wrap gap-3">
                    <Button
                      type="button"
                      label="Visualizar"
                      style={{ width: "100px" }}
                      onClick={() => {
                        OpenImage(item.nomeArquivo, "DownloadArquivo");
                        setNameSelectedImage(
                          FormatForlabel(
                            FormatNomeTipoArquivo(item.nomeArquivo)
                          )
                        );
                      }}
                    />
                    <Button
                      type="button"
                      label="Baixa"
                      style={{ width: "100px" }}
                      onClick={() => {
                        HandleDownloadDocumento(
                          item.nomeArquivo,
                          "DownloadArquivo"
                        );
                      }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="card p-3">
                <p className="fs-3 text-center m-0 ">
                  Documentos não encontrado
                </p>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel header="Holerite">
          <DataTable value={Lista1}>
            <Column field="nomeArquivo" header="Nome do arquivo" />
            <Column field="descricao" header="Descrição" />
            <Column field="ano" header="Ano" />
            <Column field="mes" header="Mes" />
            <Column
              field="dataCriacao"
              header="Data de criação"
              body={(row) => MaskUtil.applyDataAndHoraMask(row.dataCriacao)}
            />
            <Column
              header="Ação"
              body={(row) => (
                <div className="d-flex gap-3">
                  <Button
                    label="Visualizador"
                    onClick={() => OpenImage(row.nomeArquivo, "DownloadDoc")}
                  />
                  <Button
                    label="Baixar"
                    onClick={() =>
                      HandleDownloadDocumento(row.nomeArquivo, "DownloadDoc")
                    }
                  />
                </div>
              )}
            />
          </DataTable>
        </TabPanel>
        <TabPanel header="Informe de Rendimento">
          <DataTable value={Lista2}>
            <Column field="nomeArquivo" header="Nome do arquivo" />
            <Column field="descricao" header="Descrição" />
            <Column field="ano" header="Ano" />
            <Column field="mes" header="Mes" />
            <Column
              field="dataCriacao"
              header="Data de criação"
              body={(row) => MaskUtil.applyDataAndHoraMask(row.dataCriacao)}
            />
            <Column
              header="Ação"
              body={(row) => (
                <div className="d-flex gap-3">
                  <Button
                    icon="pi pi-eye"
                    tooltip="Visualizar"
                    tooltipOptions={{ position: "bottom" }}
                    onClick={() => OpenImage(row.nomeArquivo, "DownloadDoc")}
                  />
                  <Button
                    icon="pi pi-download"
                    tooltip="Baixa"
                    tooltipOptions={{ position: "bottom" }}
                    onClick={() =>
                      HandleDownloadDocumento(row.nomeArquivo, "DownloadDoc")
                    }
                  />
                </div>
              )}
            />
          </DataTable>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default MeusDocs;
