/* eslint-disable react/no-danger */
import { useState, useEffect } from "react";

import { useAuth } from "context/AuthContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";

import "./styled.scss";

const Notifications = () => {
  const auth = useAuth();
  const Requicicao = new Api();

  const notificacaoConfig = {
    0: { icon: "pi pi-info-circle", color: "#2196F3" },
    1: { icon: "pi pi-check-circle", color: "#4CAF50" },
    2: { icon: "pi pi-exclamation-triangle", color: "#FFC107" },
    3: { icon: "pi pi-times-circle", color: "#F44336" },
    4: { icon: "pi pi-cog", color: "#010101" },
    5: { icon: "pi pi-user", color: "#3F51B5" },
    6: { icon: "pi pi-list", color: "#8BC34A" },
    7: { icon: "pi pi-envelope", color: "#FF9800" },
    8: { icon: "pi pi-refresh", color: "#00BCD4" },
    9: { icon: "pi pi-calendar", color: "#9C27B0" },
    10: { icon: "pi pi-flag", color: "#E91E63" },
    11: { icon: "pi pi-check", color: "#607D8B" },
    12: { icon: "pi pi-bell", color: "#FFEB3B" },
    13: { icon: "pi pi-exclamation-circle", color: "#D32F2F" },
    14: { icon: "pi pi-star", color: "#FF5722" }
  };

  const [Notification, setNotification] = useState([]);

  const GetNotificao = async () => {
    try {
      const data = await Requicicao.Get({
        endpoint: "/Notificacao/MyAllInfo",
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setNotification(data.objetoResposta);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      GetNotificao();
    }
  }, [auth]);

  const SortNotificacao = (a, b) => {
    const dataA = new Date(a.dataInicio);
    const dataB = new Date(b.dataInicio);
    return dataB - dataA;
  };

  return (
    <div className="card p-5">
      <ul className="list-group">
        {Notification.sort(SortNotificacao).map((item) => {
          const { icon, color } = notificacaoConfig[item.tipoNotificacao]; // Obter ícone e cor com base no tipo

          return (
            <li key={item.id} className="item-notificacao">
              <div
                className="info-tab tip-icon-alert"
                style={{ "--background-color": color }}
              >
                <i className={`icon ${icon}`} />
                <i className="obj" />
              </div>
              <div className="w-100 d-flex justify-content-between align-items-center">
                <h5>{item.titulo}</h5>
                <p className="fs-6 m-0 text-muted">
                  {MaskUtil.applyDataAndHoraMask(item.dataInicio)}
                </p>
              </div>
              <div
                className="box-mensagem"
                dangerouslySetInnerHTML={{ __html: item.mensagem }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Notifications;
