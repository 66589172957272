/* eslint-disable react/no-danger */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";

const ConviteFuncionario = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const notify = useNotification();
  const { empresaId } = useParams();
  const { setLoading } = useLoading();

  const Requicicao = new Api();

  const [conviteTexto, setConviteTexto] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { TipoEscala } = MaskEnum;

  const [dados, setDados] = useState({
    nome: "",
    cpf: "",
    dataNascimento: null,
    email: "",
    telefone: "",
    regimeContratacaoId: null,
    jornadaTrabalhoId: null,
    salario: "",
    cargo: ""
  });

  const [regimes, setRegimes] = useState([]);
  const [jornadas, setJornadas] = useState([]);

  const VisualizarConvite = async () => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Funcionarios/Convite/${dados.nome}/${empresaId}`,
        config: auth.GetHeaders()
      });

      if (response.codigoEstadoHttp === 200) {
        setConviteTexto(response.mensagemAdicional);
        setShowModal(true);
      } else {
        notify({
          type: "error",
          message: "Erro ao carregar o convite. Tente novamente mais tarde."
        });
      }
    } catch (error) {
      console.error("Erro ao visualizar o convite:", error);
      notify({
        type: "error",
        message: "Erro ao visualizar o convite. Tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  const HandleChange = (event) => {
    const { name, value } = event.target;
    setDados((prevState) => ({ ...prevState, [name]: value }));
  };

  const HandleDropdownChange = (e, field) => {
    setDados((prevState) => ({ ...prevState, [field]: e.value }));
  };

  const ValidarMaiorDe18 = (dataNascimento) => {
    const hoje = new Date();
    const nascimento = new Date(dataNascimento);

    // Calcula a diferença de anos entre a data atual e a data de nascimento
    let idade = hoje.getFullYear() - nascimento.getFullYear();

    // Verifica se ainda não fez aniversário este ano
    const aniversarioNaoPassou =
      hoje.getMonth() < nascimento.getMonth() ||
      (hoje.getMonth() === nascimento.getMonth() &&
        hoje.getDate() < nascimento.getDate());

    if (aniversarioNaoPassou) {
      idade--; // Se o aniversário ainda não passou, subtrai 1 da idade
    }

    if (idade >= 18) {
      return true;
    }
    notify({
      type: "aviso",
      message: "O candidato tem que ser de maior idade."
    });
    return false;
  };

  const ValidaCampos = (data) => {
    if (data.jornadaTrabalhoId == null) return false;
    return true;
  };

  const HandleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const payload = {
        ...dados,
        empresaId,
        cpf: dados.cpf, // Remove a formatação
        telefone: dados.telefone.replace(/\D/g, ""), // Remove a formatação
        salario: dados.salario, // Converte o salário para formato numérico
        dataAdmissao: dados.dataAdmissao,
        horarioTrabalho: dados.horarioTrabalho
      };

      if (
        payload.dataNascimento !== null &&
        ValidarMaiorDe18(payload.dataNascimento) &&
        ValidaCampos(payload)
      ) {
        await Requicicao.Post({
          endpoint: "/Funcionarios/EnviarConvite",
          data: payload,
          config: auth.GetHeaders()
        });

        notify({
          type: "success",
          message: "Convite enviado com sucesso."
        });
        navigate(-1);
      } else {
        notify({
          type: "aviso",
          message: "Dados Invalidos."
        });
      }
    } catch (error) {
      console.error("Erro ao enviar o convite:", error);
      notify({
        type: "error",
        message: "Erro ao enviar o convite. Tente novamente mais tarde."
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const CarregarRegimes = async () => {
      try {
        const response = await Requicicao.Get({
          endpoint: "/Funcionarios/Regime",
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          const formattedRegimes = response.objetoResposta
            .map((regime) => ({
              label: regime.descricao,
              value: regime.id
            }))
            .filter((x) => x.label !== "CLT Flex");
          setRegimes(formattedRegimes);
        }
      } catch (error) {
        console.error("Erro ao carregar regimes de contratação:", error);
      }
    };

    const CarregarJornadas = async () => {
      try {
        const response = await Requicicao.Get({
          endpoint: "/Funcionarios/Jornada",
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          const formattedJornadas = response.objetoResposta.map((jornada) => ({
            label: jornada.descricao,
            value: jornada.id
          }));
          setJornadas(
            formattedJornadas.filter((x) => !x.label.includes("Escala"))
          );
        }
      } catch (error) {
        console.error("Erro ao carregar jornadas de trabalho:", error);
      }
    };

    CarregarRegimes();
    CarregarJornadas();
  }, [auth]);

  return (
    <div className="card" id="form-convite-funcionario">
      <form className="card-body" onSubmit={HandleSubmit}>
        <div className="row g-3">
          <div className="col-md-4">
            <label htmlFor="nome" className="form-label">
              Nome Completo
            </label>
            <InputText
              id="nome"
              name="nome"
              value={dados.nome}
              onChange={HandleChange}
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="cpf" className="form-label">
              CPF
            </label>
            <InputMask
              id="cpf"
              mask="999.999.999-99"
              name="cpf"
              value={dados.cpf}
              onChange={HandleChange}
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="dataNascimento" className="form-label">
              Data de Nascimento
            </label>
            <Calendar
              id="dataNascimento"
              name="dataNascimento"
              value={dados.dataNascimento}
              onChange={HandleChange}
              dateFormat="dd/mm/yy"
              className="w-100"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="email" className="form-label">
              E-mail
            </label>
            <InputText
              id="email"
              name="email"
              value={dados.email}
              onChange={HandleChange}
              className="form-control"
              keyfilter="email"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="telefone" className="form-label">
              Telefone com DDD
            </label>
            <InputMask
              id="telefone"
              mask="(99) 99999-9999"
              name="telefone"
              value={dados.telefone}
              onChange={HandleChange}
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="regimeContratacaoId" className="form-label">
              Regime de Contratação
            </label>
            <Dropdown
              id="regimeContratacaoId"
              value={dados.regimeContratacaoId}
              onChange={(e) => HandleDropdownChange(e, "regimeContratacaoId")}
              options={regimes}
              placeholder="Selecione o Regime de Contratação"
              className="w-100"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="jornadaTrabalhoId" className="form-label">
              Jornada de Trabalho
            </label>
            <Dropdown
              id="jornadaTrabalhoId"
              value={dados.jornadaTrabalhoId}
              onChange={(e) => HandleDropdownChange(e, "jornadaTrabalhoId")}
              options={jornadas}
              placeholder="Selecione a Jornada de Trabalho"
              className="w-100"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="salario" className="form-label">
              Salário
            </label>
            <InputNumber
              id="salario"
              name="salario"
              mode="currency"
              locale="pt-BR"
              currency="BRL"
              value={dados.salario}
              onChange={(e) =>
                HandleChange({
                  target: {
                    name: "salario",
                    value: e.value
                  }
                })
              }
              className="w-100"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="cargo" className="form-label">
              Cargo
            </label>
            <InputText
              id="cargo"
              name="cargo"
              value={dados.cargo}
              onChange={HandleChange}
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="dataAdmissao" className="form-label">
              Data de Admissão
            </label>
            <Calendar
              id="dataAdmissao"
              name="dataAdmissao"
              value={dados.dataAdmissao}
              onChange={HandleChange}
              dateFormat="dd/mm/yy"
              className="w-100"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="horarioTrabalho" className="form-label">
              Horário de Trabalho
            </label>
            <InputText
              id="horarioTrabalho"
              name="horarioTrabalho"
              value={dados.horarioTrabalho}
              onChange={HandleChange}
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="tipoEscala" className="form-label">
              Tipo Escala
            </label>
            <Dropdown
              id="tipoEscala"
              value={dados.tipoEscala}
              onChange={(e) => HandleDropdownChange(e, "tipoEscala")}
              options={TipoEscala}
              className="w-100"
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4 mb-4">
          <Button
            type="button"
            className="btn btn-info ms-2 me-4"
            onClick={VisualizarConvite}
            icon="pi pi-eye"
            label="Visualizar Convite"
          />

          <Button
            type="button"
            className="btn btn-secondary me-4"
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
          <Button type="submit" className="btn btn-primary ms-2">
            Enviar Convite
          </Button>
        </div>
        {showModal && (
          <Dialog
            header="Convite"
            visible={showModal}
            style={{ width: "50vw" }}
            modal
            onHide={() => setShowModal(false)}
          >
            <div dangerouslySetInnerHTML={{ __html: conviteTexto }} />
            <Button
              type="button"
              className="btn btn-secondary mt-3"
              onClick={() => setShowModal(false)}
            >
              Fechar
            </Button>
          </Dialog>
        )}
      </form>
    </div>
  );
};

export default ConviteFuncionario;
