import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { TabView, TabPanel } from "primereact/tabview";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import MaskUtil from "utils/MaskUtil";

import Alocacao from "./modules/Alocacao";
import Beneficios from "./modules/Beneficios";
import DadosAdmissionais from "./modules/DadosAdmissionais";
import DadosPessoais from "./modules/DadosPessoais";
import Dependentes from "./modules/Dependentes";
import EscalaFuncionario from "./modules/EscalaFuncionario";
import ExameMedico from "./modules/ExameMedico";
import ListaDocumentos from "./modules/ListaDocumentos";
import Ocorrencia from "./modules/Ocorrencia";
import Status from "./modules/Status";

const FormFuncionario = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const notify = useNotification();
  const Requicicao = new Api();
  const navigate = useNavigate();
  const { funcionarioId, empresaId } = useParams();

  const { TipoComissao } = MaskEnum;
  const optsStatus = MaskEnum.StatusCadastroFuncionari.map((status, index) => ({
    name: status,
    valor: index + 1
  }));

  const [activeIndex, setActiveIndex] = useState(0); // Gerencia a aba ativa
  const [dados, setDados] = useState({
    id: "",
    nome: "",
    cpf: "",
    rg: "",
    cnh: "",
    endereco: "",
    funcaoId: null,
    cargo: "", // Campo de cargo
    escolaridadeId: null,
    departamentoId: null,
    email: "",
    telefone: "",
    dataNascimento: null,
    dataAdmissao: null,
    banco: "",
    agencia: "",
    contaCorrente: "",
    dataExameAdmissional: null,
    tamanhoUniforme: "",
    pis: "",
    tituloEleitor: "",
    numeroCarteiraTrabalho: "",
    salario: "",
    horarioTrabalho: "",
    regimeContratacaoId: null, // Novo campo
    jornadaTrabalhoId: null, // Novo campo
    pcdId: null // Novo campo
  });

  const [funcoes, setFuncoes] = useState([]); // Usado para função e cargo
  const [escolaridades, setEscolaridades] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [regimes, setRegimes] = useState([]);
  const [jornadas, setJornadas] = useState([]);
  const [pcds, setPcds] = useState([]);
  const [ListaEmpresas, setListaEmpresas] = useState([]);

  const [legible, setLegible] = useState([]);

  const HandleChange = (event) => {
    const { name, value } = event.target;
    if (name === "banco")
      setDados((prevState) => ({
        ...prevState,
        [name]: MaskUtil.applyNumeroBancoMask(value)
      }));
    else if (name === "agencia")
      setDados((prevState) => ({
        ...prevState,
        [name]: MaskUtil.applyNumeroAgenciaMask(value)
      }));
    else if (name === "contaCorrente")
      setDados((prevState) => ({
        ...prevState,
        [name]: MaskUtil.applyNumeroContaMask(value)
      }));
    else setDados((prevState) => ({ ...prevState, [name]: value }));
  };

  const HandleDropdownChange = (e, field) => {
    setDados((prevState) => ({ ...prevState, [field]: e.value }));
  };

  const HandleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      // Verifica se algum dos campos está null ou undefined
      if (
        dados.cpf == null ||
        dados.rg == null ||
        dados.cnh == null ||
        dados.telefone == null
      ) {
        setLoading(false);
        notify({
          type: "aviso",
          message: "Os campos CPF, RG, CNH e Telefone são obrigatórios."
        });
        return;
      }

      const payload = {
        nome: dados.nome,
        cpf: dados.cpf, // Remove a formatação
        rg: dados.rg.replace(/\D/g, ""), // Remove a formatação
        cnh: dados.cnh.replace(/\D/g, ""), // Remove a formatação
        dataNascimento: dados.dataNascimento,
        endereco: dados.endereco,
        email: dados.email,
        telefone: dados.telefone.replace(/\D/g, ""), // Remove a formatação
        funcaoId: dados.funcaoId,
        cargo: dados.cargo,
        departamentoId: dados.departamentoId,
        escolaridadeId: dados.escolaridadeId,
        dataAdmissao: dados.dataAdmissao,
        banco: dados.banco,
        agencia: dados.agencia,
        contaCorrente: dados.contaCorrente,
        dataExameAdmissional: dados.dataExameAdmissional,
        tamanhoUniforme: dados.tamanhoUniforme,
        salario:
          parseFloat(dados.salario.replace(/\./g, "").replace(",", ".")) || 0, // Converte para formato numérico
        horarioTrabalho: dados.horarioTrabalho,
        regimeContratacaoId: dados.regimeContratacaoId,
        jornadaTrabalhoId: dados.jornadaTrabalhoId,
        pcdId: dados.pcdId,
        statusCadastro: dados.statusCadastro.valor,
        valeAlimentacao: dados.valeAlimentacao,
        valeRefeicao: dados.valeRefeicao,
        valeTransporte: dados.valeTransporte,
        planoSaude: dados.planoSaude,
        planoOdontologico: dados.planoOdontologico,
        seguroVida: dados.seguroVida,
        auxilioCreche: dados.auxilioCreche,
        valeAcademia: dados.valeAcademia,
        auxilioHomeOffice: dados.auxilioHomeOffice,
        tipoComissao: dados.tipoComissao,
        percentualComissao: dados.percentualComissao,
        comissaoFixa: dados.comissaoFixa,
        empresaId,
        pis: dados.pis,
        carteiraTrabalho: dados.carteiraTrabalho,
        tituloEleitor: dados.tituloEleitor,
        nomeMedico: dados.nomeMedico,
        crm: dados.crm,
        dataAdmissaoExam: dados.dataAdmissaoExam,
        statusAptidao: dados.statusAptidao
      };

      if (funcionarioId) {
        await Requicicao.Put({
          endpoint: "/Funcionarios/avaliacaoArquivos",
          data: legible,
          config: auth.GetHeaders()
        });

        const data = await Requicicao.Put({
          endpoint: `/Funcionarios/${funcionarioId}`,
          data: payload,
          config: auth.GetHeaders()
        });
        if (data.codigoEstadoHttp !== 200) {
          throw new Error(data.mensagemAdicional);
        }
      } else {
        await Requicicao.Post({
          endpoint: "/Funcionarios",
          data: payload,
          config: auth.GetHeaders()
        });
      }

      notify({
        type: "success",
        message: funcionarioId
          ? "Funcionário atualizado."
          : "Funcionário cadastrado."
      });
    } catch (error) {
      console.error("Erro ao salvar o funcionário:", error);

      if (error.response && error.response.data) {
        const { mensagemAdicional, errors, status } = error.response.data;
        if (status === 400 && errors) {
          const errorMessages = Object.values(errors).flat().join(", ");
          notify({
            type: "error",
            message: `Erro ao salvar o funcionário: ${errorMessages}`
          });
        } else {
          notify({
            type: "error",
            message: `Erro ao salvar o funcionário: ${
              mensagemAdicional || "Erro desconhecido"
            }`
          });
        }
      } else {
        notify({
          type: "error",
          message: `Erro ao salvar o funcionário. ${error}`
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const NormalizacaoGuid = (valor) =>
      valor === "00000000-0000-0000-0000-000000000000" ? null : valor;
    const formatDate = (dateString) =>
      dateString === "0001-01-01T00:00:00" ? null : new Date(dateString);

    const CarregarDadosFuncionario = async () => {
      try {
        const response = await Requicicao.Get({
          endpoint: `/Funcionarios/${funcionarioId}`,
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          const data = response.objetoResposta;

          setDados((prevState) => ({
            ...prevState,
            ...data,
            statusCadastro: optsStatus.find(
              (obj) => obj.valor === data.statusCadastro
            ),
            funcaoId: NormalizacaoGuid(data.funcaoId),
            cargo: data.cargo,
            escolaridadeId: NormalizacaoGuid(data.escolaridadeId),
            departamentoId: NormalizacaoGuid(data.departamentoId),
            dataNascimento: formatDate(data.dataNascimento),
            dataAdmissao: formatDate(data.dataAdmissao),
            dataExameAdmissional: formatDate(data.dataExameAdmissional),
            salario: data.salario
              ? data.salario.toFixed(2).replace(".", ",")
              : "",
            horarioTrabalho: data.horarioTrabalho,
            cpf: data.cpf,
            rg: MaskUtil.applyRgMask(data.rg),
            cnh: MaskUtil.applyCnhMask(data.cnh),
            telefone: MaskUtil.applyTelefoneMask(data.telefone),
            regimeContratacaoId: data.regimeContratacaoId,
            jornadaTrabalhoId: data.jornadaTrabalhoId,
            pcdId: data.pcdId
          }));
        }
      } catch (error) {
        console.error("Erro ao carregar os dados do funcionário:", error);
      }
    };

    const CarregarRegimes = async () => {
      try {
        const response = await Requicicao.Get({
          endpoint: "/Funcionarios/Regime",
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          const formattedRegimes = response.objetoResposta
            .map((regime) => ({
              label: regime.descricao,
              value: regime.id
            }))
            .filter((x) => x.label !== "CLT Flex");
          setRegimes(formattedRegimes);
        }
      } catch (error) {
        console.error("Erro ao carregar regimes de contratação:", error);
      }
    };

    const CarregarJornadas = async () => {
      try {
        const response = await Requicicao.Get({
          endpoint: "/Funcionarios/Jornada",
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          const formattedJornadas = response.objetoResposta
            .filter((x) => x.descricao !== "Escala 12x36")
            .map((jornada) => ({
              label: jornada.descricao,
              value: jornada.id
            }));
          setJornadas(formattedJornadas);
        }
      } catch (error) {
        console.error("Erro ao carregar jornadas de trabalho:", error);
      }
    };

    const CarregarPcds = async () => {
      try {
        const response = await Requicicao.Get({
          endpoint: "/Funcionarios/PCD",
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          const formattedPcds = response.objetoResposta.map((pcd) => ({
            label: pcd.descricao,
            value: pcd.id
          }));
          setPcds(formattedPcds);
        }
      } catch (error) {
        console.error("Erro ao carregar opções PCD:", error);
      }
    };

    const CarregarFuncoes = async () => {
      try {
        const response = await Requicicao.Get({
          endpoint: "/Funcionarios/Funcoes",
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          const formattedFuncoes = response.objetoResposta.map((funcao) => ({
            label: funcao.descricao,
            value: funcao.id
          }));
          setFuncoes(formattedFuncoes); // Usado para função e cargo
        }
      } catch (error) {
        console.error("Erro ao carregar funções:", error);
      }
    };

    const CarregarEscolaridades = async () => {
      try {
        const response = await Requicicao.Get({
          endpoint: "/Funcionarios/Escolaridades",
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          const formattedEscolaridades = response.objetoResposta.map(
            (escolaridade) => ({
              label: escolaridade.descricao,
              value: escolaridade.id
            })
          );
          setEscolaridades(formattedEscolaridades);
        }
      } catch (error) {
        console.error("Erro ao carregar escolaridades:", error);
      }
    };

    const CarregarDepartamentos = async () => {
      try {
        const response = await Requicicao.Get({
          endpoint: "/Funcionarios/Departamentos",
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          const formattedDepartamentos = response.objetoResposta.map(
            (departamento) => ({
              label: departamento.descricao,
              value: departamento.id
            })
          );
          setDepartamentos(formattedDepartamentos);
        }
      } catch (error) {
        console.error("Erro ao carregar departamentos:", error);
      }
    };

    const CarregarEmpresas = async () => {
      try {
        const data = await Requicicao.Get({
          endpoint: "/Funcionarios/ObterEmpresas",
          config: auth.GetHeaders()
        });
        if (data.codigoEstadoHttp === 200) {
          setListaEmpresas(data.objetoResposta);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const Go = async () => {
      try {
        setLoading(true);

        if (funcionarioId) {
          await CarregarDadosFuncionario();
        }
        await CarregarFuncoes();
        await CarregarEscolaridades();
        await CarregarDepartamentos();
        await CarregarRegimes();
        await CarregarJornadas();
        await CarregarPcds();
        await CarregarEmpresas();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    Go();
  }, [funcionarioId]);

  const BoxBtns = () => (
    <div className="d-flex justify-content-end">
      <Button
        type="button"
        className="btn btn-secondary me-4"
        onClick={() => navigate(-1)}
      >
        Voltar
      </Button>
      <Button
        type="button"
        label={funcionarioId ? "Atualizar" : "Salvar"}
        className="btn btn-primary"
        onClick={HandleSubmit}
      />
    </div>
  );

  return (
    <div className="card p-3" id="form-dados-funcionario">
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        renderActiveOnly={false}
        className="w-100"
      >
        <TabPanel header="Dados Pessoais">
          <DadosPessoais
            dados={dados}
            setDados={setDados}
            HandleChange={HandleChange}
            BoxBtns={BoxBtns}
          />
        </TabPanel>

        <TabPanel header="Dados Admissionais">
          <DadosAdmissionais
            dados={dados}
            setDados={setDados}
            funcoes={funcoes}
            HandleDropdownChange={HandleDropdownChange}
            HandleChange={HandleChange}
            departamentos={departamentos}
            regimes={regimes}
            jornadas={jornadas}
            pcds={pcds}
            escolaridades={escolaridades}
            BoxBtns={BoxBtns}
          />
        </TabPanel>

        <TabPanel header="Benefícios">
          <Beneficios
            dados={dados}
            HandleChange={HandleChange}
            BoxBtns={BoxBtns}
          />
        </TabPanel>

        <TabPanel header="Comissões">
          <div className="row g-3">
            <div className="col-md-4">
              <label htmlFor="tipoComissao" className="form-label">
                Tipo de Comissão
              </label>
              <Dropdown
                id="tipoComissao"
                value={dados.tipoComissao}
                onChange={(e) => HandleDropdownChange(e, "tipoComissao")}
                options={TipoComissao}
                placeholder="Selecione uma Tipo"
                className="w-100"
              />
            </div>
            {dados.tipoComissao === 2 && (
              <div className="col-md-4">
                <label htmlFor="percentualComissao" className="form-label">
                  Percentual de Comissão
                </label>
                <InputNumber
                  id="percentualComissao"
                  className="w-100"
                  prefix="%"
                  max={100}
                  value={dados.percentualComissao}
                  onValueChange={(e) =>
                    HandleDropdownChange(e, "percentualComissao")
                  }
                />
              </div>
            )}
            {dados.tipoComissao === 1 && (
              <div className="col-md-4">
                <label htmlFor="comissaoFixa" className="form-label">
                  Comissão Fixa
                </label>
                <InputNumber
                  id="comissaoFixa"
                  className="w-100"
                  value={dados.comissaoFixa}
                  onValueChange={(e) => HandleDropdownChange(e, "comissaoFixa")}
                />
              </div>
            )}
            <div className="col-12">
              <BoxBtns />
            </div>
          </div>
        </TabPanel>

        <TabPanel header="Exame Médico">
          <ExameMedico
            dados={dados}
            HandleChange={HandleChange}
            BoxBtns={BoxBtns}
            statusAptidao={dados.statusAptidao}
          />
        </TabPanel>

        <TabPanel header="Dependentes">
          <Dependentes dados={dados} />
        </TabPanel>

        <TabPanel header="Documentos">
          <ListaDocumentos
            dados={dados}
            funcionarioId={funcionarioId}
            legible={legible}
            setLegible={setLegible}
          />
          <div className="mt-3">
            <label htmlFor="statusCadastro" className="form-label">
              Status do Cadastro do Funcionário
            </label>
            <Dropdown
              id="statusCadastro"
              value={dados.statusCadastro}
              onChange={(e) => HandleDropdownChange(e, "statusCadastro")}
              options={optsStatus}
              optionLabel="name"
              placeholder="Selecione PCD"
              className="w-100"
            />
          </div>
          <div className="mt-3">
            <BoxBtns />
          </div>
        </TabPanel>

        <TabPanel header="Escala">
          <EscalaFuncionario id={funcionarioId} />
        </TabPanel>

        <TabPanel header="Afastamento">
          <Status id={funcionarioId} />
        </TabPanel>

        <TabPanel header="Alocação">
          <Alocacao
            id={funcionarioId}
            ListaEmpresas={ListaEmpresas}
            ListaFucao={funcoes}
          />
        </TabPanel>

        <TabPanel header="Ocorrência">
          <Ocorrencia FuncionarioId={funcionarioId} />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default FormFuncionario;
